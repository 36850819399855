<template>
  <div class="lead-container">
    <div class="bgWrap">
      <picture>
        <source
          media="(max-width: 767px)"
          :srcset="leadMobile"
        />
        <img
          class="lead-image"
          :src="lead"
          alt="Lead image"
        />
      </picture>
    </div>
    <div class="lead-flex-container">
      <div class="lead-text-container">
        <div class="lead-text">
          {{title}}
        </div>
      </div>
      <div class="lead-text-container-right"></div>
    </div>
  </div>

</template>

<script>
import lead from '@/assets/img/new/35fit_lead_dark.jpg';
import leadMobile from '@/assets/img/new/35fit_lead_mobile_dark.jpg';

export default {
  name: "MainLead",
  data() {
    return {
      lead,
      leadMobile
    }
  },
  props: {
    title: {
      type: String,
      default: 'More\nthan\na gym'
    }
  }
}
</script>

<style scoped>
.lead-container {
  position: relative;
  height: 140vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 580px) {
  .lead-container {
    height: 120vw;
  }
}

@media screen and (min-width: 768px) {
  .lead-container {
    justify-content: center;
    height: 70vw;
  }
}

@media screen and (min-width: 1180px) {
  .lead-container {
    height: calc(100vh - 192px);
  }
}

.bgWrap {
  position: absolute;
  height: 140vw;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

@media screen and (min-width: 580px) {
  .bgWrap {
    height: 120vw;
  }
}

@media screen and (min-width: 768px) {
  .bgWrap {
    height: 70vw;
    right: -300px;
    width: calc(100vw + 300px);
  }
}

@media screen and (min-width: 1180px) {
  .bgWrap {
    height: calc(100vh - 192px);
    right: -500px;
    width: calc(100vw + 500px);
  }
}

@media screen and (min-width: 1440px) {
  .bgWrap {
    right: -500px;
    width: calc(100vw + 500px);
  }
}

@media screen and (min-width: 1520px) {
  .bgWrap {
    left: -500px;
    width: calc(100vw + 500px);
  }
}

.lead-image {
  object-fit: cover;
  object-position: bottom;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .lead-image {
    object-position: right;
  }
}

@media screen and (min-width: 1520px) {
  .lead-image {
    object-position: left;
  }
}

.lead-flex-container {
  display: flex;
  width: 100%;
}

.lead-text-container {
  padding-top: 36px;
  flex: 1 1 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 32px;
  color: white;
}

@media screen and (min-width: 768px) {
  .lead-text-container {
    align-items: flex-start;
    padding-left: 70px;
    padding-top: 0;
  }
}

@media screen and (min-width: 1180px) {
  .lead-text-container {
    padding-left: 95px;
  }
}

@media screen and (min-width: 1440px) {
  .lead-text-container {
    padding-left: 180px;
  }
}

.lead-text-container-right {
  display: none;
}

@media screen and (min-width: 768px) {
  .lead-text-container-right {
    flex: 1 1 0;
    display: flex;
  }
}

.lead-text {
  white-space: pre-line;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  //width: 200px
}

@media screen and (min-width: 375px) {
  .lead-text {
    font-size: 60px;
  //width: 230px
  }
}

@media screen and (min-width: 580px) {
  .lead-text {
    font-size: 70px;
    //width: 230px
  }
}

@media screen and (min-width: 1024px) {
  .lead-text {
    font-size: 88px;
    //width: 310px
  }
}

@media screen and (min-width: 1440px) {
  .lead-text {
    font-size: 100px;
    //width: 350px
  }
}

@media screen and (min-width: 1920px) {
  .lead-text {
    font-size: 110px;
    //width: 400px
  }
}

</style>