<template>
    <transition name="bounce"
     mode="out-in">
        <LoaderView v-if="loading" />
        <div v-else
         class="gifts-store">

            <NotifyWarning v-if="error.show"
             :title="error.title"
             :text="error.text"
             :color="error.color"
             :icon="error.icon"
             @close="error.show = false" />

            <div class="gift-flex-tiles">
                <div class="gifts-explained"
                 v-bind:style='{ backgroundImage: "url(" + pointsBackground + ")", }'>
                    <div>
                        <h3>
                            {{ points }}
                            {{ points == 1 ? $t('dashboard.pages.gifts.point') : $t('dashboard.pages.gifts.points') }}
                        </h3>
                        <p>{{ $t('dashboard.pages.gifts.howEarn') }}</p>
                    </div>
                    <button @click="openModal()"
                     class="gifts-explain-button">{{ $t('dashboard.pages.gifts.readMore') }}</button>
                    <transition name="bounce">
                        <EarningPointsModal v-if="showDetails"
                         :trainings="trainingMax"
                         :currentTraining="trainingCount"
                         @closeModal="showDetails = false"
                         :orders="orders"
                         :storeItems="storeItems"
                         :baseUrl="baseUrl" />
                    </transition>
                </div>
                <div v-if="trainingMax > 0"
                 class="gifts-explained"
                 v-bind:style='{ backgroundImage: "url(" + giftsBackground + ")", }'>
                    <div>
                        <h3>
                            {{ $t('dashboard.pages.gifts.thisMonth') }}
                        </h3>
                        <p>{{ trainingMax }} {{ $t('dashboard.pages.gifts.toEarn') }} {{ trainingMax * 1 }}
                            {{ $t('dashboard.pages.gifts.points') }}
                        </p>
                    </div>
                    <div class="trainings-group">
                        <h5>{{ monthName() }}</h5>
                        <ul class="training-list">
                            <li v-for="index in trainingMax"
                             :key="index"
                             :class="trackLastClass(index)"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="store-wrapper">
                <h2 class="store-title">{{ $t('dashboard.pages.gifts.store') }}</h2>
                <StoreGrid :baseUrl="baseUrl"
                 :store="storeItems"
                 @error="(error) => showError(error)"
                 @done="result => showSuccess(result)" />
            </div>
        </div>
    </transition>
</template>
<script>
import LoaderView from '../../buttons/LoaderView.vue';
import NotifyWarning from '@/components/warnings/NotifyWarning.vue';
import StoreGrid from './StoreGrid.vue';
import EarningPointsModal from './EarningPointsModal.vue';
import giftsBackground from '../../../assets/svg/gifts-background.svg';
import pointsBackground from '../../../assets/svg/points-background.svg';
import xmark from '../../../assets/svg/xmark.svg';
import checkmark from '../../../assets/svg/check.svg';
import {state} from "@/state";


export default {
    name: 'GiftsStore',
    components: {
        LoaderView,
        StoreGrid,
        EarningPointsModal,
        NotifyWarning
    },
    data() {
        return {
            baseUrl: 'https://app.35fit.com',
            storeItems: [],
            loading: true,
            checkmark,
            trainingMax: 0,
            trainingCount: 0,
            points: 0,
            error: {
                show: false,
                title: 'An error occured',
                text: 'Error fetching server data. Try reloading the page',
                color: 'red',
                icon: xmark
            },
            giftsBackground,
            pointsBackground,
            showDetails: false,
            xmark,
            orders: [],
            state
        }
    },
    methods: {
        trackLastClass(index) {
            switch (this.trainingCount >= index) {
                case true:
                    if (this.trainingCount === index) {
                        return 'last-class'
                    } else {
                        return 'visited-class'
                    }
                case false:
                    return 'not-visited'
            }
        },
        openModal() {
            const body = document.querySelector('body');
            this.showDetails = true;
            body.classList.add('menu-open');
        },
        monthName() {
            return (new Date().toLocaleString('default', { month: 'long' }))
        },
        async fetchFromServer(method, url, body = null) {
            return fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: (body === null) ? body : JSON.stringify(body)

            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    this.loading = false;
                    if (data.success) {
                        return data
                    }
                    this.showError()
                    return null
                })
                .catch(err => {
                    console.log(err)
                    this.showError(err)
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        state.authorized = false;
                        this.$router.push('/login');
                    }
                })
        },
        showSuccess(result) {
            const body = document.querySelector('body');
            body.classList.remove('menu-open');
            this.error = {
                title: 'Success',
                text: result.message,
                color: 'green',
                icon: checkmark
            }
            this.error.show = true;
            setTimeout(() => {
                this.error = {
                    title: 'An error occured',
                    text: 'Error fetching server data. Try reloading the page',
                    color: 'red',
                    icon: xmark
                }
            }, 4000)

            // close modal
        },
        showError(error = null) {
            if (error) {
                this.error.text = error.message
            }
            this.error.show = true;
            setTimeout(() => {
                this.error = {
                    title: 'An error occured',
                    text: 'Error fetching server data. Try reloading the page',
                    color: 'red',
                    icon: xmark
                }
            }, 4000)
        },
        getStoreData() {
            this.fetchFromServer('POST', 'https://app.35fit.com/api/users/calendar', {
                date: new Date()
            }).then(response => this.trainingCount = response.finished_trainings.length)
            this.fetchFromServer('GET', this.baseUrl + '/api/store/points')
                .then(response => this.points = response.data.points)
            this.fetchFromServer('GET', this.baseUrl + '/api/store/training_count')
                .then(response => this.trainingMax = response.data)
            this.fetchFromServer('GET', this.baseUrl + '/api/store/items')
                .then(response => this.storeItems = response.data)
            this.fetchFromServer('GET', 'https://app.35fit.com/api/store/orders')
                .then(data => this.orders = data.data)
        }


    },
    mounted() {
        this.getStoreData()
    }
}


</script>
<style>
.store-title {
    margin: 10px 0;
}

.trainings-group h5 {
    text-transform: capitalize;
}

.gifts-explain-button {
    font: var(--font);
    color: var(--light-green);
    border-bottom: var(--light-green) 1px solid;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.gift-flex-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.gifts-store {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 20px;
}

.gifts-explained {
    padding: 20px;
    padding-right: 31%;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    min-height: 180px;
    background-color: var(--gray);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 35%;
    border-radius: var(--card-br);
}

.gifts-explained p {
    font-weight: 500;
}

.training-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 230px;
    gap: 5px;
    list-style: none;
}

.training-list li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.not-visited {
    background-color: #cbcbcb;
}

.visited-class {
    background-color: var(--light-green) !important;
}

.last-class {
    width: 15px !important;
    height: 15px !important;
    background-color: var(--light-green) !important;
}

.trainings-group {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media screen and (max-width: 1050px) {
    .gift-flex-tiles {
        grid-template-columns: 1fr;
    }

    .gifts-explained {
        background-size: 25%;
    }
}

@media screen and (max-width: 420px) {

    .gifts-explained {
        background-size: 30%;
    }
}


@media screen and (max-width: 370px) {
    .training-list {
        max-width: 165px;
    }
}
</style>
