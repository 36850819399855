<template>
    <div class="login">
        <NotifyWarning color="red"
         :title="warning.title"
         :text="warning.text"
         :icon="warning.icon"
         v-if="warning.show"
         @close="warning = false" />

        <div class="login-wrapper login-image-background"
         :style="{
             background: 'url(' + loginPic + ')'
         }">

            <div class="login-left">
                <img :src="logo"
                 alt="logo"
                 class="logo-image">
                <transition name="bounce"
                 mode="out-in">
                    <h2 :key="title">{{ title }}</h2>
                </transition>
                <transition name="bounce"
                 mode="out-in">


                    <div v-if="forgottenPass"
                     class="reset-pass">
                        <transition name="bounce">
                            <InputNotify v-if="inputNotify"
                             :icon="check"
                             :text="$t('login.passwordReset')" />
                        </transition>
                        <input ref="resetEmail"
                         v-model="this.forgottenEmail"
                         type="email"
                         :placeholder="$t('login.resetPlaceholder')"
                         required>
                        <LoaderView v-if="resetLoader"
                         style="height: unset;" />
                        <div class="reset-pass"
                         v-else>
                            <MainButton @click="resetPassword"
                             :text="$t('login.reset')" />
                            <button class="login-register"
                             @click="this.forgotPassView">{{ $t('login.back') }}</button>
                        </div>
                    </div>
                    <form v-else
                     class="login-form"
                     @submit.prevent="login">
                        <div class="login-text-group">
                            <transition name="bounce">
                                <InputNotify v-if="inputNotify"
                                 :icon="warningIcon"
                                 :text="$t('login.validEmail')" />
                            </transition>
                            <input v-model="this.credentials.email"
                             type="email"
                             :placeholder="$t('login.resetPlaceholder')">
                            <input v-model="this.credentials.password"
                             type="password"
                             :placeholder="$t('login.passwordPlaceholder')">
                            <div class="forgot-password">
                                <div @click="forgotPassView"
                                 class="login-forgot">{{ $t('login.forgotPassword') }}</div>
                            </div>
                        </div>
                        <transition name="bounce"
                         mode="out-in">
                            <LoaderView style="height: unset;"
                             v-if="loader" />
                            <div v-else
                             class="login-buttons">
                                <MainButton id="login"
                                 :text="$t('header.login')" />
                                <router-link class="login-register"
                                 to="/register">
                                    {{ $t('register') }}
                                </router-link>
                            </div>
                        </transition>
                    </form>
                </transition>

                <button class="login-back" @click="backAction">
                    <img :src="back"
                     class="login-back-arrow"
                     alt="back button">
                </button>
            </div>

            <div class="login-right">

            </div>
        </div>
    </div>
</template>

<script>
import MainButton from '@/components/buttons/MainButton.vue';
import NotifyWarning from '@/components/warnings/NotifyWarning.vue';
import back from '@/assets/svg/arrow-black.svg';
import logo from '@/assets/img/new/fit_35_black_outline.png';
import warningIcon from '@/assets/svg/warning.svg';
import LoaderView from '@/components/buttons/LoaderView.vue';
import InputNotify from '@/components/InputNotify.vue';
import xmark from '@/assets/svg/xmark.svg';
import check from '@/assets/svg/check.svg';
import loginPic from '@/assets/img/scheduleBG.webp';
import { useMeta } from 'vue-meta'
import { state } from '@/state'

export default {
    name: 'LoginView',
    components: {
        MainButton,
        NotifyWarning,
        LoaderView,
        InputNotify,

    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.login'),
            link: [
                { rel: 'canonical', href: 'https://35fit.com/login' },
            ],
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { property: "og:title", content: "35FIT | " + this.$t('pagenames.login') + " | AI gym" },
                { property: "og:description", content: "Teie isiklik võti tervislikuks eluks" },
                { property: "og:url", content: "https://35fit.com/login" },
            ],
        })
    },
    data() {
        return {
            back,
            loginPic,
            resetLoader: false,
            forgottenPass: false,
            forgottenEmail: '',
            title: this.$t('login.title'),
            vh: window.visualViewport.height + 'px',
            logo,
            loader: false,
            warningIcon,
            check,
          state,
            inputNotify: false,
            credentials: {
                email: '',
                password: '',
            },
            warning: {
                show: false,
                icon: xmark,
                title: '',
                text: ''
            }
        }
    },
    methods: {
        validateEmail(email) {

            // validate email input
            const re = /\S+@\S+\.\S+/;
            if (re.test(email) || email === '') {
                this.inputNotify = false;
                return
            }

            this.inputNotify = true;


        },
        redBorders() {
            const inputs = document.querySelectorAll('input');
            inputs.forEach(input => {
                input.classList.add('red-border');
            })
        },
        forgotPassView() {
            this.forgottenPass = !this.forgottenPass;
            this.title = this.forgottenPass ? this.$t('login.resetTitle') : this.$t('login.title');
        },
        resetPassword() {
            const resetEmail = this.$refs.resetEmail;
            if (this.forgottenEmail === '') {
                resetEmail.classList.add('red-border');
                return
            } else {
                resetEmail.classList.remove('red-border');
                this.resetLoader = true;
                fetch('https://app.35fit.com/api/users/reset_password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: this.forgottenEmail })
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data.success === true) {
                            this.resetLoader = false;
                            this.inputNotify = true;
                            setTimeout(() => {
                                this.inputNotify = false;
                            }, 3000)

                            return
                        }


                    })
                    .catch(err => this.warning = {
                        show: true,
                        icon: xmark,
                        title: this.$t('login.serverError'),
                        text: err.message
                    })
            }
        },
        backAction() {
          this.$router.go(-1) || this.$router.push('/')
        },
        login() {
            if (this.credentials.email === '' || this.credentials.password === '') {
                this.warning = {
                    show: true,
                    title: this.$t('login.unableLogin'),
                    text: this.$t('login.emptyCredentials')
                }
                this.redBorders()

                return;
            }
            this.loader = true;
            fetch('https://app.35fit.com/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.credentials)
            })
                .then(res => res.json())
                .then(data => {
                    if (data.success === true) {
                        // save the token in local storage
                        localStorage.setItem('token', data.token);
                        // emit to App component that the user is logged in
                        state.authorized = true;
                        console.log(data)
                        this.$emit('login');
                        this.$router.push('/dashboard');
                    } else {

                        this.warning = {
                            show: true,
                            title: this.$t('login.unableLogin'),
                            icon: xmark,
                            text: this.$t('login.emailPasswordIncorrect')
                        }
                        this.redBorders()

                    }
                })
                .catch(err => this.warning = {
                    show: true,
                    icon: xmark,
                    title: this.$t('login.serverError'),
                    text: err.message
                })
                .finally(() => this.loader = false);
        },

    },
    watch: {
        credentials: {
            handler() {
                this.validateEmail(this.credentials.email)
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => {
                    input.classList.remove('red-border');
                })
            },
            deep: true
        },
    }

}
</script>

<style>

.login-image-background {
  background-position: calc(100% + 240px) center !important;
}

@media screen and (min-width: 1024px) {
  .login-image-background {
    background-position: calc(100% + 190px) center !important;
  }
}

.logo-image {
  display: none;
  height: 96px
}

@media screen and (min-width: 1024px) {
  .logo-image {
    height: 96px;
  }
}

.login-back-arrow {
    rotate: 90deg;
}

.reset-pass,
.login-form {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.login-back {
    position: absolute;
    top: 20px;
    left: 20px;
}

.disabled-field {
    cursor: not-allowed !important;
    color: var(--text-gray);
    font-size: 18px;
    border: transparent !important;
    display: flex;
    justify-content: space-between;
}

.disabled-field,
input {
    border-radius: var(--card-br);
    padding: 20px 20px;
    background-color: var(--dark-gray);
    font-weight: 500;
    max-width: 550px;
    border: 2px solid transparent;
    text-align: left;
    font-size: 18px;
    text-decoration: none;
    width: 100%;
    font-family: var(--font);
    transition: all .2s ease-in-out;
    /* delete all stock styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.red-border {
    border: 2px solid var(--red) !important;
}

input::placeholder {
    font-size: 18px;
}

.disabled-field,
input:hover {
    background-color: var(--gray);
    cursor: pointer;
    border: 2px solid #00004422;
}

input:focus {
  background-color: var(--gray);
  outline-color: var(--dark-blue);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.login-forgot {
    cursor: pointer;
    font-family: var(--font);
    font-weight: 500;
    color: var(--dark-blue);
    font-size: 18px;
}

.forgot-password {
    margin: 10px 0 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.login-text-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  //background-color: #212225;
    background-color: rgba(0, 0, 0, 0.113);
    width: 100%;
    padding: 0 10px;
    height: calc(100vh - 64px);
    //height: 100svh;
    min-height: 620px;
}

@media screen and (min-width: 1024px) {
  .login {
    height: calc(100vh - 96px);
  }
}

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  //background-color: #212225;
    background-color: var(--dark-gray);
    border-radius: var(--card-br);
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: 120%;
    //height: 80vh;
    height: calc(100vh - 64px);
    min-height: 650px;
    max-width: 1050px;
    width: 100%;
}

@media screen and (min-width: 1024px) {
  .login-wrapper {
    height: calc(100vh - 244px);
  }
}

.login-left {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0 70px;
    background-color: white;
    border-radius: var(--card-br);
    box-shadow: var(--shadow);
    height: 100%;
    min-height: 620px;
    width: 45%;
    padding-bottom: 32px;
}

.login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 55%;
    min-height: 620px;
}

.login-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.login-register {
    font-family: var(--font);
    font-weight: 500;
    font-size: 18px;
    color: var(--dark-blue);
}


@media screen and (max-height: 620px) {

    .login {
        display: block;
        margin: 0 auto;
    }

    .register-user {
        display: block !important;
    }

    .login-wrapper {
        margin: 0 auto;
    }
}

@media screen and (max-width: 840px) {

    .login {
        padding: 0;
    }

    .login-text-group {
        max-width: 450px;
    }

    .login-buttons {
        max-width: 450px;
    }

    .login-right {
        display: none;
    }

    .login-left {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .login-wrapper {
        background: unset;
        background-color: none;
    }
}

@media screen and (max-width: 470px) {

    .login-left {
        padding: 0 20px;
    }
}
</style>
