<template>
  <img
    :src="logo"
    class="header-logo-image"
  >
</template>

<script>
import logo from '@/assets/img/new/fit_35_black_outline.png';

export default {
  name: "MainLogo",

  data() {
    return {
      logo
    }
  },
}
</script>

<style scoped>

.header-logo-image {
  height: 68px
}

@media screen and (min-width: 1024px) {
  .header-logo-image {
    height: 96px;
  }
}

</style>