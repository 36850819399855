<template>
  <div @click="select()" class="tariff-card-container" :id="id || 'select-pack'">
    <div class="tariff-card-first-line">
      <div class="tariff-card-text-block">
        <div class="tariff-card-title-block">
          <div class="tariff-card-title">
            {{name}}
          </div>
          <div class="tariff-card-small-image-block">
            <img class="tariff-card-small-image"
                 :src="color == 'green' ? green : color == 'violet' ? violet : blue"
            />
          </div>
        </div>
        <ul class="tariff-card-description-block">
          <li v-for="item in description" :key="item">
            {{item}}
          </li>
        </ul>
      </div>
      <div class="tariff-card-image-block">
        <img
          :src="color == 'green' ? green : color == 'violet' ? violet : blue"
        />
      </div>
    </div>
    <div class="tariff-card-price-line">
      {{price}} <span class="tariff-card-price-currency">€{{period != false ? '/'+period : ''}}</span>
    </div>
  </div>
</template>

<script>
import green from "@/assets/img/new/green-card-logo.png";
import violet from "@/assets/img/new/violet-card-logo.png";
import blue from "@/assets/img/new/blue-card-logo.png";

export default {
  name: "PackagesCard",
  data() {
    return {
      green,
      violet,
      blue
    }
  },
  props: {
    id: {
      type: Number
    },
    name: {
      type: String,
    },
    description: {
      type: Array,
    },
    price: {
      type: Number,
    },
    period: {
      type: [String, Boolean],
    },
    color: {
      type: String,
    },
    direction: {
      type: String
    }
  },
  methods: {
    select() {
      this.$emit('select', this.id)
    },
  }
}
</script>

<style scoped>

.tariff-card-container {
  flex: 1 0 calc(50% - 24px);
  width: 100%;
  max-width: 400px;
  padding: 24px;
  background-color: #F2F2F2;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  border: 2px solid white;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .tariff-card-container {
    //padding: 40px;
  }
}

@media screen and (min-width: 1140px) {
  .tariff-card-container {
    width: 100%;
  }
}

.tariff-card-container:hover {
  background-color: #eaeaea;
}

.tariff-card-first-line {
  display: flex;

}

@media screen and (min-width: 768px) {
  .tariff-card-first-line {
    //min-height: 180px;
  }
}

.tariff-card-text-block {
  flex: 1 0 0;
}

.tariff-card-title-block {
  display: flex;
}

.tariff-card-title {
  flex: 1 0 auto;
  font-size: 20px;
  font-weight: bold;
  white-space: pre-line;
  line-height: 23px;
  text-transform: uppercase;
}

@media screen and (min-width: 500px) {
  .tariff-card-title {
    /*font-size: 26px;*/
    /*line-height: 30px;*/
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-title {
    /*font-size: 28px;*/
    /*line-height: 32px;*/
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-title {
    /*font-size: 36px;*/
    /*line-height: 41px;*/
  }
}

.tariff-card-small-image-block {
  flex: 0 1 auto;
}

@media screen and (min-width: 768px) {
  .tariff-card-small-image-block {
    /*display: none;*/
  }
}

.tariff-card-small-image {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 500px) {
  .tariff-card-small-image {
    /*width: 80px;*/
    /*height: 80px;*/
  }
}


.tariff-card-description-block {
  margin-left: 24px;
  font-size: 18px;
  line-height: 22px;
  margin-top: 8px;
}

@media screen and (min-width: 500px) {
  .tariff-card-description-block {
    /*font-size: 20px;*/
    /*line-height: 24px;*/
    /*margin-top: 16px;*/
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-description-block {
    /*font-size: 24px;*/
    /*line-height: 29px;*/
    /*margin-top: 24px;*/
  }
}

.tariff-card-description-block li:not(:last-child) {
  margin-bottom: 4px; /* Отступ только между элементами */
}

.tariff-card-image-block {
  display: none;
}

@media screen and (min-width: 768px) {
  .tariff-card-image-block {
    /*display: block;*/
    /*margin-left: 24px;*/
    /*flex: 0 1 auto;*/
  }
}

.tariff-card-price-line {
  text-align: right;
  font-size: 22px;
  font-weight: bold;
  margin-top: 16px;
}

@media screen and (min-width: 500px) {
  .tariff-card-price-line {
    /*font-size: 28px;*/
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-price-line {
    /*font-size: 32px;*/
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-price-line {
    /*font-size: 36px;*/
  }
}

.tariff-card-price-currency {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  .tariff-card-price-currency {
    /*font-size: 26px;*/
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-price-currency {
    /*font-size: 30px;*/
  }
}
</style>