<template>
    <button :class="widthRestrict ? 'width-restrict' : ''">
        {{ text }}
    </button>
</template>
<script>
export default {
    props: {
        text: String,
        widthRestrict: {
            type: Boolean,
            default: false
        },
    }
}
</script>
<style scoped>
button {
    background-color: var(--dark-blue);
    color: white;
    border: 1px solid var(--dark-blue);
    border-radius: var(--button-br);
    padding: 10px 30px;
    width: 100%;
    max-width: 450px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: all .2s ease-in-out;
    font-family: var(--font);
}

button:hover {
    background-color: var(--lighter-blue);
}

button:active {
    background-color: var(--lighter-blue);
    transform: scale(.95);
}

@media (min-width: 450px) {
    .width-restrict {
        max-width: 250px;
    }

    button {
        display: block;
        margin: 0 auto;
    }
}
</style>
