<template>
  <router-link :to='state.authorized ? `/dashboard#contract` : "/pricing"'>
    <div class="buy-button-container">
      <div class="buy-button-text">{{ $t('header.buyNow') }}</div>
    </div>
  </router-link>
</template>

<script>
import {state} from "@/state";

export default {
  name: "BuyButton",
  data() {
    return {
      state
    }
  },
}
</script>

<style scoped>

.buy-button-container {
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 16px;
  background-color: #B5261E;
  color: white;
  border-radius: 21px;
  cursor: pointer;
  display: none;
}

.buy-button-container:hover {
  border-color: black;
  background-color: black;
  /*border-color: #000939;*/
  /*background-color: #000939;*/
  color: white;
  transition: all 0.3s;
}

@media screen and (min-width: 400px) {
  .buy-button-container {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .buy-button-container {
    height: 42px;
    padding: 0 30px;
  }
}

.buy-button-text {
  font-size: 12px;
  text-transform: uppercase;
}

@media screen and (min-width: 1024px) {
  .buy-button-text {
    font-size: 16px;
  }
}

</style>