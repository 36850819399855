<template>
    <div class="select">
        <span class="selection-pill"></span>
        <div v-for="option in options"
         :key="option.id"
         class="option">
            <label @click="movePill(option.id), this.current = option.id">
                <span>{{ option.name }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SegmentedSelect',
    props: {
        options: {
            type: Array,
            required: true,
            default: () => [{
                name: 'Scheduled',
                id: 0
            }, {
                name: 'Canceled',
                id: 1
            }, {
                name: 'Completed',
                id: 2
            }]
        },
    },
    data() {
        return {
            current: 0,
            widthPill: '408px',

        }
    },
    methods: {
        handleResize() {
            var pageWidth = window.innerWidth;
            if (pageWidth < 440) {
                this.widthPill = (window.innerWidth - 122)/1 +'px';
            } else if (pageWidth < 768) {
                this.widthPill = '310px';
            } else {
              this.widthPill = '380px';
            }

            this.movePill(this.current);
        },
        movePill(id) {
            const selectionPill = document.querySelector('.selection-pill');
            if (selectionPill) {
                selectionPill.style.transform = 'translate(calc(' + this.widthPill + ' / 3' + ' * ' + id + ' - 1px ))';
            }
            this.$emit('selected', id);
        }

    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.selection-pill {
    top: 5px;
    left: 6px;
    height: 75%;
    bottom: 2px;
    position: absolute;
    background: #fff;
    border: .5px solid rgba(0, 0, 0, .04);
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 12%), 0 3px 1px 0 rgb(0 0 0 / 4%);
    border-radius: 7px;
    width: calc(v-bind(widthPill) / 3 - 8px);
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    will-change: transform;
    -webkit-transition: transform .2s ease;
    transition: transform .2s ease;
}

.option {
    z-index: 3;
    font-size: 12px;
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
}

@media (min-width: 434px) {
  .option {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .option {
    font-size: 18px;
  }
}

.option span {
    cursor: pointer;
    transition: all .2s ease;
}

.option span:hover {
    color: var(--text-gray);
}

label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.select {
    position: relative;
    width: 100%;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch !important;
    touch-action: manipulation !important;
    -webkit-tap-highlight-color: transparent;
    --background: rgba(239, 239, 240, 1);
    background: var(--background);
    border-radius: 9px;
    margin: 0 auto;
    padding: 2px;
    height: 42px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-self: center;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 434px) {
  .select {
    width: 312px;
  }
}

@media (min-width: 768px) {
  .select {
    width: 382px;
    padding: 12px;
  }
}

@media (max-width: 340px) {
    .select {
        width: 280px;
    }

    .option {
        font-size: 10px;
    }
}
</style>
