<template>
    <div ref="wrapper"
     class="modal-wrapper">
        <div class="modal">
            <div class="modal-buttons">
                <button @click="closeModal">
                    <img :src="close"
                     alt="close">
                </button>
                <LearnMoreButton @click="toRegister"
                 v-if="(showUpButton && loggedOut)"
                 :text="$t('register')" />
            </div>
            <div class="modal-content">
                <h4 class="subheading">{{ subtitle }}</h4>
                <h1 class="heading">{{ title }}</h1>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import LearnMoreButton from './buttons/LearnMoreButton.vue';
import close from '@/assets/svg/close.svg';


export default {
    components: {
        LearnMoreButton,

    },
    props: {
        showUpButton: {
            type: Boolean,
            default: false
        },
        subtitle: {
            type: String,
            default: 'Some subheading'
        },
        title: {
            type: String,
            default: '35FIT'
        },
    },
    data() {
        return {
            close,
            loggedOut: false
        }
    },
    mounted() {
        const wrapper = this.$refs.wrapper;
        setTimeout(() => {
            wrapper.style.background = 'rgba(0, 0, 0, 0.5)';
        }, 100);

        if (localStorage.getItem('token')) {
            this.loggedOut = false;
        } else {
            this.loggedOut = true;
        }

        wrapper.addEventListener('click', (e) => {
            this.closeOut(e);
        });
    },
    methods: {
        closeOut(e) {
            const wrapper = this.$refs.wrapper;
            if (e.target === wrapper) {
                if (wrapper) {
                    this.closeModal();
                }
            }
        },
        closeModal() {
            const modal = this.$refs.wrapper;
            const body = document.querySelector('body');
            body.classList.remove('menu-open');
            modal.style.background = 'rgba(0, 0, 0, 0)';
            setTimeout(() => {
                this.$emit('closeModal');
            }, 100);
        },
        toRegister() {
            const body = document.querySelector('body');
            body.classList.remove('menu-open');
            this.$router.push('/register');
        }
    }

}
</script>

<style scoped>
button {
    display: flex;
    align-items: center;
}

.modal-wrapper {
    position: fixed;
    top: 96px;
    left: 0;
    width: 100%;
    height: calc(100% - 96px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 0;
    z-index: 105;
    transition: all 0.2s ease-in-out;
    overflow-y: scroll;
    background-color: transparent;
}

.modal {
    border-radius: var(--card-br);
    width: 100%;
    max-width: 720px;
    padding: 30px 58px;
    background-color: var(--bg);
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 15px;
}

.modal-content {
    width: 100%;
    padding-bottom: 30px;
}

.heading {
    font-size: 28px;
    line-height: 32px;
}

.subheading {
    color: var(--text-gray);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .modal-wrapper {
    top: 60px;
  }
}

@media screen and (max-width: 768px) {

    .modal-wrapper {
        display: block;
        top: 64px;
        padding: 0;
        overflow-y: hidden;
    }

    .modal-buttons {
        margin-bottom: 0px;
    }

    .modal {
        padding: var(--padding);
        max-width: unset;
        border-radius: 0;
        height: 100%;
        overflow-y: scroll;
    }

}
</style>
