<template>
  <div class="about-container">
    <div class="about-col-container-left">
      <div class="about-left-col">
        Connected<br>to results
      </div>
    </div>
    <div class="about-col-container-right">
      <div class="about-right-col">
        <div class="about-title">
          {{ $t('homeNew.about.right.title') }}
        </div>
        <div class="about-description">
          {{ $t('homeNew.about.right.description') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainAbout"
}
</script>

<style scoped>
.about-container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding: 8px 0 24px 0;
}

@media screen and (min-width: 768px) {
  .about-container {
    padding: 24px 0 32px 0;
  }
}

@media screen and (min-width: 1024px) {
  .about-container {
    padding: 50px 0 80px 0;
  }
}

.about-col-container-left {
  display: none;
}

@media screen and (min-width: 1024px) {
  .about-col-container-left {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.about-col-container-right {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 40px;
  text-transform: uppercase;
}

.about-right-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .about-right-col {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .about-right-col {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
}

.about-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  white-space: pre-line;
}

@media screen and (min-width: 768px) {
  .about-title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .about-title {
    font-size: 40px;
  }
}

.about-description {
  font-size: 18px;
  line-height: 1.5;
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .about-description {
    font-size: 24px;
  }
}

</style>