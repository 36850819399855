<template>
  <div class="tariff-tab" id="basic">
    Basic Training Zone
  </div>
</template>

<script>
export default {
  name: "TariffTabs"
}
</script>

<style scoped>
.tariff-tab {
  display: inline-block;
  font-size: 20px;
  padding: 6px 32px;
  border: 2px solid #000000;
  background-color: #000000;
  border-radius: 40px;
  margin: 40px 0 20px;
  cursor: pointer;
  color: white;
  //transition: all 0.3s;

}

@media screen and (min-width: 1024px) {
  .tariff-tab {
    font-size: 24px;
    margin: 80px 0 40px;
  }
}

/*.tariff-tab:hover {*/
/*  background-color: #ffffff;*/
/*  color: #000000;*/
/*  transition: all 0.3s;*/
/*}*/

</style>