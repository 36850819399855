<template>
    <div class="list-row">
        <div class="list-left">
            <img :src="this.icon"
             class="list-icon-contract"
             alt="icon">
            <div class="list-textgroup">
                <h4>{{ title }}</h4>
                <p>{{ date }}</p>
            </div>
        </div>
        <div v-if="this.showButton"
         @click="$emit('clicked')"
         class="list-right">
            <p>{{ $t('dashboard.view') }}</p>
        </div>
        <div v-if="this.contractList"
         @click="$emit('clicked')"
         class="list-right">
            <p>{{ $t('dashboard.pages.contract.prolong') }}</p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ListRow',
    props: {
        title: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        showButton: {
            type: Boolean,
            required: false,
            default: true
        },
        contractList: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
<style>

.list-textgroup {
  font-size: 18px;
  line-height: 1.5;
}

.list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px solid var(--dark-gray);
}

.list-row:first-of-type {
    border-top: 1px solid var(--dark-gray);
}

.list-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.list-left p {
    font-size: 18px;
}

.list-icon-contract {
    width: 15px;
}

.list-right p {
    font-size: 18px;
    color: var(--dark-blue);
    font-weight: 600;
    padding: 10px 0;
    cursor: pointer;
}
</style>
