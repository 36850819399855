<template>
  <div class="opengym-container">
    <swiper
      :modules="modules"
      effect="fade"
      :slide-per-view=1
      :autoplay="{
        delay: 2000,
          disableOnInteraction: false,
      }"
      :loop="true"
      :speed=600
      :allow-touch-move="false"
      class="swiper"
    >
      <swiper-slide>
        <div class="opengym-image-container">
          <div class="opengym-bg-wrap">
            <img
              :src="slide1"
              class="opengym-image"
            />
          </div>
          <div class="opengym-bg-dark-30"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="opengym-image-container">
          <div class="opengym-bg-wrap">
            <img
              :src="slide2"
              class="opengym-image"
            />
          </div>
          <div class="opengym-bg-dark-30"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="opengym-image-container">
          <div class="opengym-bg-wrap">
            <img
              :src="slide3"
              class="opengym-image"
            />
          </div>
          <div class="opengym-bg-dark-30"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="opengym-image-container">
          <div class="opengym-bg-wrap">
            <img
              :src="slide4"
              class="opengym-image"
            />
          </div>
          <div class="opengym-bg-dark-30"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="opengym-image-container">
          <div class="opengym-bg-wrap">
            <img
              :src="slide5"
              class="opengym-image"
            />
          </div>
          <div class="opengym-bg-dark-50"></div>
        </div>
      </swiper-slide>
    </swiper>
    <SliderDescriptions/>
  </div>
</template>

<script>
import slide1 from '@/assets/img/new/opengym-slide-1.jpg';
import slide2 from '@/assets/img/new/opengym-slide-2.jpg';
import slide3 from '@/assets/img/new/opengym-slide-3.jpg';
import slide4 from '@/assets/img/new/opengym-slide-4.jpg';
import slide5 from '@/assets/img/new/opengym-slide-5.jpg';
// import Swiper core and required modules
import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import SliderDescriptions from "@/components/opengym/SliderDescriptions";

export default {
  name: "OpenGymSlider",
  components: {
    SliderDescriptions,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, EffectFade],
    };
  },
  data() {
    return {
      slide1,
      slide2,
      slide3,
      slide4,
      slide5,
    }
  },
}
</script>

<style scoped>

.swiper {
  display: none;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .swiper {
    display: inherit;
  }
}

.opengym-container {
  height: 1650px;
  width: 100vw;
  position: relative;
  background-color: #212225;
}

@media screen and (min-width: 768px) {
  .opengym-container {
    height: 850px;
    background-color: inherit;
  }
}

@media screen and (min-width: 1440px) {
  .opengym-container {
    height: 1000px;
  }
}

.opengym-image-container {
  height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1440px) {
  .opengym-image-container {
    height: 1000px;
  }
}

.opengym-bg-wrap {
  position: fixed;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  z-index: -2;
}

@media screen and (min-width: 1440px) {
  .opengym-bg-wrap {
    height: 1000px;
  }
}

.opengym-bg-dark-70 {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100%;
  width: 100vw;
  z-index: -1;
}

.opengym-bg-dark-50 {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100vw;
  z-index: -1;
}

.opengym-bg-dark-30 {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  height: 100%;
  width: 100vw;
  z-index: -1;
}

.opengym-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

</style>