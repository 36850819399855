<template>
  <div class="notice-warning">
    <h4>{{ $t('noticeTitle') }}</h4>
    <p v-html="$t('noticeText')"
       class="notice-text">
    </p>
    <MainButton :text="$t('close')"
                @click="closenotice()" />
  </div>
</template>

<script>
import MainButton from '../buttons/MainButton.vue';

export default {
  components: {
    MainButton,
  },
  methods: {
    closenotice() {
      this.notice = false;
      // save to notices that user has seen the notice warning with time limit of 2 days
      this.$cookies.set('notice', 'seen', '1h');
      this.$emit('close');
    },
  }

}
</script>

<style>
.notice-warning {
  position: fixed;
  top: 7rem;
  left: 1rem;
  border-radius: var(--card-br);
//background-color: var(--bg);
  background-color: rgb(248, 217, 220);
  z-index: 110;
  padding: 1rem;
  max-width: 375px;
  display: flex !important;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--text);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.notice-text {
  white-space: pre-line;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
}

.notice-text a {
  color: var(--light-green);
  font-weight: 500;
}

.notice-notice {
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 768px) {
  .notice-warning {
    top: 16rem;
    left: 0.2rem;
    right: 0.2rem;
    max-width: unset;
  }
}

@media screen and (max-width: 1024px) {
  .notice-warning {
    top: 5rem;
  }
}
</style>