<template>
    <transition name="bounce"
     mode="out-in">
        <div v-if="loader">
            <LoaderView />
        </div>
        <div v-else
         class="classes-page">

            <NotifyWarning v-if="error.show"
             :title="error.title"
             :text="error.text"
             :color="error.color"
             :icon="error.icon"
             @close="error.show = false" />
            <div class="width-100">
                <div class="space-between width-100">
                    <h2 class="pricing-topic-services">{{ $t('dashboard.pages.booking.services') }}</h2>
                    <NewButton :text="this.$t('pricing.other.order')"
                               @click="state.toggleBooking()" />
                </div>
<!--                <PricingGrid :json="services"-->
<!--                             mode="servicesOnClassesPage" />-->
            </div>
          <transition name="bounce" mode="out-in">
            <div class="prev-contracts">
              <ClassesServices/>
            </div>
          </transition>
            <div class="space-between margin-bottom-24">
              <h2 class="heading-left">
                  {{ $t('dashboard.pages.booking.reserves') }}
              </h2>
              <NewButton v-if="showBooking"
                 @clicked="state.toggleBooking()"
                 :text="$t('dashboard.pages.booking.modal.newBook')" />
            </div>
            <SegmentedSelect @selected="(id) => this.tab = id"
             :options="options" />
            <transition name="bounce">
                <InputNotify v-if="showNotify.show"
                 :icon="showNotify.icon"
                 :text="showNotify.text" />
            </transition>
            <transition name="left"
             mode="out-in">
                <div v-if="this.tab === 0"
                 class="scheduled-list">
                    <transition name="bounce"
                     mode="out-in">
                        <div v-if="this.classes.length > 0">
                            <ScheduledClass v-for="booking in this.classes"
                             :key="booking.id"
                             :booking="booking"
                             :classInfo="{
                                 name: booking.good_name,
                                 date: booking.start.split('T')[0].split('-').reverse().join('.'),
                                 time: booking.start.split('T')[1].split(':')[0] + ':' +
                                     booking.start.split('T')[1].split(':')[1],
                             }"
                             mode="scheduled"
                             @delete="cancelReserve(booking.id, booking.luckyfit)" />
                        </div>
                        <NoInfoPlaceholder :img="classesImg"
                         :text="$t('dashboard.pages.booking.noClasses')"
                         v-else />
                    </transition>
                </div>
                <div v-else-if="this.tab === 1"
                 class="scheduled-list">
                    <ScheduledClass v-for="booking in this.canceled"
                     :key="booking.id"
                     :booking="booking"
                     :classInfo="{
                         name: booking.good_name,
                         date: booking.start.split('T')[0].split('-').reverse().join('.'),
                         time: booking.start.split('T')[1].split(':')[0] + ':' +
                             booking.start.split('T')[1].split(':')[1],
                     }"
                     mode="canceled" />
                    <NoInfoPlaceholder v-if="this.canceled.length === 0"
                     :img="classesImg"
                     :text="$t('dashboard.pages.booking.noCanceled')" />
                </div>
                <div v-else-if="this.tab === 2"
                 class="scheduled-list">
                    <VisitCalendar />

                </div>
            </transition>

        </div>
    </transition>
</template>

<script>
// import PricingGrid from '@/components/PricingGrid.vue';
import SegmentedSelect from '@/components/selectors/SegmentedSelect.vue'
import ScheduledClass from './ScheduledClass.vue';
import NewButton from '../buttons/NewButton.vue';
import LoaderView from '../buttons/LoaderView.vue';
import InputNotify from '../InputNotify.vue';
import check from '../../assets/svg/check.svg';
import xmark from '../../assets/svg/xmark.svg';
import NoInfoPlaceholder from './NoInfoPlaceholder.vue';
import NotifyWarning from '../warnings/NotifyWarning.vue';
import classesImg from '../../assets/img/dashboard/classes.svg';
import VisitCalendar from './VisitCalendar.vue';
import { state } from '../../state.js';
import services from '@/resources/services.json';
import sgt from '@/resources/sgt.json';
import tests from '@/resources/tests.json';
import ClassesServices from "@/components/dashboard/ClassesServices";

export default {
    name: "ClassesPage",
    components: {
      ClassesServices,
        SegmentedSelect,
        ScheduledClass,
        NewButton,
        LoaderView,
        InputNotify,
        NoInfoPlaceholder,
        NotifyWarning,
        VisitCalendar,
        // PricingGrid,
    },
    props: {
        fromTests: {
            type: Boolean,
            default: false,
        },
        isTrial: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            state,
            tab: 0,
            classesImg,
            loader: true,
            classes: null,
            finished: null,
            canceled: null,
            showBooking: true,
            ticket: null,
            services,
            sgt,
            tests,
            showNotify: {
                show: false,
                icon: null,
                text: null,
            },
            error: {
                show: false,
                title: 'An error occured',
                text: 'Error fetching server data. Try reloading the page',
                color: 'red',
                icon: xmark
            },
            check,
            xmark,
            options: [{
                name: this.$t('dashboard.pages.booking.select.scheduled'),
                id: 0
            }, {
                name: this.$t('dashboard.pages.booking.select.canceled'),
                id: 1
            }, {
                name: this.$t('dashboard.pages.booking.select.completed'),
                id: 2
            }]
        }
    },
    watch: {
        state: {
            handler() {
                if (!this.state.openBooking) {
                    this.getClasses();
                }
            },
            deep: true
        }
    },

    methods: {

        showError(error = null) {
            if (error) {
                this.error.text = error.message
            }
            this.error.show = true;
            setTimeout(() => {
                this.error = {
                    title: 'An error occured',
                    text: 'Error fetching server data. Try reloading the page',
                    color: 'red',
                    icon: xmark
                }
            }, 4000)
        },
        async connectServer(method, url, body = null) {
            return fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: (body === null) ? body : JSON.stringify(body)

            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    this.loading = false;
                    if (data.success) {
                        return data
                    }
                    this.showError(data)
                    return null
                })
                .catch(err => {
                    console.log(err.message)
                    this.showError(err)
                    if (err.message == 401) {
                        localStorage.removeItem('token');
                        state.authorized = false;
                        this.$router.push('/login');
                    }
                })
        },
        getClasses() {
            this.connectServer('GET', 'https://app.35fit.com/api/users/reserves')
                .then(response => {
                    if (response.success) {
                        this.classes = response.reserves;
                        this.canceled =
                            response.canceled_reserves;
                        this.finished =
                            response.finished_reserves;
                        this.loader = false;
                        this.getContract()
                        return
                    }
                })
        },
        getContract() {
            this.connectServer('GET', 'https://app.35fit.com/api/users/ticket')
                .then(response => {
                    if (response.success) {
                        if (response.ticket) {
                            this.ticket = response.ticket
                            this.checkTrialClasses()
                            return
                        }

                        this.showBooking = false;
                    }
                })
        },
        checkTrialClasses() {
            // start tickets
            if (this.ticket.ticket_ticket_id == 613481) {
                // check if user has any classes
                if (this.finished.length > 0) {
                    this.showBooking = false;
                } else {
                    // check if user has any trial classes
                    if (this.classes.length > 0) {
                        this.classes.forEach(item => {
                            if (item.good_name === 'Proovitreening') {
                                this.showBooking = false;
                            } else {
                                this.showBooking = true;
                            }
                        })
                    } else {
                        this.showBooking = true;
                    }
                }
            }
        },
        manageNotify(success) {
            if (success) {
                this.showNotify = {
                    show: true,
                    text: 'We have deleted your reserve',
                    icon: this.check
                }
            } else {
                this.showNotify = {
                    show: true,
                    text: 'Something went wrong',
                    icon: this.xmark
                }
            }

            setTimeout(() => {
                this.showNotify = {
                    show: false,
                    text: null,
                    icon: null,
                }
            }, 3000)
        },
        cancelReserve(reserve_id, luckyfit) {
            if (luckyfit != false) {
                luckyfit = true;
            }

            fetch('https://app.35fit.com/api/users/reserves', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' +
                        localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: reserve_id,
                    luckyfit: luckyfit
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.success) {
                        this.classes = this.classes.filter(booking =>
                            booking.id !== reserve_id);
                        this.checkTrialClasses()
                        this.manageNotify(true)
                        return
                    }
                    this.manageNotify(false)
                })
                .catch(err => {
                    console.log(err)
                })
        },

    },
    mounted() {
        this.getClasses()
        this.state.isTrial = this.isTrial;

        if (this.fromTests) {
            setTimeout(() => {
                this.state.toggleBooking();
            }, 1500)
            this.$emit('opened')
        }
    },
    beforeMount() {
        switch (this.$i18n.locale) {
            case 'en':
                this.services = {services: this.services.plans.en, tests: this.tests.plans.en, sgt: this.sgt.plans.en}
                break;
            case 'et':
                this.services = {services: this.services.plans.et, tests: this.tests.plans.et, sgt: this.sgt.plans.et}
                break;
            case 'ru':
                this.services = {services: this.services.plans.ru, tests: this.tests.plans.ru, sgt: this.sgt.plans.ru}
                break;
            default:
                this.services = {services: this.services.plans.en, tests: this.tests.plans.en, sgt: this.sgt.plans.en}
                break;
        }
    },

}
</script>

<style>

.width-100 {
  width: 100%;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.scheduled-list {
    width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
}

.classes-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
}

.heading-left {
    align-self: self-start;
}
</style>
