<template>
    <div class="main-container">
      <OpenGymLead/>
      <OpenGymAbout/>
      <OpenGymSlider/>
      <OpenGymBenefits/>
      <OpenGymPackages/>
      <OpenGymFaq/>
    </div>
</template>

<script>
// import SiteHeader from "@/components/common/SiteHeader";
import OpenGymLead from "@/components/opengym/OpenGymLead";
import OpenGymAbout from "@/components/opengym/OpenGymAbout";
import OpenGymSlider from "@/components/opengym/OpenGymSlider";
import OpenGymBenefits from "@/components/opengym/OpenGymBenefits";
// import MainContacts from "@/components/common/MainContacts";
// import MainForm from "@/components/common/MainForm";
import OpenGymPackages from "@/components/opengym/OpenGymPackages";
import OpenGymFaq from "@/components/opengym/OpenGymFaq";
// import MainFooter from "@/components/common/MainFooter";

export default {
  name: "OpenGymView",
  components: {
    OpenGymFaq,
    OpenGymPackages,
    OpenGymBenefits,
    OpenGymSlider,
    OpenGymAbout,
    OpenGymLead,
    // SiteHeader,
    // MainFooter,
    // MainForm,
    // MainContacts,
  }
}
</script>

<style scoped>

</style>