<template>
  <header class="root">
    <div class="container">
      <a href="/" class="logo-container">
        <MainLogo/>
      </a>
      <div class="right-menu">
        <div class="menu-button-container">
          <MenuButton  @click="menuOpen = true"/>
        </div>
        <div class="language-container">
          <div class="language-selector"
               @click="isDropdownLangVisible = !isDropdownLangVisible">

            <div class="language-name">
              <span>{{ this.$i18n.locale }}</span>
<!--              <span>{{ this.$i18n.locale }}</span><span class="language-last-symbol">N</span>-->
            </div>
            <img
              :src="selectDown"
            >
            <LanguageSelector :isDropdownLangVisible="isDropdownLangVisible"/>
          </div>
        </div>
        <div class="login-container">
          <LoginButton/>
        </div>
        <div class="buy-button-wrapper">
          <BuyButton/>
        </div>
      </div>
    </div>
    <HeaderMenu :menuOpen="menuOpen" :hideMenu="hideMenu"/>
  </header>
</template>

<script>
import MenuButton from "@/components/common/MenuButton";
import LanguageSelector from "@/components/common/LanguageSelector";
import MainLogo from "@/components/common/MainLogo";
import BuyButton from "@/components/common/BuyButton";
import selectDown from '@/assets/svg/new/select-down.svg';
import LoginButton from "@/components/common/LoginButton";
import HeaderMenu from "@/components/common/HeaderMenu";
export default {
  name: "SiteHeader",
  components: {HeaderMenu, LoginButton, BuyButton, MainLogo, LanguageSelector, MenuButton},
  data() {
    return {
      selectDown,
      isDropdownLangVisible: false,
      menuOpen: false
    }
  },
  methods: {
    hideMenu() {
      this.menuOpen = false;
    }
  }
}
</script>

<style>

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  padding: 0 16px;
  height: 60px;
  /*line-height: 3.8rem;*/
  /*font-size: .625rem;*/
  /*font-weight: 900;*/
  /*font-style: normal;*/
  /*letter-spacing: .125rem;*/
  /*text-transform: uppercase;*/
  color: #131614;
  border-bottom: 1px solid #c3c3c3;
  background-color: #fff;
  /*max-width: 100vw;*/
}

@media screen and (min-width: 768px) {
  .root {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .root {
    height: 96px;
  }
}

.container {
  display: flex;
  /*flex-wrap: wrap;*/
  height: 100%;
}

.right-menu {
  display: flex;
  /*flex-basis: 0;*/
  flex-grow: 1;
  /*max-width: 100%;*/
  /*width: 100%;*/
  flex-direction: row-reverse;
}


.language-container {
  display: none;
}

@media screen and (min-width: 768px) {
  .language-container {
    display: flex;
    align-items: center;
  }
}


.menu-button-container,
.logo-container {
  display: flex;
  align-items: center;
}

.login-container,
.buy-button-wrapper {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

@media screen and (min-width: 1024px) {
  .login-container,
  .buy-button-wrapper {
    margin-right: 24px;
  }
}

.language-selector {
  /*font-weight: 400;*/
  display: flex;
  /*align-items: center;*/
  line-height: 32px;
  /*text-align: center;*/
  padding: 0 12px 0 20px;
  margin-right: 20px;
  position: relative;
  font-size: 14px;
  cursor: pointer
  /*transform: scaleY(0.9);*/
}

@media screen and (min-width: 1024px) {
  .language-selector {
    font-size: 16px;
    line-height: 48px;
  }
}

.language-last-symbol {
  /*letter-spacing: 0;*/
}

.language-name {
  margin-right: 6px;
  text-transform: uppercase;
}

</style>