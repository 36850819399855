<template>
  <div class="descriptions-wrapper">
    <div class="descriptions-container">
      <div class="descriptions-col">
        <div class="descriptions-line descriptions-line-left-top">
          <SliderDescription
            value='35'
            :subtitle=' $t("basicTrainingZone.slider[0].subtext") '
            :description=' $t("basicTrainingZone.slider[0].description") '
          />
        </div>
        <div class="descriptions-line descriptions-line-left-bottom">
          <SliderDescription
            value='2'
            :subtitle=' $t("basicTrainingZone.slider[1].subtext") '
            :description=' $t("basicTrainingZone.slider[1].description") '
          />
        </div>
      </div>
      <div class="descriptions-col-center">
        <span class="descriptions-center-text"> Basic Training Zone </span>
      </div>
      <div class="descriptions-col">
        <div class="descriptions-line descriptions-line-right-top">
          <SliderDescription
            value='4'
            :subtitle=' $t("basicTrainingZone.slider[2].subtext") '
            :description=' $t("basicTrainingZone.slider[2].description") '
          />
        </div>
        <div class="descriptions-line descriptions-line-right-bottom">
          <SliderDescription
            :value='$t("basicTrainingZone.slider[3].subtext") + " 65+"'
            :description=' $t("basicTrainingZone.slider[3].description") '
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderDescription from "@/components/opengym/SliderDescription";
export default {
  name: "SliderDescriptions",
  components: {SliderDescription}
}
</script>

<style scoped>

.descriptions-wrapper {
    width: 100%;
    position: absolute;
    z-index: 100;
    height: 100%;
    top:0;
    left: 0;
}

.descriptions-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 50px 16px;
  max-width: 1440px;
}

@media screen and (min-width: 450px) {
  .descriptions-container {
    padding: 50px 40px;
  }
}

@media screen and (min-width: 768px) {
  .descriptions-container {
    flex-direction: row;
    padding: 50px 24px;
  }
}

@media screen and (min-width: 1024px) {
  .descriptions-container {
    padding: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .descriptions-container {
    padding: 100px 50px;
  }
}

.descriptions-col {
  flex: 0 0 0;
  flex-direction: column;
  gap: 50px;
  display: flex;
}

@media screen and (min-width: 1440px) {
  .descriptions-col {
    gap: 100px;
  }
}

.descriptions-col-center {
  font-size: 80px;
  flex: 1 0 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-bottom: 50px;
}

.descriptions-center-text {
  display: none;
}

@media screen and (min-width: 1280px) {
  .descriptions-center-text {
    display: none;
  }
}

.descriptions-line {
  flex: 1 1 0;
  display: flex;
}

.descriptions-line-left-top,
.descriptions-line-right-top,
.descriptions-line-left-bottom,
.descriptions-line-right-bottom
{
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .descriptions-line-left-top {
    justify-content: flex-start;
    align-items: flex-start;
  }
}



@media screen and (min-width: 768px) {
  .descriptions-line-right-top {
    justify-content: flex-end;
    align-items: flex-start;
  }
}



@media screen and (min-width: 768px) {
  .descriptions-line-left-bottom {
    justify-content: flex-start;
    align-items: flex-end;
  }
}



@media screen and (min-width: 768px) {
  .descriptions-line-right-bottom {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

</style>