<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `35FIT | ${content}` : `35FIT` }}
    </template>
  </metainfo>

  <div ref="progressLine"
   v-if="layoutOn"
   class="progress-line"></div>

  <transition name="bounce">
    <NotifyWarning v-if="loggedIn"
     title="Navigation warning"
     text="You are logged in. Log out to navigate to this page."
     color="default"
     @close="loggedIn = false" />
  </transition>
    <div class="wrapper">
  <SiteHeader v-if="layoutOn"/>
  <router-view v-slot="{ Component }">
    <Transition name="side"
     mode="out-in">
      <component :is="Component" />
    </Transition>
  </router-view>
    </div>

  <Transition name="bounce">
    <NoticeWarning v-if="notice"
                   @close="this.notice = false" />
  </Transition>

  <Transition name="bounce">
    <CookieWarning v-if="cookie"
     @close="this.cookie = false" />
  </Transition>

  <Transition name="bounce">
    <ModalWindow v-if="state.openBooking"
     @closeModal="state.toggleBooking()"
     title="Gonsiori 33"
     :subtitle="$t('dashboard.pages.booking.modal.newBook')">
      <BookClass :is-trial="state.isTrial" />
    </ModalWindow>
  </Transition>

  <Transition name="bounce">
    <FullFooter v-if="fullFooter"/>
  </Transition>

  <Transition name="bounce">
    <MainFooter v-if="!fullFooter" />
  </Transition>

</template>

<script>
import NoticeWarning from './components/warnings/NoticeWarning.vue';
import CookieWarning from './components/warnings/CookieWarning.vue';
import Footer from './components/layout/FooterComp.vue';
import Header from './components/layout/HeaderComp.vue';
import notFound from '@/assets/svg/notFound.svg';
import BottomPanel from './components/warnings/BottomPanel.vue';
import { useMeta } from 'vue-meta'
import NotifyWarning from './components/warnings/NotifyWarning.vue';
import { state } from './state.js'
import BookClass from './components/BookClass.vue';
import ModalWindow from './components/ModalWindow.vue';
import SiteHeader from "@/components/common/SiteHeader";
import MainFooter from "@/components/common/MainFooter";
import FullFooter from "@/components/common/FullFooter";

export default {
  name: 'App',
  setup() {
    useMeta({
      title: 'Connected to results | €49/kuu | Gym',
      htmlAttrs: {
        lang: 'et'
      },
      meta: [
        { property: "og:title", content: "35FIT | Connected to result | €49/kuu | AI Gym" },
        { property: "og:description", content: "AI-laadne fitness stuudio. Spordiklubi, jõusaal, personaaltreening, kindlad tulemused, kiire tagasiside, toitumis-/jõudlustestid." },
        { property: "og:url", content: "https://35fit.com" },
      ],
      description: "AI-laadne fitness stuudio. Spordiklubi, jõusaal, personaaltreening, kindlad tulemused, kiire tagasiside, toitumis-/jõudlustestid."
    })
  },
  components: {
    FullFooter,
    MainFooter,
    SiteHeader,
    Footer,
    CookieWarning,
    Header,
    ModalWindow,
    BottomPanel,
    NotifyWarning,
    BookClass,
    NoticeWarning
  },
  methods: {
    scrollProgress() {
      if (this.layoutOn) {
        const progressLine = this.$refs.progressLine;
        const currentOffset = window.pageYOffset;
        const pageHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
        const progress = (currentOffset / (pageHeight - windowHeight)) * 100;
        progressLine.style.width = progress + '%';
      }
    },
    // NOTE: delete warning upon component deletion after campaign
    loggedInWarn() {
      if (localStorage.getItem('token')) {
        console.log('logged in');
        this.loggedIn = true;
      }

    },
    showCookie() {
      // get cookie by its key name
      const cookieData = this.$cookies.get('cookie');
      if (cookieData === 'seen') {
        this.cookie = false;
        return
      }
      this.cookie = true;
    },
    showNotice() {
      // get notice by its key name
      const noticeData = this.$cookies.get('notice');
      if (noticeData === 'seen') {
        this.notice = false;
        return
      }
      this.notice = true;
    },
    layoutSwitch() {
      const currentRouter = this.$route.path;
      switch (currentRouter) {
        case '/':
          this.fullFooter = false;
          this.layoutOn = true;
          break;
        //case '/opengym':
        //case '/login':
        //case '/register':
        //case '/dashboard':
        case '/test':
          this.fullFooter = true;
          this.layoutOn = false;
          break;
        default:
          this.fullFooter = true;
          this.layoutOn = true;
          break;
      }
    },
    setLang() {
      const lang = localStorage.getItem('lang');
      if (lang) {
        this.$i18n.locale = lang;
      }
    },
    launchSequence() {
      this.setLang();
      this.layoutSwitch();
      window.addEventListener('scroll', this.scrollProgress);

      // cookies check
      setTimeout(() => {
        this.showCookie()
      }, 1000);

      // notice check
      setTimeout(() => {
        this.showNotice()
      }, 1000);
    }
  },
  data() {
    return {
      cookie: false,
      notice: false,
      layoutOn: false,
      notFound,
      loggedIn: false,
      fullFooter: true,
      state
    }
  },
  // watch for route changes
  watch: {
    '$route': 'layoutSwitch'
  },
  mounted() {
    this.launchSequence()
  },
}

</script>

<style>

.underline {
  text-decoration: underline;
}

/* temporary */

.beta-note {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.beta-note p,
.beta-note a {
  font-size: 14px;
  font-weight: 500;
}

.side-enter-active,
.side-leave-active {
  transition: all 0.3s ease-in-out;
}

.side-enter-from,
.side-leave-to {
  transform: scale(0.97);
  opacity: 0;
  filter: blur(10px);
  transform-origin: center center;

}


.left-enter-active,
.left-leave-active {
  transition: all 0.4s ease;
}

.left-enter-from,
.left-leave-to {
  opacity: 0;
  transform: translateX(-5%);
}

.slider-enter-active,
.slider-leave-active {
  transition: all 0.3s ease-in-out;
}

/* slides slide from one side to another  */



.slider-enter-from,
.slider-leave-to {
  opacity: 0;
  transform: translateX(-5%);

}

@font-face {
  font-family: 'NimbusSanL';
  src: local('NimbusSanL'), local('NimbusSanL'),
  url('./assets/fonts/NimbusSanL/NimbusSanL-Reg-webfont.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NimbusSanL';
  src: local('NimbusSanL'), local('NimbusSanL'),
  url('./assets/fonts/NimbusSanL/NimbusSanL-RegIta-webfont.woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'NimbusSanL';
  src: local('NimbusSanL'), local('NimbusSanL'),
  url('./assets/fonts/NimbusSanL/NimbusSanL-Bol-webfont.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NimbusSanL';
  src: local('NimbusSanL'), local('NimbusSanL'),
  url('./assets/fonts/NimbusSanL/NimbusSanL-BolIta-webfont.woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: local('Moderat'), local('Moderat'),
    url('./assets/fonts/Moderat/Moderat-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: local('Moderat'), local('Moderat'),
    url('./assets/fonts/Moderat/Moderat-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: local('Moderat'), local('Moderat'),
    url('./assets/fonts/Moderat/Moderat-Bold.woff2');
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

:root {
  --font: 'NimbusSanL', sans-serif;
  /*--font: 'Montserrat', sans-serif;*/
  /* --font: "Moderat", "Helvetica Neue", "Helvetica", sans-serif; */
  --dark-blue: #000939;
  --lighter-blue: #111d65;
  --light-green: #59B893;
  --lighter-green: #59B893d2;
  --yellow: #FFC700;
  --button-bc: #8c8b9e;
  --disabled: #8c8b9e;
  --divider: #e9e9eb;
  --shadow: 0 0 90px 5px rgba(162, 162, 162, 0.1);
  --bg: white;
  --red: #FF6376;
  --header-height: 80px;
  --menu-item-height: 150px;
  --padding: 0px 60px;
  --header-padding: 10px 60px;
  --intro-content-padding: 40px 60px;
  --gray: rgba(154, 154, 154, 0.05);
  --br: 10px;
  --card-br: 13.4px;
  --button-br: 40px;
  --dark-gray: #f1f2f3;
  --text-gray: #666e7f;
}

.page-name {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  max-width: 450px;
}


body {
  font-family: var(--font);
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

p {
  font-size: 18px;
  line-height: 24px;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media screen and (min-width: 480px) {
  .center-btn {
    margin: 0 auto;
    display: block;
  }

}

.red-btn {
  background: var(--red) !important;
  border: var(--red) 1px solid !important;
}

.text-warning {
  color: var(--yellow) !important;
}

.red-text {
  color: var(--red) !important;
}

.small-link {
  font-size: 15px;
  color: var(--dark-blue);
  font-weight: 600;
  transition: all 0.3s ease;
}

.green-text {
  color: var(--light-green) !important;
}

.blue-text {
  color: var(--dark-blue) !important;
  text-decoration-line: underline;
}

.small-link:hover {
  color: var(--lighter-green);
}

.marg-top {
  margin-top: 10px !important;
}

.marg-bot {
  margin-bottom: 10px !important;
}

.full-width {
  width: 100% !important;
  max-width: unset !important;
}

.menu-open {
  overflow: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.progress-line {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  width: 0;
  height: 4px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--light-green);
  transition: all 0.05s linear;
}

.add-button {
  position: fixed;
  bottom: 20px;
}

.footnote {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray);
}

.bounce-enter-active {
  animation: bounce-in 0.2s;
}

.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}

.bounce-enter,
.bounce-leave-to {
  transform-origin: center center;
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 660px) {
  :root {
    --padding: 0 1rem;
    --header-padding: 10px 20px;
    --intro-content-padding: 40px 20px;
  }
}
</style>
