<template>
    <div class="table-wrap">
        <div class="calendar-controls">
            <button @click="this.dateManager('back')">
                <img :src="arrowBlack"
                 alt="backwards"
                 class="calendar-back">
            </button>
            <h4 class="date">{{ monthName(date) }}
                {{ new Date(date).getFullYear() }}
            </h4>
            <button @click="this.dateManager('forward')">
                <img :src="arrowBlack"
                 alt="forward"
                 class="calendar-forward">
            </button>
        </div>
        <table>
            <thead>
                <tr>
                    <th>{{ $t('weekdays.mon') }}</th>
                    <th>{{ $t('weekdays.tue') }}</th>
                    <th>{{ $t('weekdays.wed') }}</th>
                    <th>{{ $t('weekdays.thu') }}</th>
                    <th>{{ $t('weekdays.fri') }}</th>
                    <th>{{ $t('weekdays.sat') }}</th>
                    <th>{{ $t('weekdays.sun') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="week in renderCalendar()"
                 :key="week">
                    <td v-for="day in week"
                     :key="day">
                        <div v-if="markDay(day)"
                         class="day-wrap">
                            <div class="had-training">{{ day }}</div>
                        </div>
                        <div v-else>{{ day }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import arrowBlack from '@/assets/svg/arrow-black.svg';

export default {
    name: 'VisitCalendar',
    data() {
        return {
            date: new Date(),
            visits: [],
            arrowBlack
        }
    },
    methods: {
        monthName(date) {
            return (new Date(date).toLocaleString(this.$i18n.locale, { month: 'long' }))
        },
        markDay(d) {
            var dateInit = new Date(this.date);
            const month = dateInit.getMonth();
            const year = dateInit.getFullYear();
            // form string from date in format YYYY-MM-DD and check if month and day is two digits
            const date = (d < 10 ? '0' + d : d) + '.' + (month + 1 < 10 ? '0' + (month + 1) : month + 1) + '.' + year;

            return this.visits.includes(date);
        },
        async fetchVisits() {
            await fetch('https://app.35fit.com/api/users/calendar', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date: new Date(this.date)
                })
            })
                .then(response => response.json())
                .then(data => {
                    // iterate over data and push to visits array
                    this.visits = data.finished_trainings
                })
                .catch(error => {
                    console.log(error);
                });
        },
        dateManager(action) {
            var dateInit = new Date(this.date);
            switch (action) {
                case "forward":
                    this.date = dateInit.setMonth(dateInit.getMonth() + 1);
                    this.fetchVisits()
                    break;
                case "back":
                    this.date = dateInit.setMonth(dateInit.getMonth() - 1);
                    this.fetchVisits()
                    break;
                default:
                    this.date = dateInit.setMonth(dateInit.getMonth() + 1);
                    this.fetchVisits()
                    break;


            }
        },
        renderCalendar() {
            const date = new Date(this.date);
            const month = date.getMonth();
            const year = date.getFullYear();
            const firstDay = new Date(year, month, 0);
            const lastDay = new Date(year, month + 1, 0);
            const daysInMonth = lastDay.getDate();
            const daysInWeek = 7;
            const firstDayIndex = firstDay.getDay();
            const numberOfWeeks = Math.ceil((daysInMonth + firstDayIndex) / daysInWeek);
            const calendar = [];
            let day = 1;
            for (let i = 0; i < numberOfWeeks; i++) {
                const week = [];
                for (let j = 0; j < daysInWeek; j++) {
                    if (i === 0 && j < firstDayIndex) {
                        week.push('');
                    } else if (day > daysInMonth) {
                        week.push('');
                    } else {
                        week.push(day);
                        day++;
                    }
                }
                calendar.push(week);
            }
            return calendar;
        }
    },
    mounted() {
        this.fetchVisits();
    },
}
</script>
<style scoped>
.date {
    text-transform: uppercase;
    font-weight: 800;
}

.calendar-back {
    transform: rotate(90deg);
    width: 30px;
}

.calendar-forward {
    transform: rotate(-90deg);
    width: 30px;
}

.calendar-controls {
    display: flex;
    width: 100%;
    align-items: center;
    width: 100%;
    gap: 15px;
    justify-content: center;
}

.had-training {
    background-color: var(--light-green);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-wrap {
    background-color: var(--dark-gray);
    border-radius: var(--card-br);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table thead th {
    font: var(--font);
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    text-align: center;
    width: calc(100% / 7);
    user-select: none;
    cursor: default;
}

table tbody td {
    font: var(--font);
    font-size: 13px;
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
    user-select: none;
    cursor: default;
}

td div {
    min-height: 25px;
}


@media (max-width: 340px) {
    table thead th {

        font-size: 12px;

    }

    table tbody td {
        font: var(--font);
        font-size: 12px;
        font-weight: 500;
        padding: 10px 0;
        text-align: center;
        user-select: none;
        cursor: default;
    }
}
</style>
