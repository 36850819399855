export default {
  "pagenames": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧАВО"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])}
  },
  "warnings": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C 1-го сентября все клиенты будут получать счета на следующий месяц в первый день каждого месяца с датой оплаты до 7-го числа каждого месяца."])}
  },
  "weekdays": {
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вт"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ср"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чт"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пт"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сб"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс"])}
  },
  "update112": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин уже доступен!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБНОВЛЕНИЕ"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарабатывайте подарки за поход в зал!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT - первый зал, который платит за то, что вы правильно тренируетесь. Новая функция для клиентов - магазин подарков."])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как пользоваться?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронируйте тренировку через личный кабинет, приходите на нее и получите 1 балл за каждую*"])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Баллы могут получать все клиенты, имеющие любой действующий договор на момент 1-го числа каждого месяца. Чтобы получить баллы, количество посещений не должно превышать лимита в месяц по вашему тарифу. Если лимит превышен, вы не получите баллы за этот месяц."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копите баллы и расплачивайтесь ими до 70% от суммы за товары и услуги"])}
  },
  "update130": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые батончики уже в магазине"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБНОВЛЕНИЕ"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В нашем магазине пополнился ассортимент"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["И теперь Вы можете купить энергетические батончики Harvest Republic за баллы, один батончик стоит 5 баллов."])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как заработать баллы?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрируйтесь на тренировки в личном кабинете и приходите на них, за каждый приход Вы получаете баллы*."])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Баллы могут получать все клиенты, имеющие любой действующий договор на момент 1-го числа каждого месяца. Чтобы получить баллы, количество посещений не должно превышать лимита в месяц по вашему тарифу. Если у вас нет активного контракта более 2-ух недель - бонусный счет будет обнулен."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательно пробуйте и делитесь мнением."])}
  },
  "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счастливого <br>Нового Года!"])},
  "newYearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда 35FIT желает вам веселого и спортивного Рождества и энергичного Нового года!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "cookiehead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем куки"])},
  "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT использует куки для правильной и удобной работы сайта. Вы можете проверить <a href='/docs/GDPR.pdf'>политику конфиденциальности</a>, чтобы понять, как мы используем куки и персональную инфомрацию. Продолжая пользоваться этим сайтом, Вы соглашаетесь с использованием этимтих файлов"])},
  "noticeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорогие клиенты!"])},
  "noticeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В связи с работами по обслуживанию на стороне внешнего сервиса 31 марта в период с 21:30 до 22:30 может быть не доступна оплата через Paysera"])},
  "newInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый счет"])},
  "meta": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы - современный спортзал с ИИ! Спортклуб, персональные тренировки, фитнес-бутик студия, тесты на метаболизм и многое другое."])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Революционный ИИ спортзал. У нас есть Milon, групповые на Power Plate, TRX, кардио и метаболизм тесты"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От €49/месяц вместо €49/тренировка. Персональная поддержка с ИИ подобным оборудованием. Сonnected to results"])}
  },
  "dashboard": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги и резервы"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
    "gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подарки"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть"])},
    "pages": {
      "gifts": {
        "earnTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарабатывайте баллы"])},
        "earnSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РУКОВОДСТВО"])},
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["балл"])},
        "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["баллов"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["евро"])},
        "backToRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["к правилам"])},
        "howEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как получить больше баллов?"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали продукта"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий месяц"])},
        "toEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тренировок, чтобы заработать"])},
        "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБЯЗАТЕЛЬНО"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОПЦИОНАЛЬНО"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные баллы"])},
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получите по 5 баллов за каждые Dynostics тест, групповую/персональную тренировку, которые вы купили. Milon test дает вам 2.5 баллов, если он был куплен"])},
        "discountText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Плата ", _interpolate(_named("price")), " баллами дает 70% скидки от обычной цены. Скидка уже учтена в стоимости."])},
        "discountTextFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот товар имеет 100% скидки от обычной цены. Скидка уже применена"])},
        "consistency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постоянство"])},
        "modalText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Завершите ", _interpolate(_named("trainings")), " тренировок (не больше), чтобы получить награду. Тренировка считается, когда вы ее забронировали и пришли. Балы начисляются каждое первое число месяца"])},
        "noPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет активного тарифа. Чтобы получить баллы, вы должны быть подписаны на тариф"])},
        "currTraining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сейчас у вас ", _interpolate(_named("currentTraining")), " тренировок из ", _interpolate(_named("trainings"))])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История заказов"])},
        "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет заказов"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ"])},
        "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата заказа"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
      },
      "contract": {
        "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние контракты"])},
        "prolong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить"])},
        "discounts": {
          "workerDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У Вас есть скидка работника. Цена договора €17/месяц"])},
          "testDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть скидка на тест Dynostics (Performance). Цена теста 25€"])}
        },
        "paymentModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите метод"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код билета, который приобрели у Stebby."])},
          "stebbyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте код Stebby, чтобы заплатить за услуги вашего пакета"])},
          "payseraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте Paysera, чтобы заплатить за свой пакет, используя электронную среду своего банка или любой другой поддерживаемый метод оплаты."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать код"])}
        },
        "currentContract": {
          "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановлен"])},
          "currentContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий контракт"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В месяц"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заключен"])},
          "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекает"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпуск"])},
          "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяцев"])},
          "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете отменить или изменить договор"])},
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "newContraсt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписать договор"])},
          "cancelModal": {
            "cancContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить контракт"])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание"])},
            "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв"])},
            "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину"])},
            "reasons": {
              "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT слишком далеко от дома/работы"])},
              "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы и услуги слишком дорогие"])},
              "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональные причины, не связанные с клубом"])},
              "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип тренировки мне не подходит"])},
              "fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я не получаю достаточно обратной связи от тренера"])},
              "sixth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я не вижу результата"])},
              "seventh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проблема не описана/другое"])}
            },
            "warnUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны предупредить хотя бы за два месяца перед разрывом договора."])}
          }
        },
        "paymentWidget": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи"])},
          "inDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш счет к оплате"])},
          "allOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все окей!"])},
          "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить до"])},
          "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К оплате"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След. счет"])},
          "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
          "prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоплата"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпуск"])},
          "modal": {
            "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш отпуск начнется с "])},
            "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я подтверждаю, что я ознакомлен и согласен с датой действия и условиями оформления отпуска."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпуск от тренировок"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание"])},
            "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
            "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяцев"])},
            "short1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маленький перерыв"])},
            "short2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длинный отпуск (не забывайте про активность!)"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный период отпуска - 2 месяца подряд за один договор. Отпуск применяется с ближайшего платежного цикла"])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время отпуска вы платите уменьшенную цену в <b>8 евро за месяц</b>. Вы не сможете зайти в клуб во время отпуска."])},
            "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период"])},
            "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить отпуск"])}
          },
          "noPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет платежей"])}
        },
        "buyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить новый пакет"])},
        "changeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пакет"])}
      },
      "profile": {
        "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код двери"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])}
      },
      "noContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет контракта"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, купите новый договор"])},
        "toPricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К ценам"])}
      },
      "invoices": {
        "noInvoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все окей!"])},
        "noInvoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас нет неоплаченных счетов"])},
        "invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть неоплачнные счета"])},
        "invoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, у вас есть неоплаченный счет"])},
        "prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовим счет..."])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет #"])},
        "payBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить до: "])},
        "noInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас еще нет счетов"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])}
      },
      "booking": {
        "criticalError": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Попробуйте перезагрузить страницу"])}
        },
        "select": {
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронировано"])},
          "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])}
        },
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платные услуги"])},
        "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервы"])},
        "noClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет бронирований"])},
        "noCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет отмененных тренировок"])},
        "noFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет завершенных тренировок"])},
        "modal": {
          "availableForActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервы и дополнительные услуги доступны только при наличии действующего пакета"])},
          "chooseTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать пакет"])},
          "availableForWithoutDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резервы и дополнительные услуги доступны только при отсутствии неоплаченных счетов"])},
          "notime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступного времени в этот день, пожалуйста, выберите другой."])},
          "lasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длится"])},
          "newBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая бронь"])},
          "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно создать резерв"])},
          "firstTrialText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату для пробной тренировки"])},
          "selectService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип услуги"])},
          "selectAdditionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите услугу"])},
          "selectFreezePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату начала заморозки и период заморозки"])},
          "selectExtraDaysCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите количество дополнительных тренировок"])},
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
          "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])},
          "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите время"])},
          "orderService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать услугу"])},
          "createBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать резерв"])},
          "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тренировка"])},
          "milonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычная круговая Milon тренировка с ИИ оборудованием"])},
          "included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено в договор"])},
          "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])},
          "noWeekends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недоступно на выходных"])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недоступно"])},
          "milonOther": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуем взять полотенце"])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуем взять бутылку для воды"])}
          },
          "tests": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon тесты"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От €15"])}
          },
          "personal": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональная тренировка"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональная тренировка с тренером"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€30/тренировка"])}
          },
          "extradays": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные тренировочные дни"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки в Basic Training Zone"])},
            "oneDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 тренировка"])},
            "threeDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 тренировки"])}
          },
          "sgt": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групповая тренировка"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групповая тренировка с тренером на 3 человека"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€15/тренировка"])}
          },
          "additional": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витаминная вода, заморозка"])}
          },
          "additionalServices": {
            "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витаминная вода (15 напитков) €7.95"])},
            "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморозка"])},
            "oneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 месяц €8.00"])},
            "twoMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 месяца €16.00"])}
          }
        }
      }
    }
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка"])},
  "header": {
    "menu": {
      "home": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться на главную страницу"])}
      },
      "training": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 минут 12 раз в месяц"])}
      },
      "faq": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧАВО"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответы на общие вопросы"])}
      },
      "pricing": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подходящий тариф"])}
      },
      "packages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты и Тарифы"])}
      }
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить сейчас"])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧАВО"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соцсети"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила клуба"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика приватности"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "pricelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прайс-лист"])},
    "buyStebby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата через Stebby"])}
  },
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "home": {
    "trialTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='blue-highlight'>БЕСПЛАТНАЯ </span><br>ПРОБНАЯ<br>"])},
    "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ спортзал, <br> созданный <br> для вас."])},
    "firstSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто. Безопасно. Эффективно."])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутик фитнес-студия"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальный подход"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка и помощь от тренеров благодаря автоматической системе."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умное оборудование"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ тренажеры подстраиваются к вашиы параметры за секунды."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономия времени"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплексная круговая тренировка занимает всего 35 минут."])}
      }
    },
    "secondSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подходит каждому"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любой уровень. Любая цель."])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развитие маскулатуры"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплексная тренировка с прогрессирующим весом."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избавление от боли"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим нацелен на причины, лежащие в основе дискомфорта."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избавление от лишнего веса"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочетание тренировок на выносливость и силу."])}
      },
      "card4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Построение фигуры"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальная силовая тренировка для стройной фигуры."])}
      }
    },
    "thirdSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренируйтесь с улыбкой"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая тренировка"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оборудование в зале"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь - все тренажеры, необходимые для тренировки всего тела."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без очереди"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никогда не ждите своей очереди! Тренируйтесь одновременно."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая формула"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система “не думай - делай”. Тренируйтесь по готовой программе."])}
      }
    },
    "mediaContent": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальные преимущества"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная эффективность"])},
      "powerPlate": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрация всего тела"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальные микро вибрации активируют больше мышц, сжигайте больше калорий, улучшите циркуляцию и помогайте мышцам восстановиться."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрация всего тела"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Растяжка с вибрацией"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрационные платформы можно использовать для растяжки после тренировки, чтобы облегчить мышечную боль для более быстрого восстановления. "])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка с вибрацией"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки с использованием вибрационных платформ имеют множество преимуществ, например, улучшение плотности костей и увеличение скорости сжигания жира. "])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация в 35FIT"])}
      },
      "trugge": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витаминная вода"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите 37% дневной дозы важных витаминов всего в одном напитке без сахара во время тренировки."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витаминная вода"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкусы"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более 30 вкусов, которые меняются каждый месяц. 5 вкусов на выбор каждый месяц."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состав"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витамины В5, В6, В12, Е, ниацин, фолиевая кислота. Всего 3 ккал на один напиток. Концентраты сделаны в Германии."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация в 35FIT"])}
      },
      "tests": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты для отслеживания прогресса"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свою гибкость, баланс и скорость реакции. Все в одном тесте Milon, который занимает всего 5 минут. Результаты тестов а так же динамика доступны моментально на экране."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dynostics"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свой метаболизм"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется всего 5 минут для анализа, который покажет ваш текущий метаболизм, а также рекомендации по питанию для быстрого достижения цели. "])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свою производительность"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В нашем клубе ваше сердце будет связано с кардиотренажерами с помощью пульсометра. Узнайте индивидуальный пульс для эффективного сжигания жира или улучшения физической подготовки."])}
        },
        "mediaInner3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свою мобильность"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте свою гибкость, баланс и скорость реакции. Все в одном тесте Milon, который занимает всего 5 минут. Результаты тестов а так же динамика доступны моментально на экране."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация в 35FIT"])}
      }
    }
  },
  "training": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Революционный смарт-фитнес для лучших результатов"])}
    },
    "segmented": {
      "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групповые"])}
    },
    "circle": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая тренировка"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего 35 минут"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая тренировка"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Машины расположены по кругу, идеальное сочетание силовых и кардио тренажеров."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все под контролем"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сердце, вес, прогресс и даже движение во время тренировки всегда видны на экране."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ оборудование"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Машины запоминают вас и подстраиваются под ваш вес, рост и силу."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подходит каждому"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любой уровень. Любая цель."])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экцентрика"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отрицательном типе тренировки мышцы используются наиболее эффективно."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адаптивный режим"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически регулирует вес в режиме реального времени. Хорошо для гипертрофии."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изокинетика"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопротивление зависит от скорости. Идеально подходит для терапии и развития силы."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моментальный фидбек"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка и прогресс"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика тренировки"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облачная система соединяет ваш телефон с оборудованием."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умный контроль"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес увеличивается в зависимости от ваших успехов и статистики."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следите за динамикой"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенный доступ к данным, которые можно увидеть на телефоне."])}
        }
      },
      "section4": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оборудование в клубе"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучший подход"])},
        "media": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
          "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новатор в мире цифрового фитнес оборудования. Более 1000 клубов во всем мире используют круговую систему Milon. 35FIT единственный Milon клуб в Эстонии."])},
          "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренируйтесь с улыбкой"])},
          "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто и эффективно"])},
          "inner": {
            "first": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение многих проблем"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не знаете чем заняться в обычном спортивном клубе? Не можете достичь желаемых результатов? Студия 35FIT — решение всех ваших проблем."])}
            },
            "second": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерактивный дисплей"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисплей обеспечивает мгновенную обратную связь во время тренировки, которая помогает правильно выполнять упражнения, а так же моментально добавлять веса."])}
            },
            "third": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда 35 минут достаточно"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регулярная интенсивная нагрузка и правильный режим питания - ключи к вашему успеху. 2-3 занятия в неделю по 35 минут идеально подходит для тех, у кого нет времени."])}
            }
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простая тренировка"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко и эффективно"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая тренировка"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Силовые тренировки всего тела, включая кардио, выполняются на тренажерах, расположенных по кругу, без очереди. Не теряйте время, просто тренируйтесь!"])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контроль"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следите за динамикой через приложение, контролируйте движение тренировки через интерактивный дисплей. Ваше сердечный ритм также отслеживается, начиная с пакета SUPER FLEX."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ оборудование"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте персональный браслет, чтобы войти в систему и начать тренировку. Это обеспечит подходящие веса и диапазон движения. Система рекомендует нагрузки и сердечную прогрессию."])}
        }
      }
    },
    "tests": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат - это микс регулярных тренировок и питания"])},
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для эффективного достижения результатов мы рекомендуем тестироваться, отслеживать питание, физическую подготовку и общую динамику."])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки с индивидуальным планом питания – залог вашего успеха."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжите свое сердце с оборудование для достижения цели."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест Milon"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрый способ зафиксировать свои показатели гибкости, баланса и динамику."])}
          }
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моментальный отзыв"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка и прогресс"])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальный контроль"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренер проанализирует ваши результаты и поможет определить стратегию и следовать ей."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстро и весело"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест на метаболизма занимает 5 мин, физическую подготовку - 15, гибкость - 5 мин."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда под рукой"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите доступ к своим результатам в любое время в любом месте"])}
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка с улыбкой"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстро и полезно"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70% вашей цели зависит от стратегии сбалансированного питания. Тест вместе с консультацией тренера даст вам полное представление о питании согласно цели."])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После теста мы записываем ваши результаты в систему, чтобы кардио тренажеры автоматически подстраивались под вашу цель (сердечный ритм). Так же вы будете знать уровень физической подготовки, максимальное потребление кислорода и регенерацию."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест на физическую форму и гибкость"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только веселье! Быстрый тест для проверки и фиксации вашей гибкости, скорости реакции, силы прыжка и баланса. После нескольких тестов ваш тренер проанализирует динамику."])}
        }
      }
    },
    "groupTraining": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соревновательно. Весело."])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки в малых группах"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляемая тренировка"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программы до 8 недель с тренером в группе до 4 человек."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оборудование"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX, BootyBuilder, и Power Plate используются на тренировках."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономия времени"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целенаправленная интенсивная тренировка за меньшее время."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигните своей цели"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программы тренировок"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное сосредоточение на активации и формировании ягодиц."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подходит для увеличения вашей силы и физической подготовки."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия нацелена на тело и разум. Включает в себя йогу и пилатес."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальные преимущества"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрационная тренировка"])},
        "card1": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжигайте жир быстрее"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Микровибрации заставят вас быстрее сжигать жир.."])}
        },
        "card2": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улучшите гибкость"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрации всего тела оказывают значительное влияние на гибкость."])}
        },
        "card3": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улучшите прочность костей"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрации сильнее нагружают ваши кости, что делает их сильнее."])}
        }
      },
      "media": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другой подход"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оборудование на сессии"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрирующая платформа вызывает немедленные сокращения рабочих и стабилизирующих мышц, улучшая баланс и силу."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научный подход"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат - быстрее"])},
        "inner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная эффективность - меньше времени"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С виброплатформой 55 минут превращаются в 25 минут, так как во время такой тренировки задействуется больше мышц"])},
          "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные преимущества"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исследования показали, что тренировки с вибрацией также могут укреплять кости и улучшать кровообращение."])}
        },
        "mediaContent": {
          "first": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty Builder"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренажер для тазобедренного сустава дает тренирующимся оптимальную амплитуду для проработки большой ягодичной мышцы."])}
          },
          "second": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX использует упражнения с собственным весом для развития силы, баланса, гибкости и стабильности тела."])}
          }
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка в малых группах"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программы тренировок"])},
          "first": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы обещаем, ваши ягодицы будут гореть от этой безумной тренировки, которая включает в себя лучшее оборудование и упражнения для активации мышц."])}
          },
          "second": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увеличение мышечной массы достигается длительными силовыми упражнениями на виброплатформе. Вибрация активирует больше мышц, сжигает больше жира."])}
          },
          "third": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идеальная программа для релаксации и концентрации внимания на телесной связи, включающая массаж на виброплатформе."])}
          }
        }
      }
    }
  },
  "pricing": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подходящий пакет"])}
    },
    "other": {
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия договора"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])}
      },
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатно"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мес."])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше"])},
      "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньше"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прайс-листа"])},
      "planChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При смене плана средства за оплаченный период и услуги не возвращаются"])},
      "acknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я подтверждаю, что прочитал и принимаю условия"])},
      "payWithStebby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата через Stebby"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждаю"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бронировать"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заказать"])},
      "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительно"])},
      "groups": {
        "circleTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
        "groupTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональные"])}
      },
      "segments": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональная"])}
      }
    }
  },
  "login": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для сброса пароля отправлена на ваш адрес электронной почты, если он существует в нашей базе данных"])},
    "resetPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите действительный адрес электронной почты"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти в аккаунт"])},
    "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
    "serverError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сервера"])},
    "unableLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно войти"])},
    "emptyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные для входа не могут быть пустыми"])},
    "emailPasswordIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email или пароль неверны"])}
  },
  "registration": {
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здравствуйте! 👋🏻"])},
    "introText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давайте познакомимся?"])},
    "changableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация в 35FIT"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "idcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID код"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
    "prefLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпочитаемые языки"])},
    "selectLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
    "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите все языки, которые знаете. Так мы подберем вам тренера на пробную тренировку"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Женский"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный адрес"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить пароль"])},
    "dataAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я даю согласие на <a target='_blank' class='blue-text' href='/docs/GDPR.pdf'>использование и обработку моих персональных данных</a>"])},
    "rulesAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен с <a target='_blank' class='blue-text' href='/docs/Club_Rules.pdf'>правилами клуба</a>"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получилось"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отправили вам электронное письмо. Проверьте это, чтобы завершить регистрацию"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "errorCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка создания учетной записи"])},
    "emptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пустые строки"])},
    "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните все поля"])},
    "stageText": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вперед, "])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы почти у цели!"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обезопасьте аккаунт"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финиш!"])}
    },
    "passErrors": {
      "doNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
      "doNotMatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте пароль"])},
      "tooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль слишком короткий"])},
      "tooShortText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте не менее 8 символов, включая цифры и буквы"])},
      "legalConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое согласие"])},
      "legalConsentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, подтвердите обработку данных и правила"])},
      "invalidMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email"])},
      "invalidMailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте свою электронную почту"])}
    },
    "trialNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробная тренировка не требуется"])},
    "iHaveNoIdCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У меня нет ID кода"])}
  },
  "advert": {
    "blackfriday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Black</span><span>friday</span>"])},
    "bfpoints": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатная групповая"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протеиновый коктейль на пробной"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатный MILON тест"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнай больше у тренера"])}
    },
    "trialpromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробная тренировка бесплатно! До 28.08"])},
    "trialAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробная тренировка доступна с 17.04"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрируйтесь в 35FIT!"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "summerPromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='intro-gradient' ref='summer'>ЛЕТНЯЯ</span><br>КАМПАНИЯ"])},
    "summerf1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только €35/месяц"])},
    "summerf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневный пакет"])},
    "summerf3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступен до 31.07"])},
    "summerBottomPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет SUMMER за €35/месяц"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приведи друга и получи бесплатный Dynostics тест!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
    "regCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='green-highlight'>БЕСПЛАТНАЯ</span><br>РЕГИСТРАЦИЯ"])},
    "regCampaignText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купите контракт во время пробной"])},
    "regCampaignText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не платите за заключение договора"])},
    "regCampaignText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сэкономьте €30"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробная персональная<br>тренировка всего за €15"])},
    "descriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробная 40 мин тренировка на тренажерах Milon, PowerPlate"])},
    "descriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Milon Fitness теста в подарок"])},
    "descriptionThird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определение цели тренировок"])},
    "descriptionFourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При заключении контракта сразу регистрационный взнос 0 евро"])}
  },
  "faq": {
    "pagename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧАВО"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите ответ на самые распространенные вопросы"])},
    "contract": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как получить бесплатный тест, если я приведу друга?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш друг должен сообщить Ваше имя при подписании договора, написав на нашу почту. Мы сразу свяжемся с Вами, чтобы забронировать время на тест."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какие пакеты Вы предлагаете?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент у нас есть <b>6 тарифов</b>. У каждого из них есть свои преимущества. Мы рекомендуем брать такие пакеты как ALL under CONTROL или Connected to result, поскольку в данных пакетах имеется большее количество преимуществ для наиболее быстрого достижения цели. Вы можете сравнить пакеты и цены на странице <a href='/pricing' class='green-text'>цены</a>"])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда начинает абонемент действовать?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовый период, начинает действовать с момента регистрации на сайте. Обычный абонемент начинает действовать с момента оформления договора на сайте. Договор вступает в силу, когда вы соглашаетесь с условиями и нажимаете на кнопку 'Подписаться' на странице цены."])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно ли приобрести билет на одноразовый вход?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас можно приобрести  билеты на персональные тренировки в маленьком зале. Цена одной тренировки - 25 евро + 8 евро за пропуск на единовременное посещение. Если хотите попробовать наши тренировки, то Вы всегда можете приобрести пробный период на 3 пробные тренировки."])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что такое депозит за браслет?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед тем, как начать заниматься в нашем клубе, мы выдаём специальный браслет, с помощью которого Вы сможете открывать входную дверь и активировать тренажёры. Браслет является собственностью клуба. Мы взимаем депозит из-за риска его потери. Когда Ваш договор заканчивается, и Вы не собираетесь его продлевать, то браслет нужно вернуть. Сразу после возврата браслета мы возвращаем депозит на Ваш банковский счёт в течение недели после получения браслета."])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могу ли я платить через Stebby?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Да</b>, наши услуги можно оплатить через Stebby. Билет можно купить <a class='green-text' href='https://app.stebby.eu/pos/35fit.com'>здесь</a>, а активировать на сайте по кнопке оплатить - <a class='green-text' href='/dashboard#contract'>здесь</a>"])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как и когда должны оплачиваться счета?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За 10 дней до дня оплаты Вы будете получать счета на вашу электронную почту, которую Вы указали при регистрации. Если Вы не получили письмо, пожалуйста, проверьте спам-ящик. Вы также можете найти свои счета на вашем личном аккаунте. <b>Войти - Счета</b>. Счета могут быть оплачены через <b>банковский перевод, на веб-сайте через Paysera или Stebby. Мы не принимаем оплату наличными или физической банковской картой.</b> Счета должны быть оплачены даже в том случае, если Вы по каким-то причинам не посещаете клуб, поскольку договор составлен по принципу абонентской платы."])}
      },
      "eight": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могу ли я досрочно завершить договор?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>У нас есть два вида договора: обязательный со строгой датой окончания или обычный</b>. Обычные контракты это: ALL under CONTROL, Connected to result, Super Flex, Flex. Обязательным договором со строгой датой окончания являются SMART и IDEAL. <b>Обычный договор может быть завершён досрочно в любое время и без причины</b>, но Вы должны предупредить об этом клуб <b>за 2 месяца</b> до фактического окончания предоставления услуги. <b>Обязательный договор не может быть завершён раньше срока окончания договора</b>, и Вы должны оплачивать весь период Вашего договора (даже если Вы не посещаете клуб). <b>Исключением для обоих видов договора является травма, которая исключает занятия спортом (в данном случае Вы должны предоставить нам действующую справку от лечащего врача). Договор будет завершён незамедлительно</b>."])}
      },
      "ninth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Могу ли я взять отпуск?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете взять до двух месяцев отпуска в зависимости от выбранного абонемента. Отпуск оформляется на полный месяц, взять отпуск на 10 дней или 2 недели нельзя. Каждый месяц отпуска стоит 8 евро вместо обычной цены Вашего абонемента."])}
      },
      "tenth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что будет, если я не оплачу счёт вовремя?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если Вы не оплачиваете счета вовремя, то вход в зал будет автоматически заблокирован, и Вы не сможете попасть внутрь до тех пор, пока долг не будет погашен. Если счета не оплачиваются в течение длительного срока, тренажёры также будут заблокированы."])}
      }
    },
    "training": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нужно взять с собой на тренировку?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для комфортной тренировки, Вам понадобятся: удобная и чистая спортивная одежда, сменная спортивная обувь, полотенце и бутылка."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как попасть в клуб?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте свой личный браслет или пин-код. Если Вы забыли пин-код, войдите в свой аккаунт и зайдите в “Профиль” - там Вы сможете его увидеть. Если дверь не открывается ни с помощью  браслета ни с помощью пин-кода, то проверьте, все ли Ваши счета оплачены и не закончился ли Ваш договор."])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть ли в зале тренер?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренер объяснит  весь процесс во время первой тренировки и покажет как можно следить за своими результатами через нашу систему. Обычно тренер в зале в будние дни по вечерам, но если Вам нужна консультация с тренером, когда его нет на месте, то Вы всегда можете связаться с нами по электронной почте или по телефону и мы можем подобрать время для встречи с тренером."])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько длится тренировка?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировка занимает примерно 35 минут + время на смену одежды и принятия душа."])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно ли мне каждый раз бронировать время на тренировку?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Мы настоятельно рекомендуем</b> бронировать каждую тренировку на сайте. Количество людей в одно время ограничено ради Вашего комфорта и безопасности. Бронь помогает нам проанализировать количество людей в зале. Также наличие брони на тренировку позволяет вам зарабатывать бонусные баллы, которые можно потратить в магазине подарков."])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как я могу отслеживать свои результаты?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас есть приложение <a class='green-text' href='https://www.milonme.com/login'>Milon Me</a>. Для использования войдите, используя свои учётные данные, которые Вам предоставили во время первой тренировки."])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что будет, если я потеряю браслет и/или пульсометр?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если Вы потеряете браслет, то Вам не будет возвращён депозит. В случае потери пульсометра предусмотрен штраф в размере 35 евро."])}
      }
    },
    "other": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть бассейн?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет, у нас нет бассейна или бани. У нас есть только душ."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть ли у вас парковка?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, у нас не отведена отдельная парковочная зона. В будние дни Вы можете парковаться возле  магазина Selver, который находится неподалеку. На выходных Вы можете парковаться рядом с клубом бесплатно."])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно ли купить у Вас воду?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас есть аппарат с водой. Большинство абонементов включают в себя воду и спортивные напитки. Если Ваш абонемент не включает воду, можно купить доступ к машине с водой отдельно - 7,95 евро в месяц, 15 напитков."])}
      }
    },
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Галерея"])},
    "about": {
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "openHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работаем"])},
      "monFri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн-Пт"])},
      "satSun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сб-Вс"])},
      "stateHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гос. праздники"])},
      "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офис"])}
    }
  },
  "homeNew": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовая тренировка"])},
    "offerSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["включает фитнес-тест"])},
    "about": {
      "right": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто. Безопасно. Эффективно."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 FIT предлагает персонализированную систему тренировок, чтобы вы могли достичь своих фитнес-целей с наилучшим вовлечением мышц, которое только можно себе представить"])}
      }
    },
    "classes": {
      "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро открытие"])}
    },
    "trial": {
      "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45 минут сессия за 15"])},
      "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовая тренировка"])},
      "thirdLine": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эксклюзивное оборудование Milon и Power Plate"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включает 2 бесплатных фитнесс теста Milon"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определение целей тренировок"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При заключении контракта сразу регистрационный взнос 0"])}
      ],
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить сейчас"])}
    },
    "contacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы работы"])},
      "monFri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн-Пт"])},
      "satSun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сб-Вс"])},
      "holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праздники"])}
    },
    "feedback": {
      "title": {
        "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас остались"])},
        "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вопросы?"])},
        "thirdLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])}
      },
      "form": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш вопрос"])}
      },
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
    }
  },
  "basicTrainingZone": {
    "lead": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты\nи тарифы"])}
    },
    "about": {
      "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты от "])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
      "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобретите пакет Start+ чтобы лучше\nпонять, какой тренинг лучше всего подходит именно вам!"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты"])}
    },
    "slider": [
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минут"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняя всего два круга за цикл, тренажеры обеспечивают оптимальную тренировку всего тела всего за 35 минут.\nСиловые и кардиотренажеры обеспечивают эффективную круговую тренировку всего тела"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зоны тренинга"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Endurance Training Zone активизирует ваш метаболизм и сделают вашу тренировку идеальной.\nУпражнения, нагрузки, темп и время отдыха разработаны для индивидуальных тренировочных программ"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["типа тренинга"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для достижения персональной цели план тренировок периодизирован тренером различными видами нагрузки - Adaptive, Isokinetic, Normal, Eccentric"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки основаны на последних достижениях спортивной науки и физиотерапии. Подходят - от восстановления пациентов до соревнующихся спортсменов"])}
      }
    ],
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особенности Basic Training Zone"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализированные тренировки с использованием инновационных технологий Milon и Clmbr для быстрого достижения ваших целей — от восстановления до интенсивного фитнеса"])},
      "slides": [
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Круговая тренировка подходит для любых целей: Muscle Buliding, Weight Reduction, Pain-Free, Firming"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренировки на  нашем электронном оборудовании предлагают постоянно разнообразный опыт с контролем и интеллектуальным управлением"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренажер Clmbr обеспечивает высокоинтенсивную кардиотренировку с минимальной негативной нагрузкой на ваши суставы"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milonazer предлагает самый быстрый и простой способ начать тренировку. За считанные секунды инновационная система сканирования измеряет тело на основе биометрических маркеров"])}
        }
      ]
    },
    "packages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонементы\nBasic Training Zone"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть все"])}
    }
  },
  "packages": {
    "startPackages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовые пакеты"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовый пакет <span class='underline'>обязателен</span> для создания вашего профиля в интеллектуальной системе Milon и формирования индивидуальных параметров для автонастройки тренажеров на основе результатов фитнес-тестов."])},
      "note_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* если с момента истечения последнего абонемента прошло более 30 дней и период не был заморожен, потребуется повторно приобрести стартовый пакет для обновления профиля."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "mainPackages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонементы"])},
      "subtitle_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобретая абонемент, вы получает полный доступ к тренажерам, расположеным в Basic Training Zone:\nСиловой и кардио круги Milon, беговые дорожки и вертикальные тренажерым Clmbr."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После любого Стартового пакета, вы можете приобрести абонемент*.\nВы получите полный доступ к тренажерам, расположеным в Basic Training Zone:\nСиловой и кардио круги Milon, беговые дорожки и вертикальные тренажеры Clmbr."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* если с момента истечения последнего абонемента прошло более 30 дней и период не был заморожен, потребуется повторно приобрести стартовый пакет для обновления профиля."])}
    },
    "additionalServices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем вам возможность приобретения дополнительных услуг в рамках вашего абонемента, в том числе, если в ваш абонемент такие услуги не входили."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* все дополнительные услуги доступны только при наличии действующего абонемента."])}
    },
    "card": {
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])}
    }
  }
}