<template>
  <div class="form-container">
    <div class="form-left-col-container">
      <div class="form-left-col">
        <div class="form-first-line-left-col">
          {{ $t('homeNew.feedback.title.firstLine') }}
        </div>
        <div class="form-second-line-left-col">
          {{ $t('homeNew.feedback.title.secondLine') }}
        </div>
        <div class="form-third-line-left-col">
          {{ $t('homeNew.feedback.title.thirdLine') }}
        </div>
      </div>
    </div>
    <div class="form-right-col-container">
      <div class="form-right-col">
        <div class="form-right-col-input">
          <input @input="reset" v-model="name" maxlength="50" :placeholder=' $t("homeNew.feedback.form.name") '/>
        </div>
        <div class="form-right-col-input">
          <input @input="reset" v-model="email" maxlength="50" type="email" :placeholder=' $t("homeNew.feedback.form.email") '/>
        </div>
        <div class="form-right-col-input">
          <textarea @input="reset" v-model="question" maxlength="1000" rows="5" :placeholder=' $t("homeNew.feedback.form.question") '></textarea>
        </div>
        <div class="form-error" v-if="error">{{error}}</div>
        <div class="form-success" v-if="success">{{ texts.success }}</div>
        <div class="form-button" @click="sendForm">
          <span>{{ $t('homeNew.feedback.buttonText') }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="form-button-icon">
            <path d="M5 12H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from "@/assets/svg/new/arrow-right.svg";

export default {
  name: "MainForm",

  data() {
    return {
      arrow,
      name: '',
      email: '',
      question: '',
      error: '',
      success: false,
      texts: []
    }
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.texts = {
          errorEmail: "Vigane e-posti aadress",
          errorFillFields: "Kõik väljad on kohustuslikud",
          errorSend: "Ilmnes viga saatmisel, palun saatke oma küsimus aadressile info@35fit.com",
          success: "Saadetud edukalt"
        }
        break;
      case 'et':
        this.texts = {
          errorEmail: "Invalid email address",
          errorFillFields: "All fields are required",
          errorSend: "There was an error when sending, please send your question to info@35fit.com",
          success: "Successfully sent"
        }
        break;
      case 'ru':
        this.texts = {
          errorEmail: "Некорректный email",
          errorFillFields: "Все поля являются обязательными",
          errorSend: "Возникла ошибка при отправке, направьте, пожалуйста, ваш вопрос на адрес info@35fit.com",
          success: "Успешно отправлено"
        }
        break;
      default:
        this.packages = this.packages.plans.en
        this.additionalservices = this.additionalservices.plans.en
        this.startplans = this.startplans.plans.en
        break;
    }
  },
  methods: {
    reset() {
      this.error = ''
      this.success = false
    },
    sendForm() {
      if (!this.name || !this.email || !this.question) {
        this.error = this.texts.errorFillFields
        return;
      }
      var re = /^(?!.*\.\.)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      if (!re.test(this.email)) {
        this.error = "некорректный почты"
        return;
      }
      fetch('https://app.35fit.com/api/form', {
      //fetch('http://localhost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          question: this.question
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            this.success = true
            this.name = ''
            this.email = ''
            this.question = ''
            setTimeout(() => {
              this.success = false
            }, 10000)
            return
          }
          this.error = this.texts.errorSend
        })
        .catch(err => {
          console.log(err)
          this.error = this.texts.errorSend
        })
    },
  }
}
</script>

<style scoped>

.form-success {
  font-size: 20px;
  color: #59B893;
}

.form-error {
  font-size: 20px;
  color: #FF6376;
}

.form-container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 32px 0;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .form-container{
    flex-direction: row;
    padding: 80px 0;
  }
}

.form-left-col-container {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

@media screen and (min-width: 1024px) {
  .form-left-col-container{
    justify-content: flex-start;
    padding-left: 24px;
    margin-bottom: 0;
  }
}

.form-left-col{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .form-left-col{
    align-items: flex-start;
  }
}

.form-first-line-left-col{
  font-size: 18px;
  text-transform: uppercase;
  color: #8A8A8A;
}

@media screen and (min-width: 768px) {
  .form-first-line-left-col{
    font-size: 24px;
  }
}

.form-second-line-left-col{
  font-size: 34px;
  line-height: 1;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .form-second-line-left-col{
    font-size: 52px;
  }
}

.form-third-line-left-col{
  margin-top: 24px;
  font-size: 18px;
  //color: #8A8A8A;
}

@media screen and (min-width: 768px) {
  .form-third-line-left-col{
    font-size: 24px;
  }
}

.form-right-col-container {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-right-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  width: 100%;
  padding: 0 16px
}

@media screen and (min-width: 768px) {
  .form-right-col {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .form-right-col {
    padding: 0 24px 0 0;
    align-items: flex-start;
  }
}

.form-right-col-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-right-col-input input {
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  height: 52px;
  color: black;
  padding: 0 12px;
  font-size: 18px;
  letter-spacing: 1.6px;
  width: 100%;
  outline-style: none;
  max-width: none;
}

.form-right-col-input input::placeholder {
  font-size: 18px;
}

.form-right-col-input input:hover {
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  //border-left-style: none;

}

.form-right-col-input input:focus {
  border-bottom: 1px solid black;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
 }


.form-right-col-input textarea {
  border-radius: 16px;
  border: 1px solid #ccc;
  margin-top: 32px;
  background-color: transparent;
  color: black;
  padding: 12px 18px;
  line-height: 1.5;
  font-size: 18px;
  //letter-spacing: 1.6px;
  //font-family: Arial, Helvetica, sans-serif;
  resize: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s;
}

.form-right-col-input textarea::placeholder {
  font-size: 18px;
}


.form-right-col-input textarea:focus {
   border: 1px solid black;
   outline: none;
}

.form-button {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 6px 20px 6px 28px;
  border: 1px solid black;
  border-radius: 40px;
  margin-top: 32px;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .form-button {
    font-size: 24px;
  }
}

.form-button:hover {
  /*border-color: #000939;*/
  /*background-color: #000939;*/
  border-color: black;
  background-color: black;
  color: white;
  transition: all 0.3s;
}

.form-button-icon {
  transition: fill 0.3s ease;
  color: black;
}

.form-button:hover .form-button-icon {
  color: white;
}

</style>