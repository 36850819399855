<template>
    <main class="dashboard">
        <DashHeader v-if="!desktopMenu" @menuOpen="toggleMenu"
         :desktopMenu="desktopMenu" />
        <div v-if="desktopMenu"
         class="menu-flex-section">
            <!-- Desktop menu -->
            <Menu :key="updMenu"
             @itemClicked='(name) => selectMenu(name)'
             :selected="selectedMenu" />
            <Transition name="left"
             mode="out-in">
                <div v-if="selectedMenu == 'profile'"
                 class="menu-page-section">
                    <UserProfile />
                </div>
                <div v-else-if="selectedMenu == 'classes'"
                 class="menu-page-section">
                    <ClassesPage :fromTests="fromTestsVar"
                     @opened="fromTestsVar = false"
                     :isTrial="isTrial" />
                </div>
                <div v-else-if="selectedMenu == 'tests'"
                 class="menu-page-section">
                    <TestsPage @toSchedule="toSchedule" />
                </div>

                <div v-else-if="selectedMenu == 'contract'"
                 class="menu-page-section">
                    <ContractPage :toPay="toPay"
                     @opened="toPay = false" />
                </div>

                <div v-else-if="selectedMenu == 'invoices'"
                 class="menu-page-section">
                    <InvoicesPage @pay="toPayments" />
                </div>

                <div v-else-if="selectedMenu == 'gifts'"
                 class="menu-page-section">
                    <GiftsStore />
                </div>
            </Transition>
        </div>
        <div v-else>
            <!-- Mobile menu -->
            <Transition name="left"
             mode="out-in">
                <Menu v-if="menu"
                 @itemClicked='(name) => selectMenu(name)'
                 :selected="selectedMenu"
                 :hasDebt="hasDebt" />

                <div class="menu-page-section"
                 v-else>
                    <UserProfile v-if="selectedMenu == 'profile'" />
                    <ClassesPage v-if="selectedMenu == 'classes'"
                     :fromTests="fromTestsVar"
                     :isTrial="isTrial"
                     @opened="fromTestsVar = false" />
                    <TestsPage v-if="selectedMenu == 'tests'"
                     @toSchedule="toSchedule"
                     @opened="fromTestsVar = false" />
                    <ContractPage v-if="selectedMenu == 'contract'"
                     :toPay="toPay"
                     @opened="toPay = false" />
                    <InvoicesPage v-if="selectedMenu == 'invoices'"
                     @pay="toPayments" />
                    <GiftsStore v-if="selectedMenu == 'gifts'" />
                </div>
            </Transition>
        </div>
    </main>
</template>

<script>
import Menu from '@/components/dashboard/MenuList.vue'
import DashHeader from '@/components/dashboard/DashHeader.vue'
import UserProfile from '@/components/dashboard/UserProfile.vue'
import ClassesPage from '@/components/dashboard/ClassesPage.vue'
import TestsPage from '@/components/dashboard/TestsPage.vue'
import InvoicesPage from '@/components/dashboard/InvoicesPage.vue'
import ContractPage from '@/components/dashboard/ContractPage.vue'
import GiftsStore from '@/components/dashboard/store/GiftsStore.vue'
import router from '@/router'
import { useMeta } from 'vue-meta'

export default {
    name: 'DashboardView',
    components: {
        Menu,
        DashHeader,
        UserProfile,
        ClassesPage,
        TestsPage,
        InvoicesPage,
        ContractPage,
        GiftsStore
    },
    beforeMount() {
        useMeta({
            title: this.$t('pagenames.dashboard'),
            link: [
                { rel: 'canonical', href: 'https://35fit.com/dashboard' },
            ],
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: [
                { property: "og:title", content: "35FIT | " + this.$t('pagenames.dashboard') },
                { property: "og:description", content: this.$t('meta.home') },
                { property: "og:url", content: "https://35fit.com/dashboard" },
            ],
            description: this.$t('meta.home'),
        })
    },
    data() {
        return {
            show: false,
            menu: true,
            selectedMenu: "profile",
            desktopMenu: false,
            fromTestsVar: false,
            isTrial: false,
            hasDebt: false,
            toPay: false,
            updMenu: 0,
        }
    },
    methods: {
        selectMenu(name) {
            this.selectedMenu = name
            if (!this.desktopMenu) {
                this.menu = !this.menu
            }
        },
        toggleMenu() {
            if (this.menu) {
                router.push({ name: 'home' })
            }
            this.menu = !this.menu
        },
        toggleMenuDesktop() {
            if (window.innerWidth > 900) {
                this.desktopMenu = true
            } else {
                this.desktopMenu = false
            }
        },
        toSchedule() {
            // this.fromTestsVar = true
            this.selectedMenu = "classes"
            this.menu = false
            this.updMenu++
            router.push({ hash: '' })
        },
        toPayments() {
            this.selectedMenu = "contract"
            this.toPay = true
            this.menu = false
            this.updMenu++
            router.push({ hash: '' })
        },
        openMenuPage(page) {
            this.selectedMenu = page
            this.menu = false
            this.updMenu++
            router.push({ hash: '' })
        },
        async is_trial() {
            await fetch('https://app.35fit.com/api/users/is_trial', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },

            })
                .then(res => res.json())
                .then(data => {
                    if (data.is_trial) {
                        this.hasDebt = (data.debt > 0) ? true : false
                        this.isTrial = true
                        localStorage.setItem('eesti', data.eesti)
                        if (this.hasDebt) {
                            this.openMenuPage('invoices')
                        } else {
                            this.toSchedule()
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    mounted() {
        this.toggleMenuDesktop()
        // check if user is trial
        this.is_trial()
        // event listener when window resized
        window.addEventListener('resize', () => {
            this.toggleMenuDesktop()
        })

        switch (this.$route.hash) {
            case '#schedule':
                this.toSchedule()
                break;
            case '#contract':
                this.openMenuPage('contract')
                break;
            case '#services':
                this.openMenuPage('classes')
                break;
            case '#invoices':
                this.openMenuPage('invoices')
                break;
            case '#gifts':
                this.openMenuPage('gifts')
                break;
            default:
                this.selectedMenu = 'profile'
                break;
        }

    },
    unmounted() {
        window.removeEventListener('resize', this.toggleMenuDesktop())
    }
}
</script>

<style>
.dashboard {
    padding: 8px 16px 0;
    /*padding: var(--padding);*/
    width: 100%;
    margin: 0 auto;
    min-height: 80vh;
    max-width: 1240px;
}

@media screen and (min-width: 600px) {
  .dashboard {
    padding: 8px 24px 0;
  }
}

@media screen and (min-width: 900px) {
  .dashboard {
    padding: 24px 24px 0;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard {
    padding: 48px 60px 0;
  }
}

@media screen and (min-width: 900px) {
    .menu-flex-section {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 25px;
    }

    .menu-page-section {
        width: 100%;
        grid-area: 1 / 2 / 3 / 5;

    }
}
</style>
