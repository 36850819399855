<template>
  <div class="contacts-container">
    <div class="contacts-left-col-container">
      <div class="contacts-left-col">
        <div class="contacts-title-left-col">
          {{ $t('homeNew.contacts.title') }}
        </div>
        <div class="contacts-left-cols">
          <div class="contacts-description-left-col">
            <p>{{ $t('homeNew.contacts.address') }}:</p>
            <p>Gonsiori 33, Tallinn</p>
            <p>+372 5843 5415</p>
            <p>+372 5819 3357</p>
            <p>info@35fit.com</p>
          </div>
          <div class="contacts-description-left-col">
            <p>{{ $t('homeNew.contacts.hours') }}:</p>
            <p>{{ $t('homeNew.contacts.monFri') }}: 06:00 - 22:00</p>
            <p>{{ $t('homeNew.contacts.satSun') }}: 07:00 - 21:00</p>
            <p>{{ $t('homeNew.contacts.holidays') }}:</p>
            <p>07:00 - 21:00</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts-right-col-container">
      <div class="contacts-bg-wrap">
        <div class="contacts-bg">
        </div>
        <img
          :src="map"
          class="contacts-image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import map from '@/assets/img/new/contacts_map_blue.jpg';

export default {
  name: "MainContacts",

  data() {
    return {
      map
    }
  },
}
</script>

<style scoped>

.contacts-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #212225;
}

@media screen and (min-width: 1024px) {
  .contacts-container {
    flex-direction: row;
  }
}


.contacts-left-col-container {
  padding: 80px 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .contacts-left-col-container {
    flex: 1 1 0;
  }
}

@media screen and (min-width: 1280px) {
  .contacts-left-col-container {
    flex: 1 1 0;
  }
}

.contacts-left-col{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contacts-title-left-col{
  font-size: 34px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .contacts-title-left-col {
    font-size: 52px;
    margin-bottom: 40px;
  }
}

.contacts-left-cols {
  display: flex;
  flex-direction: column;
  gap: 68px;
}

@media screen and (min-width: 1024px) {
  .contacts-left-cols {
    gap: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .contacts-left-cols {
    gap: 68px;
  }
}

@media screen and (min-width: 768px) {
  .contacts-left-cols {
    flex-direction: row;
  }
}

.contacts-description-left-col p{
  line-height: 1.5;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .contacts-description-left-col p{
    font-size: 22px;
  }
}

@media screen and (min-width: 1280px) {
  .contacts-description-left-col p{
    font-size: 24px;
  }
}

.contacts-description-left-col p:first-child {
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .contacts-description-left-col p:first-child {
    margin-bottom: 24px;
  }
}


.contacts-right-col-container {
  flex: 1 1 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #436787;
  z-index: 11;
}

.contacts-bg-wrap {
  position: absolute;
  height: 100%;
  width: 50vw;
  overflow: hidden;
  z-index: 10;
}

.contacts-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .contacts-image {
    object-position: left;
  }
}

@media screen and (min-width: 1280px) {
  .contacts-image {
    object-position: center;
  }
}

.contacts-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  //background-color: #1473c699;
  z-index: 11;
}

</style>