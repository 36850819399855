<template>
    <div class="spinner-wrap">
        <span class="spinner"></span>
        <h4 :class="handleColor()">{{ $t('loading') }}</h4>
    </div>

</template>
<script>
export default {
    name: 'LoaderView',
    props: {
        isWhite: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            windowHeight: window.visualViewport.height + 'px',
        }
    },
    methods: {
        handleColor() {
            if (this.isWhite) {
                return 'white'
            }
        }
    }
}
</script>
<style scoped>
.spinner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    height: calc(v-bind(windowHeight) - 100px);
}

.white {
    color: white;
}

.spinner {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, var(--dark-blue) 94%, #0000) top/5.8px 5.8px no-repeat,
        conic-gradient(#0000 30%, var(--dark-blue));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5.8px), #000 0);
    animation: spinner-c7wet2 1.2s infinite linear;
}

@keyframes spinner-c7wet2 {
    100% {
        transform: rotate(1turn);
    }
}
</style>
