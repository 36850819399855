<template>
  <div class="contract-packages-container">
    <div class="contract-packages-line">
      <PackagesCard
        :id="additionalservices.plans[0].id ?? ''"
        :name="additionalservices.plans[0].name ?? ''"
        :description="additionalservices.plans[0].description ?? ''"
        :price="additionalservices.plans[0].price ?? ''"
        :period="additionalservices.plans[0].period ?? ''"
        :color="additionalservices.plans[0].color ?? ''"
      />
      <PackagesCard
        :id="additionalservices.plans[1].id ?? ''"
        :name="additionalservices.plans[1].name ?? ''"
        :description="additionalservices.plans[1].description ?? ''"
        :price="additionalservices.plans[1].price ?? ''"
        :period="additionalservices.plans[1].period ?? ''"
        :color="additionalservices.plans[1].color ?? ''"
      />
    </div>
    <div class="contract-packages-line contract-packages-line-margin">
      <PackagesCard
        :id="additionalservices.plans[2].id ?? ''"
        :name="additionalservices.plans[2].name ?? ''"
        :description="additionalservices.plans[2].description ?? ''"
        :price="additionalservices.plans[2].price ?? ''"
        :period="additionalservices.plans[2].period ?? ''"
        :color="additionalservices.plans[2].color ?? ''"
      />
      <PackagesCard
        :id="additionalservices.plans[3].id ?? ''"
        :name="additionalservices.plans[3].name ?? ''"
        :description="additionalservices.plans[3].description ?? ''"
        :price="additionalservices.plans[3].price ?? ''"
        :period="additionalservices.plans[3].period ?? ''"
        :color="additionalservices.plans[3].color ?? ''"
      />
    </div>
    <div class="contract-packages-line contract-packages-between-margin">
      <PackagesCard
        :id="additionalservices.plans[4].id ?? ''"
        :name="additionalservices.plans[4].name ?? ''"
        :description="additionalservices.plans[4].description ?? ''"
        :price="additionalservices.plans[4].price ?? ''"
        :period="additionalservices.plans[4].period ?? ''"
        :color="additionalservices.plans[4].color ?? ''"
      />
    </div>
  </div>
</template>

<script>
import additionalservices from '@/resources/additionalservices.json'
import PackagesCard from "@/components/dashboard/PackagesCard";
export default {
  name: "ClassesServices",
  components: {
    PackagesCard,
  },
  data() {
    return {
      additionalservices
    }
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.additionalservices = this.additionalservices.plans.en
        break;
      case 'et':
        this.additionalservices = this.additionalservices.plans.et
        break;
      case 'ru':
        this.additionalservices = this.additionalservices.plans.ru
        break;
      default:
        this.additionalservices = this.additionalservices.plans.en
        break;
    }
  },
}
</script>

<style scoped>

.contract-packages-container {
  width: 100%;
  margin-bottom: 40px;
}

.contract-packages-title {
  font-size: 22px;
  text-align: center;
//font-weight: bold;
}

@media screen and (min-width: 1140px) {
  .contract-packages-title {
    text-align: left;
  }
}

.contract-packages-subtitle {
  max-width: 900px;
  font-size: 18px;
  color: #8a8a8a;
}

.contract-packages-line {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1140px) {
  .contract-packages-line {
    flex-direction: row;
    align-items: stretch;
  }
}

.contract-packages-line-margin {
  margin-top: 28px;
}

.contract-packages-section-margin {
  margin-top: 40px;
}



.contract-packages-between-margin {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .contract-packages-between-margin {
    margin-top: 24px;
  }
}

</style>