<template>
    <div>
        <img @click="$emit('menuOpen')"
         class="back"
         :src="back"
         alt="back" />
<!--        <h3 v-if="desktopMenu">{{ $t('pagenames.dashboard') }}</h3>-->
<!--        <img v-if="!desktopMenu"-->
<!--         :src="logo"-->
<!--         alt="logo">-->
<!--        <div class="lang-open"-->
<!--         @mouseover="langMenuOpen = true"-->
<!--         @mouseleave="langMenuOpen = false">-->
<!--            <span>{{ this.$i18n.locale }}</span>-->

<!--            <transition name="bounce">-->
<!--                <div class="lang-wrap"-->
<!--                 v-if="langMenuOpen">-->

<!--                    <LanguageSelect />-->
<!--                </div>-->
<!--            </transition>-->
<!--        </div>-->
    </div>
</template>

<script>
import back from '@/assets/svg/arrow-black.svg';
// import logo from '@/assets/svg/logo.svg';
// import lang from '@/assets/svg/lang-dash.svg';
// import LanguageSelect from '../selectors/LanguageSelect.vue';

export default {
    name: "DashHeader",
    props: {
        desktopMenu: Boolean
    },
    data() {
        return {
            back,
            // logo,
            // lang,
            // langMenuOpen: false
        };
    },
    // components: { LanguageSelect }
}
</script>

<style scoped>
.menu-button {
    width: 38px;
    height: 38px;
    visibility: hidden;
}

.max-lang-wrapper {
    position: fixed;
    top: 80px;
    right: 0;
    width: clamp(30%, 100%, 450px);
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
}

a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
}

.lang-open {
    position: relative;
}

.lang-open span {
    font-weight: 600;
    font-size: 16px;
    cursor: default;
    user-select: none;
    text-transform: uppercase;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-wrap {
    position: absolute;
    right: 0px;
    z-index: 100;
    padding-top: 10px;
}


.back {
    rotate: 90deg;
    cursor: pointer;
}
</style>
