export default {
  "pagenames": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaleht"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKK"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minu konto"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])}
  },
  "warnings": {
    "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alates 1. septembrist kõik kliendid saavad iga kuu esimesel päeval järgmise kuu arved, mis tuleb tasuda iga kuu 7. kuupäevaks"])}
  },
  "weekdays": {
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])}
  },
  "update112": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pood juba saadaval!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuendamine"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teeni lihtsalt jõusaalis käimise eest!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimene jõusaal, mis maksab Teile korraliku treeningu eest. Uus funktsioon klintidele - kinkekauplus."])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas kasutada?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneerige trenni oma konto kaudu, tule kohale ja iga kord saate 1 punki treeningu eest*"])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Punkte saavad kõik kliendid, kellel on iga kuu 1. kuupäeval kehtiv leping. Punktide saamiseks ei tohi külastuste arv ületada Teie tariifile kehtestatud piirmäära kuus. Kui limiit on ületatud, Te ei saa selle kuu eest punkte."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koguge punkte ja makske nendega kuni 70% kaupade ja teenuste eest"])}
  },
  "update130": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uued baarid on juba saadaval!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUENDAMINE"])},
    "block1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie kaupluse tootevalik on laienenud"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja nüüd saab Harvest Republici energiabatoonid osta punktide eest, üks batoonike maksab 5 punktid."])}
    },
    "block2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas teenida punkte?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeru treeningutele oma kontol ja tule trennida, iga külastuse eest saad punkte."])},
      "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte saavad kõik kliendid, kellel on iga kuu 1. kuupäeval kehtiv leping. Kui Teil pole kehtiva lepingu rohkem kui 2 nädalat - Teie boonuskonto nullistatakse."])}
    },
    "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindlasti proovige ja jagage oma tagasisidet."])}
  },
  "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head uut <br> aastat!"])},
  "newYearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT meeskond soovib Sulle rõõmsaid ja sportlikke Jõule ning energilist Uut Aastat!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista"])},
  "cookiehead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutame küpsiseid"])},
  "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT kasutab küpsiseid korralikuks tööks ja teie parimaks kasutuskogemuseks. Saate vaadata meie <a href='/docs/GDPR.pdf'>privaatsuspoliitikat</a>, et mõista, kuidas me küpsiseid kasutame ja teavet kogume. Veebisaidi kasutamist jätkates nõustute nende failide kasutamisega"])},
  "noticeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head Kliendid!"])},
  "noticeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoses hooldustöödega välise teenuse pakkuja poolel ei pruugi Paysera makse olla saadaval 31. märtsil ajavahemikus 21:30–22:30"])},
  "newInv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil on uus arve"])},
  "meta": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me oleme AI-laadne gym! Spordiklubi, jõusaal, personaaltreening, kindlad tulemused, kiire tagasiside, toitumis-/jõudlustestid."])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revolutsiooniline AI gym. Meil on Milon, Power Plate rühmatreeningud, TRX, toitumis- ja kardiotestid"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alates 49€/kuu, mitte 49€/treening. Isiklik tugi AI-laadse jõusaaliga"])}
  },
  "dashboard": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiil"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenused ja broneeringud"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arved"])},
    "gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kingitused"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi välja"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata"])},
    "pages": {
      "profile": {
        "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uksekood"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda"])}
      },
      "gifts": {
        "earnTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teeni punkte"])},
        "earnSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUHEND"])},
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punkt"])},
        "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punktid"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])},
        "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eurot"])},
        "backToRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tagasi"])},
        "howEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas saada rohkem punkte?"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmed"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohkem"])},
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praegune kuu"])},
        "toEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treeningud, et teenida"])},
        "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pood"])},
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOHUSTUSLIK"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALIKULINE"])},
        "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisapunktid"])},
        "additionalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenite 5 punkti iga ostetud Dynosticsi testi, rühma-/isikliku treeningu eest. Ostetud Miloni test annab 2,5 punkti"])},
        "discountText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Makstes ", _interpolate(_named("price")), " punktiga saate 70% allahindlust tavahinnast. Soodustus on juba hinna sees."])},
        "discountTextFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sellel kaubal on tavahinnast 100% allahindlus. Soodustus on juba lisatud"])},
        "consistency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Püsivus"])},
        "modalText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Preemia saamiseks läbige ", _interpolate(_named("trainings")), " treeningut (mitte enam). Treening loetakse tehtud siis, kui olete seda broonerinud ja kohale tulnud. Punkte antakse igal kuu esimesel päeval."])},
        "noPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil pole treeningupaketti. Ostke pakett, et saada rohkem punkte"])},
        "currTraining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Praegu teil on ", _interpolate(_named("currentTraining")), " treeningut ", _interpolate(_named("trainings")), "-st"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuste ajalugu"])},
        "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil pole tellimusi"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus"])},
        "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kuupäev"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatus"])}
      },
      "contract": {
        "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanad lepingud"])},
        "prolong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikenda"])},
        "discounts": {
          "workerDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil on töötaja allahindlust. Paketti hind on 17€/kuus"])},
          "testDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil on Dynostics’i testile allahindlus (Performance). Testi hind on 25€"])}
        },
        "paymentModal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige maksmise metood"])},
          "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage kood"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksmine"])},
          "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun, sisestage oma kood"])},
          "stebbyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suurim heaoluteenuste keskkond Baltikumis"])},
          "payseraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige oma pank kinnitada kaupade või teenuste eest tasumine"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esitage kood"])}
        },
        "currentContract": {
          "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peatatud"])},
          "currentContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teie leping"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestus"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuumakse"])},
          "concluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
          "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpp"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus"])},
          "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuud"])},
          "noCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ei saa oma lepingut muuta ega tühistada"])},
          "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista"])},
          "newContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus leping"])},
          "cancelModal": {
            "cancContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista leping"])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoiatus"])},
            "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside"])},
            "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige põhjus"])},
            "reasons": {
              "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35FIT on kodust/töökohast liiga kaugel"])},
              "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaanid ja teenused on liiga kallid"])},
              "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isiklikud põhjused, mis pole klubiga seotud"])},
              "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training type is not suitable for me"])},
              "fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningtüüp ei sobi mulle"])},
              "sixth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma ei näe tulemust"])},
              "seventh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem pole loendis/muu"])}
            },
            "warnUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peate klubi hoiatama vähemalt 2 kuud enne päeva, mille soovite tühistada."])}
          }
        },
        "paymentWidget": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksed"])},
          "inDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teie arve tasumiseks"])},
          "allOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik on korras!"])},
          "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse tähtaeg"])},
          "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksma"])},
          "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järgmine arve"])},
          "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksa"])},
          "prepay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettemaks"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus"])},
          "modal": {
            "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus periood alates "])},
            "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitan, et olen nõus puhkuse kehtivusaja ja tingimustega ning nõustun nendega."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepingu puhkus"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoiatus"])},
            "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu"])},
            "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuud"])},
            "short1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehke jõusaalist paus"])},
            "short2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtke kogu puhkuseaeg järjest"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimaalne puhkuseperiood on plaaniperioodil 2 kuud järjest. Puhkus algab lähimast maksetsüklist."])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkuse ajal maksate allahindlust <b>8 eurot kuus</b>. Sel perioodil ei saa te klubisse siseneda."])},
            "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige periood"])},
            "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taotlege puhkust"])}
          },
          "noPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulevikus makseid pole"])}
        },
        "buyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta uus pakett"])},
        "changeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda paketti"])}
      },
      "noContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne leping puudub"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta uus leping"])},
        "toPricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta nüüd"])}
      },
      "invoices": {
        "noInvoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik on korras!"])},
        "noInvoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praegu pole teil tasumata arveid"])},
        "invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil on tasumata arveid"])},
        "invoiceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näib, et teil on tasumata arve"])},
        "prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arve ettevalmistamine..."])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arve #"])},
        "payBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksa enne: "])},
        "noInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil pole veel arveid"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtke ühendust"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksa nüüd"])}
      },
      "booking": {
        "criticalError": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmnes viga"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midagi läks valesti. Proovige leht uuesti laadida"])}
        },
        "select": {
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeritud"])},
          "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühistatud"])},
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpetatud"])}
        },
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasulised teenused"])},
        "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneering"])},
        "noClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole broneeringuid"])},
        "noCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole tühistatud"])},
        "noFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole õpetatud"])},
        "modal": {
          "availableForActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeringud ja lisateenused on saadaval ainult aktiivse paketid olemasolul"])},
          "chooseTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali paketid"])},
          "availableForWithoutDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved ja lisateenused on saadaval ainult tasumata arvete puudumisel"])},
          "notime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aega sellel päeval pole saadaval, palun valige mõni teine päev."])},
          "lasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestab"])},
          "newBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus broneering"])},
          "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservi ei saa luua"])},
          "firstTrialText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali esimese proovitreeningu kuupäev"])},
          "selectService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali teenuse tüüp"])},
          "selectAdditionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali teenus"])},
          "selectFreezePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali külmutamise alguskuupäev ja külmutamisperiood"])},
          "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päev ja aeg"])},
          "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kuupäev"])},
          "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali aeg"])},
          "orderService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telli teenus"])},
          "createBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeri"])},
          "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treening"])},
          "milonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulaarne Miloni ringtreening AI varustusega"])},
          "included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lepingusse lisatud"])},
          "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutid"])},
          "noWeekends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nädalavahetustel pole saadaval"])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole saadaval"])},
          "milonOther": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soovitatav on rätik kaasa võtta"])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soovitatav on võtta pudel vett"])}
          },
          "tests": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testid"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon testid"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alates €15"])}
          },
          "personal": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaaltreening"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaltreening treeneriga"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€30/treening"])}
          },
          "extradays": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täiendavad koolituspäevad"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Training Zone treeningud"])},
            "oneDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 treening"])},
            "threeDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 treeningut"])}
          },
          "sgt": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupitreening"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupitreening 3 inimesele treeneriga"])},
            "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€15/treening"])}
          },
          "additional": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisateenus"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamiinijook, lepingu peatamine"])}
          },
          "additionalServices": {
            "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamiinivesi (15 vitamiinijooki) €7.95"])},
            "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus"])},
            "oneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kuu €8.00"])},
            "twoMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 kuud €16.00"])}
          }
        }
      }
    }
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadimine"])},
  "header": {
    "menu": {
      "home": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaleht"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi avalehele"])}
      },
      "training": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 minutit 12 korda kuus"])}
      },
      "faq": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKK"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korduma kippuvad küsimused"])}
      },
      "pricing": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige teile sobiv pakett"])}
      },
      "packages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketid ja hinnad"])}
      }
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keel"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta kohe"])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktid"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juriidiline"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKK"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abi"])},
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotsiaalmeedia"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klubi kodukord"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatsuspoliitika"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meist"])},
    "pricelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnakiri"])},
    "buyStebby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksmine Stebby kaudu"])}
  },
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata rohkem"])},
  "home": {
    "trialTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='blue-highlight'>TASUTA</span><br>PROOVITREENING"])},
    "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teie jaoks <br> loodud <br> AI gym"])},
    "firstSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lihtne. Ohutu. Tõhus."])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness butiik stuudio"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuaalne lähenemine"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuaalne tugi ja abi tänu automatiseeritud süsteemile ja AI-varustusele"])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-varustus"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-laadsed masinad väga kiiresti kohanduvad  teie suuruse, vajaduste ja eesmärgi järgi"])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aja kokkuhoid"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogu keha ringtreening võtab suurepäraste tulemuste saavutamiseks vaid 35 minutit."])}
      }
    },
    "secondSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobib igale eesmärgile"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igale tasemele."])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihaste kasvatamine"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningkoormus kõrgendatud raskusega"])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuvaba treening"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Režiim on suunatud füüsilise ebamugavuse põhjustele."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaalu langetamine"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastupidavuse ja mõõduka jõutreeningu kombinatsioon."])}
      },
      "card4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toonimine"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihipärane jõutreening saleda kehakuju jaoks ja lihaste tugevdamiseks."])}
      }
    },
    "thirdSection": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening naeratades"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
      "card1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningvarustus"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik treeningmasinad, mida kogu keha treeninguks vajate, on siin."])}
      },
      "card2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei mingit ooteaega"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei pea ootama oma korda treeningmasinale. Järjekorravaba süsteem."])}
      },
      "card3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ära mõtle – lihtsalt tee seda. Treening valmis programmi järgi."])}
      }
    },
    "mediaContent": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaalsed eelised"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimaalne efektiivsus"])},
      "powerPlate": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOGU KEHA VIBRATSIOON"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaalsed mikrovibratsioonid aktiveerivad rohkem lihaseid, põletavad rohkem kaloreid, parandavad vereringet ja aitavad lihaseid kiiremini taastuda"])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOGU KEHA VIBRATSIOON"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsiooniga venitamine"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsiooniplatvorme saab kasutada pärast treeningut venitamiseks, et leevendada lihaste valu ja aidata kiiremini taastuda."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsiooniga treening"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsiooniplatvormiga treenimisel on sellised eelised nagu luutiheduse suurendamine, kuni kaks korda rohkem rasva põletamine."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri 35FIT'is"])}
      },
      "trugge": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamiinivesi"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saad treeningu ajal 37% oluliste vitamiinide päevasest annusest vaid ühe suhkruvaba joogiga."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamiinivesi"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lifefuel"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maitsed"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üle 30 maitse, mis muutuvad iga kuu. Iga kuu valikus on 5 maitset."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koostis"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitamiinid B5, B6, B12, E, niatsiin, Saksamaa toodangu foolhape. Ainult 3 kcal ühe joogi kohta."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri 35FIT'is"])}
      },
      "tests": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edenemise jälgimise testid"])},
        "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi oma paindlukust ja reaktsiooni kiirust. Kõik ühes Milon testis, mis võtab vaid 5 minutit. Nii testi kui ka dünaamika tulemused on ekraanil koheselt saadaval."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testid"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dynostics"])},
        "mediaInner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi oma ainevahetust"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["much to eat according to your goal. - Analüüsimiseks kulub 5 minutit. Test annab ülevaate sinu praegusest ainevahetuse olukorrast ja sellest, kui palju süüa vastavalt sinu eesmärgile."])}
        },
        "mediaInner2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi oma jõudlust"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie stuudios ühendatakse sinu süda kardioseadmetega. Tee test rasvapõletuse või parema vastupidavuse jaoks ideaalse pulssisageduse teadmiseks."])}
        },
        "mediaInner3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi oma liikuvust"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our studio your heart will be connected with cardio equipment. Take a test for ideal bpm for fat burn or better stamina."])}
        },
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register at 35FIT"])}
      }
    }
  },
  "training": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revolutsiooniline AI fitness paremate tulemuste saavutamiseks"])}
    },
    "segmented": {
      "circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
      "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testid"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rühmatreening"])}
    },
    "circle": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtab 35 minutit"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningasinad on paigutatud ringis, täiuslik segu jõust ja kardiost."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik on kontrolli all"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress ja isegi treeningu liikumise juhtimine on alati nähtaval."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-tüüpi seadmed"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masinad jätavad sind meelde ja kohanduvad sinu jaoks sobivaks."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobib igale eesmärgile."])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igale tasemele. Igale sihile"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstsentriline"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivset tüüpi treeningutel kasutatakse lihaseid ideaalselt."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaptiivne"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raskus sõltub kiirusest. Ideaalne teraapiaks ja võistlusspordiks."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isokineetiline"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguleerib raskust automaatselt reaalajas. Hea hüpertroofiale suunatud treeninguks."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiire tagasiside"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening ja progress."])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningu statistika"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühine süsteem ühendab teie telefoni seadmetega."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutikas treeningu kontroll"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raskused tõstetakse vastavalt teie kohanemisvõimele treeningutega."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jälgi oma dünaamikat"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahetu juurdepääs andmetele, mis on ühe pilguga nähtavad."])}
        }
      },
      "section4": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seadmed stuudios"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parim lähenemine"])},
        "media": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milon"])},
          "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitreeningute pioneer ja uuendaja. Rohkem kui 1000 klubi üle maailma, 35FIT on ainuke Milon stuudio Eestis."])},
          "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeni naeratades"])},
          "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtne ja tõhus"])},
          "inner": {
            "first": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahendus paljudele muredele"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tea, mida tavalises spordiklubis teha? Kas sa ei saa soovitud tulemusi saavutada? 35FIT stuudio on lahendus kõikidele sinu muredele."])}
            },
            "second": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktiivne ekraan"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekraan annab treeningu ajal kohest tagasisidet, mis aitab treeningut õigesti teha."])}
            },
            "third": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui 35 minutist piisab"])},
              "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tänu intensiivsele koormusele ja õigetele harjutusraskuste seadistustele on 35 minutit 2-3 korda nädalas ideaalne selleks, et olla vormis ja terve."])}
            }
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtne treenimine"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtne ja efektiivne"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningmasinad on paigutatud ringikujuliselt ja on mõeldud kogu keha treenimiseks, järjekorravaba süsteem. Ära raiska aega, vaid treeni!"])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroll"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jälgi oma dünaamikat rakenduse abil, kontrolli oma treeningu liikumist interaktiivse ekraani kaudu. Alates SUPER FLEX paketist kontrollitakse ja kohandatakse ka sinu südamerütmi."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" AI-tüüpi seadmed"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasuta sisselogimiseks ja treeningu alustamiseks isiklikku käepaela. See tagab sobiva raskuse ja harjutuse liikumisulatuse. Süsteem soovitab koormusi ja südamerütmiga seotud progresseerumist."])}
        }
      }
    },
    "tests": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulemus on õige treeningu ja toitumise summa"])},
        "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paremate tulemuste saavutamiseks soovitame alati testida ja jälgida oma toitumist, jõudlust ja dünaamikat."])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Individuaalse toitumiskavaga treeningud on sinu edu võti."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paremate tulemuste saavutamiseks ühenda oma süda seadmetega."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness ja painduvuse test"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiire ja lõbus viis oma treeningdünaamikat fikseerida."])}
          }
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiire tagasiside"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening ja progress"])},
        "cardsContent": {
          "card1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuaalne kontroll"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treener annab ülevaate sinu tulemustest ning aitab paika panna ja järgida strateegiat."])}
          },
          "card2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiire ja lõbus"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainevahetustest võtab aega 5 min, jõudlustest - 15 min, fitnesstest - 5 min."])}
          },
          "card3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati käepärast"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurdepääs tulemustele ja jälgimine igal ajal ja igal pool"])}
          }
        }
      },
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeni naeratades"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtne ja tõhus"])},
        "first": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Nutrition"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70% sinu eesmärgist sõltub tasakaalustatud toitumisstrateegiast. Test koos treeneri konsultatsiooniga annab sulle täieliku ülevaate oma toitumisest."])}
        },
        "second": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynostics Performance"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pärast testi sisestame sinu individuaalsed südameandmed süsteemi, et see kohaneks automaatselt rasvapõletusega.  Saad teada oma treenitustase, VO2 max ja regeneratsiooni võime kohta."])}
        },
        "third": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness ja painduvuse test"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei midagi muud kui lõbus. Kiire test sinu painduvuse, reaktsioonikiiruse, hüppejõu ja tasakaalu kontrollimiseks ja fikseerimiseks. Pärast mitut testimist jälgib treener sinu dünaamikat."])}
        }
      }
    },
    "groupTraining": {
      "section1": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljakutsuv. Lõbus."])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väikestes gruppides treeningud"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juhendatud treening"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-8-nädalane programm koos treeneritega 4-liikmelises grupis (max)"])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seadmed"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningu ajal kasutatakse TRX-i, BootyBuilderit ja Power Plate'i."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aja kokkuhoid"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väga intensiivne eesmärgile orienteeritud treening lühema ajaga."])}
        }
      },
      "section2": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täida oma eesmärk"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningprogrammid"])},
        "card1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuhar"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ole täielikult keskendunud oma tuharalihaste ja reielihaste aktiveerimisele ja kujundamisele."])}
        },
        "card2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugevus"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paranda lihaste toonust. Parim valik oma võimsuse suurendamiseks."])}
        },
        "card3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening on suunatud kehale ja vaimule. Sisaldab joogat ja pilatest."])}
        }
      },
      "section3": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikaalsed eelised"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsioonitreening"])},
        "card1": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Põleta rasva kiiremini"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikrovibratsioonid sunnivad sind pingutama rohkem, põletades rohkem rasva."])}
        },
        "card2": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arenda painduvust"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogu keha vibratsioonil on oluline mõju painduvusele"])}
        },
        "card3": {
          "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranda luu tugevust"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibratsioon paneb  luud rohkem pingutama, mis muudab need tugevamaks"])}
        }
      },
      "media": {
        "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinevad lähenemisviisid"])},
        "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningu varustus"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Plate"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vibreeriv platvorm käivitab töötavate ja stabiliseerivate lihaste kohesed kokkutõmbed, parandades tasakaalu ja jõudu."])},
        "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaduspõhine lähenemine"])},
        "modalSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saavuta tulemusi kiiremini"])},
        "inner": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimaalne efektiivsus vähema ajaga"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["55-minutiline treening mahub vibratsiooniplatvormiga treenides 25 minuti sisse, treeningu ajal aktiveeritakse rohkem lihaseid."])},
          "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veel eeliseid"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuringud on näidanud, et vibratsiooniga treenimine võib ka luid tugevdada ja vereringet parandada."])}
        },
        "mediaContent": {
          "first": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booty builder"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningmasin annab kasutajatele optimaalse liikumisulatuse tuharalihaste aktiveerimiseks."])}
          },
          "second": {
            "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRX kasutab keharaskusega harjutusi jõu, tasakaalu, painduvuse ja keha stabiilsuse arendamiseks."])}
          }
        },
        "modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väikestes gruppides treeningud"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningprogrammid"])},
          "first": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuhar"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lubame, et sinu tuharalihased põlevad selle  treeninguga, mis sisaldab parimat varustust ja harjutusi lihaste aktiveerimiseks."])}
          },
          "second": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugevus"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihaskasv saavutatakse pikendatud jõuharjutustega vibratsiooniplatvormil. Vibratsioon aktiveerib rohkem lihaseid, põletab rohkem rasva."])}
          },
          "third": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zen"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideaalne programm lõõgastumiseks ja oma keha ja keha-vaimu sidemele tähelepanu pööramiseks, sisaldab massaaži vibratsiooniplatvormil."])}
          }
        }
      }
    }
  },
  "pricing": {
    "intro": {
      "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
      "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali pakett, mis vastab kõikidele Sinu vajadustele ning soovidele"])}
    },
    "other": {
      "modal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepingu tingimused"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])}
      },
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohkem"])},
      "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähem"])},
      "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telli"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
      "planChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaani muutmisel tasutud perioodi ja teenuste eest raha ei tagasta"])},
      "acknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätkates kinnitan, et olen läbi lugenud, aru saanud ja olen nõus"])},
      "payWithStebby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksmine Stebby kaudu"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostma"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broneeri"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telli"])},
      "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaks"])},
      "groups": {
        "circleTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testid"])},
        "groupTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rühmatreening"])}
      },
      "segments": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketid"])},
        "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testid"])},
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rühmatreening"])}
      }
    }
  },
  "login": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parooli lähtestamise link on saadetud teie e-posti aadressile, kui see on meie andmebaasis olemas"])},
    "resetPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage e-posti aadress"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage parool"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtesta"])},
    "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun sisesta kehtiv e-posti aadress"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unustasid parooli?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse kontole"])},
    "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtestage parool"])},
    "serverError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveri viga"])},
    "unableLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei saa sisse logida"])},
    "emptyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisselogimisandmed ei tohi olla tühjad"])},
    "emailPasswordIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post või parool on vale"])}
  },
  "registration": {
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tere! 👋🏻"])},
    "introText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saame tuttavaks?"])},
    "changableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreerimine"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perekonnanimi"])},
    "idcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kood"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugu"])},
    "prefLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eelistatud keeled"])},
    "selectLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali keeled"])},
    "footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige kõik keeled, mida oskate, et saaksite valida teile esimeseks proovitreeningu treeneri"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mees"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naine"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sünnikuupäev"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arve aadress"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktid"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita parool"])},
    "dataAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nõustun oma <a target='_blank' class='blue-text' href='/docs/GDPR.pdf'>isikuandmete töötlemisega</a>"])},
    "rulesAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen lugenud ja nõustun <a target='_blank' class='blue-text' href='/docs/Club_Rules.pdf'>klubi reeglitega</a>"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edu"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatsime teile meili. Registreerimise lõpetamiseks kontrollige seda"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätka"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
    "errorCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga konto loomisel"])},
    "emptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühjad read"])},
    "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun täitke kõik väljad"])},
    "stageText": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätka, "])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa oled peaaegu kohal!"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaitske oma kontot"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpp!"])}
    },
    "passErrors": {
      "doNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroolid ei kattu"])},
      "doNotMatchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun kontrolli oma parooli"])},
      "tooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool on liiga lühike"])},
      "tooShortText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutage vähemalt 8 sümbolit, sealhulgas numbreid ja tähti"])},
      "legalConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seaduslik nõusolek"])},
      "legalConsentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun kinnitage oma andmetöötlus ja reeglid"])},
      "invalidMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtetu e-posti aadress"])},
      "invalidMailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun kontrolli oma meili"])}
    },
    "trialNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proovitreeningut pole vaja"])},
    "iHaveNoIdCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mul pole id-koodi"])}
  },
  "advert": {
    "blackfriday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Must</span><span>reede</span>"])},
    "bfpoints": {
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta rühmatreening"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta proteiinijook proovitreeningul"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta Milon test"])},
      "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa info treenerilt"])}
    },
    "trialpromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta proovitreening! Kuni 28.08"])},
    "trialAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proovitreening on saadaval alates 17.04"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri oma konto 35FIT'is!"])},
    "summerPromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='intro-gradient' ref='summer'>SUVINE</span><br>KAMPAANIA"])},
    "summerf1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainult €35/kuu"])},
    "summerf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igapäevane pakett"])},
    "summerf3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni 31.07"])},
    "summerBottomPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUMMER pakket - €35/kuu"])},
    "buyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta nüüd"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasuta Dynostics testi saamiseks võtke sõber kaasa"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaga"])},
    "regCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='green-highlight'>TASUTA</span><br>REGISTREERIMINE"])},
    "regCampaignText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeri proovipäeval"])},
    "regCampaignText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ära maksa lepingutasu"])},
    "regCampaignText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säästa €30"])},
    "bookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeri <br>grupitreening"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimene proovitreening<br>ainult €15"])},
    "descriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proovitreening 40min: milon trenaažöörid"])},
    "descriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 milon fitness testi kingituseks"])},
    "descriptionThird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningu eesmärgi määramine"])},
    "descriptionFourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepingu sõlmimisel kohe pärast proovitrenni - registreerimistasu 0€"])}
  },
  "faq": {
    "pagename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKK"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastused üldistele küsimustele"])},
    "contract": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas ma saan tasuta testi, kui toon sõbra?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinu sõber peab lepingu sõlmimisel nimetama Sind, kirjutades meie e-postile. Võtame Sinuga koheselt ühendust, et broneerida testi aeg."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milliseid pakette Teil on pakkuda?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hetkel on <b>meil 6 erinevat paketti</b>. Igal neist on oma eelised. Parimaks tulemuseks soovitame võtta sellised paketid nagu All under Control või Connect to result, kuna nendel pakettidel on rohkem eeliseid Sinu eesmärgi kiiremaks saavutamiseks. Pakette ja hindu saab võrrelda <a href='/pricing' class='green-text'>hinnakirja</a> lehel"])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millal hakkab abonement kehtima?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui sul on prooviperiood, hakkab see kehtima <b>kodulehel registreerimise hetkest</b>. Tavaline pakett hakkab kehtima hetkest, kui leping allkirjastatakse kodulehel. Leping jõustub, <b>kui nõustud tingimustega ja vajutad hinnakirja lehel nuppu “Osta plaan</b>”"])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas ma saan osta ühekordse pileti?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilt saab osta ühekordseid personaaltreeninguid väikses saalis. Ühe treeningu hind on <b>25 eurot + 8 eurot</b> ühekordse külastuse eest. Kui soovid meie treeninguid proovida, siis saab alati osta prooviperioodi esimese 3 treeningu jaoks."])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis on käepaela tagatisraha?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enne kui hakkad meie klubis treenima, anname sulle isikliku käepaela, millega saad avada välisukse ja aktiveerida treeningmasinaid. Käepael on klubi oma. Tagatisraha võtame selle kaotamise ohu tõttu. Kui Sinu leping lõpeb ja Sa ei planeeri enam meie klubis treeningutega jätkata, tuleb käepael tagastada. Kohe pärast käepaela tagastamist, tagastatakse Sulle tagatisraha Teie arvelduskontole."])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas saab maksta Stebby kaudu?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Jah</b>, meie teenuste eest saab maksta Stebby kaudu. Link on <a class='green-text' href='https://app.stebby.eu/pos/35fit.com'>siin</a>"])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millal ja kuidas tuleb arveid maksta?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga kuu 10 päeva enne tasumispäeva saate oma e-postile arve. Kui te pole kirja saanud, palun kontrollige oma rämpspostkasti. Arved leiate ka oma isiklikult kontolt. Logi sisse - Minu konto - Minu leping - Arved. Tasuda saab pangaülekande, kodulehe või Stebby kaudu. Me ei aktsepteeri sularaha ega kaardimakseid. Arved tuleb tasuda ka siis, kui Te mingil põhjusel klubi ei külasta, kuna leping on koostatud kuutasu põhimõttel."])}
      },
      "eight": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas ma saan lepingu ennetähtaegselt lõpetada?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meil on kahte liiki lepinguid: siduvad - määratud lõppkuupäevaga, või tavalised. Tavalised lepingud on: All under Control, Connect to result, Super Flex, Flex. Siduv määratud tähtajaga leping on SMART ja IDEAL. Tavalise lepingu võib igal ajal ja ilma põhjuseta ennetähtaegselt lõpetada, kuid sellest tuleb klubi 2 kuud ette teavitada. Siduvat lepingut ei saa lõpetada enne lepingu lõppu ja tuleb tasuda kogu lepingu kehtivusaja eest (isegi kui klubi ei külastata). Erandiks mõlema lepinguliigi puhul on sportimist välistav trauma (sel juhul peate meile esitama raviarsti poolt väljastatud kehtiva tõendi). Leping lõpetatakse koheselt."])}
      },
      "ninth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas ma saan võtta treeningpuhkuse?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olenevalt valitud paketist võite võtta kuni kaks kuud puhkust. Puhkust tehakse täiskuuks, puhkust ei saa võtta näiteks 10 päevaks ega 2 nädalaks. Iga puhkuse kuu maksab  8 euro/kuu"])}
      },
      "tenth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis juhtub, kui ma arveid ei maksa?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui arve pole  õigeks ajaks makstud, siis klubi sissepääs piiratakse automaatselt kuni arve tasumiseni. Kui arveid pole pikka aega tasutud, piiratakse ka treeningmasinate kasutamine."])}
      }
    },
    "training": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treening"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis ma pean trenni kaasa võtma?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil läheb vaja: mugavad ja puhtad spordiriided, mugavad vahetusjalatsid, rätik ja pudel"])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas saab klubisse siseneda?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutage oma isiklikku käepaela või pin-koodi. Kui olete pin-koodi unustanud, siis seda saab vaadata oma isiklikul kontol vajutades “Minu konto” ja seejärel ”Profiil”. Kui uks ei avane käeapaela ega pin-koodi abil,veenduge, et kõik arved on  makstud"])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas treener on alati klubis?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treener selgitab kogu treeningprotsessi esimese proovitreeningu jooksul ja näitab kuidas saab oma tulemusi näha meie süsteemi kaudu, Tavaliselt on treener kohal tööpäeviti õhtuti. Kui vajate konsultatsiooni ja treenerit kohal ei ole, siis saab meiega ühendust võtta e-posti või telefoni teel ja me saame planeerida Teie kohtumist treeneriga."])}
      },
      "fourth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui kaua kestab üks treening?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üks treening on koostatud 35 minutiks + aeg riiete vahetamiseks ja duši all käimiseks"])}
      },
      "fifth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas on vaja iga kord aega broneerida?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie väga soovitame, et te broneeriksite aega iga kord. Teie mugavuse ja ohutuse huvides on klubis inimeste arv piiratud. Broneeringud aitavad meil hinnata saalis olevate inimeste arvu."])}
      },
      "sixth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kus ma saan oma tulemusi näha?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meil on olemas <a href='https://www.milonme.com/login'>Milon Me app</a> rakendus. Kasutamiseks sisestage oma andmed, mis anti esimesel treeningul."])}
      },
      "seventh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis juhtub, kui olen  käepaela või pulsivöö kaotanud?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui on käepael kaotatud, siis kahjuks ei saa me teile  tagatisraha tagastada. Kui pulsivöö on kaotatud, siis on ettenähtud trahv - 35 eurot."])}
      }
    },
    "other": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muud"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas teil on ujula/saun?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meil ei ole ujulat ega sauna. On olemas ainult duširuumid."])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas on tasuta parkimiskohti?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kahjuks, meil eraldi parkimisala ei ole. Tööpäeviti saab parkida lähedal asuva Selveri kaupluse juures. Nädalavahetustel saab parkida klubi kõrval tasuta."])}
      },
      "third": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas Teilt saab osta vett?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meil on veeaparaat olemas. Enamikel pakettidel on vesi hinna sees - 15 vitamiinijooki kuus, tavaline vesi ilma piiranguteta. Kui Teie paket vett ei sisalda, saab veemasina kasutamist eraldi osta - 7,95 eurot kuus."])}
      }
    },
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerii"])},
    "about": {
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juriidiline"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aadress"])},
      "openHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahtiolekuajad"])},
      "monFri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-R"])},
      "satSun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-P"])},
      "stateHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riigipühad"])},
      "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontor"])}
    }
  },
  "homeNew": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algtreening"])},
    "offerSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisaldab fitness-testi"])},
    "about": {
      "right": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtsalt. Ohutult. Tõhusalt."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35 FIT pakub personaalse treeningsüsteemi, mis aitab teil saavutada oma fitness-eesmärgid parima võimaliku lihaste kaasatusega"])}
      }
    },
    "classes": {
      "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsti avatakse"])}
    },
    "trial": {
      "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45 minutit seanss 15"])},
      "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algtreening"])},
      "thirdLine": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksklusiivne varustus: Milon ja Power Plate"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisaldab 2 tasuta Milon treeningutesti"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningueesmärkide määramine"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohese lepingu sõlmimisel registreerimistasu on 0"])}
      ],
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta kohe"])}
    },
    "contacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktid"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aadress"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tööaeg"])},
      "monFri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-R"])},
      "satSun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-P"])},
      "holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riigipühad"])}
    },
    "feedback": {
      "title": {
        "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas teil on"])},
        "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["küsimusi?"])},
        "thirdLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtke meiega ühendust"])}
      },
      "form": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinu küsimus"])}
      },
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada"])}
    }
  },
  "basicTrainingZone": {
    "lead": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketid\nja\nhinnad"])}
    },
    "about": {
      "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketid alates"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu"])},
      "secondLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osta Start+ pakett, et paremini\nmõista, milline treening sulle kõige paremini sobib"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketid"])}
    },
    "slider": [
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutit"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sooritades vaid kaks ringi tsüklis, saavutatakse kogu keha optimaalne treening vaid 35 minutiga. Jõu- või kardiomasinad pakuvad tõhusat kogu keha ringtreeningut"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treeningala"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Endurance Training Zone aktiveerivad sinu ainevahetuse ja teevad treeningu ideaalseks. Harjutused, koormused, tempo ja puhkepausid on loodud individuaalseteks treeningprogrammideks"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["treeningtüüpi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaalse eesmärgi saavutamiseks treener periodiseerib treener erinevate koormustüüpidega - Adaptive, Isokinetic, Normal, Eccentric"])}
      },
      {
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningud põhinevad sporditeaduse ja füsioteraapia uusimatel saavutustel. Sobivad nii patsientide taastusraviks kui ka võistlussportlastele"])}
      }
    ],
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Training Zone eelised"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaalsed treeningud innovatiivsete Milon ja Clmbr tehnoloogiatega, et saavutada oma eesmärgid – alates taastumisest kuni intensiivse fitnessini"])},
      "slides": [
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtreening sobib igaks eesmärgiks: lihaste kasvatamiseks, kehakaalu langetamiseks, valuvabaks liikumiseks ja keha vormimiseks"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treeningud meie elektroonilistel seadmetel pakuvad pidevalt vaheldusrikast kogemust koos kontrolli ja nutika juhtimisega"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trenažöör Clmbr – pakub kõrge intensiivsusega kardiotreeningut, mis avaldab minimaalset negatiivset koormust liigestele"])}
        },
        {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milonazer pakub kiireimat ja lihtsamat viisi treeningu alustamiseks. Innovatiivne skaneerimissüsteem mõõdab keha biomeetriliste markerite alusel vaid mõne sekundiga"])}
        }
      ]
    },
    "packages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Training Zone\npaketid"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki"])}
    }
  },
  "packages": {
    "startPackages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stardi paketid"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stardipakett on <span class='underline'>kohustuslik</span> teie profiili loomiseks Miloni nutikas süsteemis ning individuaalsete parameetrite määramiseks, et treeningseadmed saaksid automaatselt kohanduda fitnessitestide tulemuste põhjal."])},
      "note_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* kui teie viimase liikmepaketi kehtivuse lõppemisest on möödunud rohkem kui 30 päeva ja perioodi ei ole külmutatud, peate profiili uuendamiseks ostma uue stardipaketi."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "mainPackages": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liikmepaketid"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pärast mis tahes stardipaketti, saate osta liikmelisuse*. Saate täieliku juurdepääsu Basic Training Zone’i seadmetele, sealhulgas Miloni jõu- ja kardiotreeningu ringidele, jooksuratastele ning Clmbr vertikaalsetele treeningseadmetele."])},
      "subtitle_temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostes liikmepaketi, saate täieliku juurdepääsu Basic Training Zone’i seadmetele, sealhulgas Miloni jõu- ja kardiotreeningu ringidele, jooksuratastele ning Clmbr vertikaalsetele treeningseadmetele."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* kui teie viimase liikmepaketi kehtivuse lõppemisest on möödunud rohkem kui 30 päeva ja perioodi ei ole külmutatud, peate profiili uuendamiseks ostma uue stardipaketi."])}
    },
    "additionalServices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisateenused"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakume teile võimalust osta oma liikmepaketi raames lisateenuseid, isegi kui need ei kuulu teie paketivalikusse."])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* kõik lisateenused on saadaval ainult kehtiva liikmepaketi olemasolul."])}
    },
    "card": {
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostma"])}
    }
  }
}