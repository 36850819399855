<template>
  <transition name="bounce" mode="out-in">
    <div v-if="menuOpen" class="header-menu-inner">
      <div class="header-menu-top">
        <img class="header-menu-close" @click="hideMenu"
          :src="close"
        >
      </div>
      <ul class="header-menu-list">
        <li>
          <router-link to="/" @click="hideMenu">
            {{ $t('header.menu.home.name') }}
          </router-link>
        </li>
        <li>
          <router-link to="/basic-zone" @click="hideMenu">
            Basic Training Zone
          </router-link>
        </li>
        <li>
          <router-link to="/pricing" @click="hideMenu">
            {{ $t('header.menu.packages.name') }}
          </router-link>
        </li>
        <li class="header-menu-inner-not-active" @click="hideMenu">
          Small Group Training
        </li>
        <li class="header-menu-inner-not-active" @click="hideMenu">
          Personal Training
        </li>
        <div class="header-menu-top-languages">
          <div @click="changeLang('et')" class="header-menu-lang-mr-right" :class="this.$i18n.locale == 'et' ? 'header-menu-lang-select' : 'header-menu-lang-unselect'">
            ET
          </div>
          <div @click="changeLang('en')" class="header-menu-lang-mr-right" :class="this.$i18n.locale == 'en' ? 'header-menu-lang-select' : 'header-menu-lang-unselect'">
            EN
          </div>
          <div @click="changeLang('ru')" class="" :class="this.$i18n.locale == 'ru' ? 'header-menu-lang-select' : 'header-menu-lang-unselect'">
            RU
          </div>
        </div>
      </ul>
    </div>
  </transition>
</template>

<script>
import close from "@/assets/svg/new/close.svg"

export default {
  name: "HeaderMenu",
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
    hideMenu: {
      type: Function,
      required: true,
    }
  },
  methods: {
    changeLang(id) {
      if (id == this.$i18n.locale) {
        return;
      }
      this.hideMenu();
      this.$i18n.locale = id;
      localStorage.setItem('lang', id);
      this.$router.go();
    },
  },
  data() {
    return {
      close
    }
  },

}
</script>

<style scoped>

.header-menu-inner {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 0;
  padding: 24px 0 48px;
  width: 100%;
  background-color: #212225;
  color: white;
  z-index: 99;
  overflow-y: scroll;
  transition: all 0.8s ease-in-out;
}

.header-menu-inner-not-active {
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .header-menu-inner {
    width: 400px;
    border-bottom-left-radius: 16px;
    padding: 32px 0 40px;
  }
}

.header-menu-top {
  width: 100%;
  text-align: end;
  padding-right: 24px;
  margin-bottom: 16px;
  /*display: flex;*/
  /*justify-content: space-between;*/
}

@media screen and (min-width: 768px) {
  .header-menu-top {
    padding-right: 32px;
  }
}

.header-menu-top-languages {
  margin-top: 24px;
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  .header-menu-top-languages {
    display: none;
    font-size: 20px;
  }
}

.header-menu-lang-mr-right {
  margin-right: 24px;
}

.header-menu-lang-select {
  padding-bottom: 2px;
  border-bottom: 1px solid white;
}

.header-menu-lang-unselect {
  cursor: pointer;
}

.header-menu-close {
  cursor: pointer;
}

.header-menu-list {
  list-style-type: none
}

.header-menu-list li {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .header-menu-list li {
    font-size: 24px;
  }
}

</style>