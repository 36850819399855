<template>
  <div :class="'lang-container' + ' ' + (isDropdownLangVisible ? 'flex' : '')">
    <div class="lang-cell lang-active-cell">
    <div class="lang-name">
      <span>{{this.$i18n.locale}}</span>
    </div>
      <img
        :src="selectUp"
      >
    </div>
    <div v-for="lang in this.$i18n.availableLocales.filter(lang1 => lang1 != this.$i18n.locale)"
      class="lang-cell lang-not-active-cell"
      @click="changeLang(lang)"
      :id="lang"
      :key="lang">{{ lang }}</div>
  </div>
</template>

<script>
import selectUp from "@/assets/svg/new/select-up.svg";

export default {
  name: "LanguageSelector",
  data() {
    return {
      selectUp
    }
  },
  props: {
    isDropdownLangVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    changeLang(id) {
      this.$i18n.locale = id;
      localStorage.setItem('lang', id);
      this.$router.go();
    },
  }
}
</script>

<style scoped>
.lang-container {
  visibility: hidden;
  opacity: 0;
  //transition: visibility 0s, opacity 0.5s linear;
  display: flex;
  position: absolute;
  top: -1px;
  right: -1px;
  flex-direction: column;
  align-items: center;
  background: white;
  transition: all 0.3s;
  border-radius: 12px;
}

.flex {
  visibility: visible;
  opacity: 1;
}

.lang-active-cell {
//color: white;
//background: #0A0D0E;
//font-weight: 400;
  cursor: pointer;
}

.lang-not-active-cell {
  cursor: pointer;
}
.lang-not-active-cell:hover {
   color: white;
   //background-color: #030344;
  background-color: black;
   font-weight: 400;
}

.lang-cell {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-bottom: none;
  line-height: 32px;
  text-align: center;
  padding: 0 12px 0 20px;
  align-self: stretch;
  //margin-right: 1.25rem;
}

@media screen and (min-width: 1024px) {
  .lang-cell {
    line-height: 48px;
  }
}

.lang-cell:first-child {
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
}

.lang-cell:last-child {
   border-bottom: 1px solid #e1e1e1;
   border-bottom-left-radius: 12px;
   border-bottom-right-radius: 12px;
}

.lang-last-symbol {
  letter-spacing: 0;
}

.lang-name {
  margin-right: 6px;
  text-transform: uppercase;
}
</style>