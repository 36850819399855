<template>
  <div class="footer-container">
    <div class="footer-wrapper">
      <div class="contacts-left-col-container">
        <div class="contacts-left-cols">
          <div class="contacts-description-left-col">
            <p>{{ $t('homeNew.contacts.address') }}:</p>
            <p>Gonsiori 33, Tallinn</p>
            <p>+372 5843 5415</p>
            <p>+372 5819 3357</p>
            <p>info@35fit.com</p>
          </div>
          <div class="contacts-description-left-col">
            <p>{{ $t('homeNew.contacts.hours') }}:</p>
            <p>{{ $t('homeNew.contacts.monFri') }}: 06:00 - 22:00</p>
            <p>{{ $t('homeNew.contacts.satSun') }}: 07:00 - 21:00</p>
            <p>{{ $t('homeNew.contacts.holidays') }}:</p>
            <p>07:00 - 21:00</p>
          </div>
        </div>
      </div>
      <div class="footer-right-col-container">
        <div class="footer-right-col">
          <a href='/basic-zone' class="footer-right-col-link footer-right-col-link-white">
            Basic Training Zone
          </a>
          <a href='/pricing' class="footer-right-col-link footer-right-col-link-white">
            {{ $t('header.menu.packages.name') }}
          </a>
          <div class="footer-right-col-link">
            Small Group Training
          </div>
          <div class="footer-right-col-link">
            Personal Training
          </div>
        </div>
      </div>
    </div>
    <div class="footer-line"></div>
    <div class="footer-wrapper-bottom">
      <div class="footer-bottom-col-container-wrapper">
        <div class="footer-bottom-col-container-first">
          <div class="footer-bottom-col">
            <a href="https://app.35fit.com/resources/ClubRules.pdf">{{ $t('footer.rules') }}</a>
            <a href="https://app.35fit.com/resources/GDPR.pdf">{{ $t('footer.privacy') }}</a>
          </div>
        </div>
        <div class="footer-bottom-col-container">
          <div class="footer-bottom-col">
            <a href="https://app.35fit.com/resources/client_contract_ET.pdf">{{ $t('footer.contract') }}</a>
            <a href="https://app.35fit.com/resources/Hinnakiri.pdf">{{ $t('footer.pricelist') }}</a>
          </div>
        </div>
      </div>
      <div class="footer-bottom-col-container-stebby">
        <div class="footer-bottom-col">
          <a href="https://app.stebby.eu/pos/35fit.com"><div class="footer-bottom-button">{{ $t('footer.buyStebby') }}</div></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullFooter"
}
</script>

<style scoped>

.footer-container {
  background-color: black;
}

.footer-wrapper {
  width: 100%;
  max-width: calc(1357px + 160px);
  display: flex;
  padding: 40px 0 20px;
  flex-direction: column;
  gap: 40px;
}

@media screen and (min-width: 1024px) {
  .footer-wrapper {
    margin: 0 auto;
    padding: 80px 56px 40px;
    flex-direction: row;
    gap: 0;
  }
}

@media screen and (min-width: 1280px) {
  .footer-wrapper {
    padding: 80px 76px 40px;
  }
}
@media screen and (min-width: 1440px) {
  .footer-wrapper {
    padding: 80px 96px 40px;
  }
}

.footer-right-col-container {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .footer-right-col-container {
    justify-content: flex-end;
  }
}

.footer-right-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

@media screen and (min-width: 1024px) {
  .footer-right-col {
    align-items: flex-end;
  }
}

.footer-right-col-link {
  color: #8a8a8a;
  display: block;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
}

.footer-right-col-link-white {
  color: white
}

.contacts-left-col-container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .contacts-left-col-container {
    flex: 1 1 0;
    justify-content: flex-start;
  }
}

.contacts-left-cols {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

@media screen and (min-width: 520px) {
  .contacts-left-cols {
    gap: 68px;
  }
}

@media screen and (min-width: 1024px) {
  .contacts-left-cols {
    gap: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .contacts-left-cols {
    gap: 68px;
  }
}

@media screen and (min-width: 520px) {
  .contacts-left-cols {
    flex-direction: row;
  }
}

.contacts-description-left-col p{
  line-height: 1.5;
  font-size: 22px;
}

@media screen and (min-width: 1280px) {
  .contacts-description-left-col p{
    font-size: 24px;
  }
}

.contacts-description-left-col p:first-child {
  margin-bottom: 16px;
}

@media screen and (min-width: 520px) {
  .contacts-description-left-col p:first-child {
    margin-bottom: 24px;
  }
}

.footer-wrapper-bottom {
  font-size: 18px;
  width: 100%;
  margin: 0 auto;
  max-width: calc(1357px + 160px);
  display: flex;
  padding: 20px 16px 20px;
  flex-direction: column-reverse;
  gap: 24px;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .footer-wrapper-bottom {
    margin: 0 auto;
    padding: 20px 40px 20px;
    flex-direction: row;
    gap: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer-wrapper-bottom {
    font-size: 20px;
    padding: 20px 56px 20px;
  }
}

@media screen and (min-width: 1280px) {
  .footer-wrapper-bottom {
    padding: 20px 76px 20px;
  }
}

@media screen and (min-width: 1440px) {
  .footer-wrapper-bottom {
    padding: 20px 96px 20px;
  }
}

.footer-bottom-col-container {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-start;
}

.footer-bottom-col-container-wrapper {
  flex: 2 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px
}

@media screen and (min-width: 768px) {
  .footer-bottom-col-container-wrapper {
    flex-direction: row;
    gap: 0;
  }
}

.footer-bottom-col-container-first {
  flex: 1.6 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .footer-bottom-col-container-first {
    flex: 1.3 0 0;
  }
}

@media screen and (min-width: 1280px) {
  .footer-bottom-col-container-first {
    flex: 1 0 0;
  }
}

@media screen and (min-width: 1440px) {
  .footer-bottom-col-container-first {
    flex: 0.9 0 0;
  }
}

.footer-bottom-col-container-stebby {
  flex: 2 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-bottom-col {
//color: #b6b6b6;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px
}

.footer-line {
  border-top: 0.5px solid #8b8b8b;
  width: 100%;
}

.footer-bottom-button {
  font-size: 20px;
  padding: 6px 32px;
  border: 1px solid #e3e3e3;
//background-color: #b6b6b6;
  color: #e3e3e3;
  border-radius: 40px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .footer-bottom-button {
    font-size: 20px;
  }
}

.footer-bottom-button:hover {
  background-color: white;
  color: #000000;
  transition: all 0.3s;
}

</style>