<template>
  <div class="opengym-packages-wrapper">
    <div class="opengym-packages-title-wrapper">
        <div class="opengym-packages-title-small">{{ $t('basicTrainingZone.packages.title') }}</div>
        <router-link to="/pricing#basic">
          <div class="opengym-packages-button ">{{ $t('basicTrainingZone.packages.buttonText') }}</div>
        </router-link>
    </div>
    <div class="opengym-packages-list-wrapper">
      <div class="opengym-packages-image-wrapper">
        <img class="opengym-packages-image"
          :src="slide1"
        >
      </div>
      <div class="opengym-packages-list">
        <TariffCard
          :name="packages.plans[0].name ?? ''"
          :description="packages.plans[0].description ?? ''"
          :price="packages.plans[0].price ?? ''"
          :period="packages.plans[0].period ?? ''"
          color="blue"
        />
        <TariffCard
          :name="packages.plans[1].name ?? ''"
          :description="packages.plans[1].description ?? ''"
          :price="packages.plans[1].price ?? ''"
          :period="packages.plans[1].period ?? ''"
          color="blue"
        />
        <TariffCard
          :name="packages.plans[2].name ?? ''"
          :description="packages.plans[2].description ?? ''"
          :price="packages.plans[2].price ?? ''"
          :period="packages.plans[2].period ?? ''"
          color="blue"
        />
      </div>
    </div>
    <div class="opengym-packages-bottom-button-wrapper">
      <router-link to="/pricing#basic">
        <div class="opengym-packages-button opengym-packages-button-bottom">{{ $t('basicTrainingZone.packages.buttonText') }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import packages from "@/resources/packages.json";
import TariffCard from "@/components/tariffs/TariffCard";
import slide1 from '@/assets/img/new/packages_image.jpg';

export default {
  name: "OpenGymPackages",
  components: {
   TariffCard
  },
  data() {
    return {
      packages,
      slide1
    }
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.packages = this.packages.plans.en
        break;
      case 'et':
        this.packages = this.packages.plans.et
        break;
      case 'ru':
        this.packages = this.packages.plans.ru
        break;
      default:
        this.packages = this.packages.plans.en
        break;
    }
  },
}
</script>

<style scoped>

.opengym-packages-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opengym-packages-title-wrapper {
  margin: 8px 0 28px;
  padding: 0 16px;
  display: flex;
  width: 100%;
  max-width: calc(653px + 680px + 24px + 160px);
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .opengym-packages-title-wrapper {
    margin: 8px 0 42px;
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-packages-title-wrapper {
    padding: 0 40px;
    margin: 20px 0 56px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-packages-title-wrapper {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .opengym-packages-title-wrapper {
    padding: 0 60px;
  }
}

@media screen and (min-width: 1440px) {
  .opengym-packages-title-wrapper {
    padding: 0 80px;
  }
}

.opengym-packages-title-small {
  max-width: 600px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  white-space: pre-line
}

@media screen and (min-width: 768px) {
  .opengym-packages-title-small {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-packages-title-small {
    font-size: 40px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-packages-title-small {
    text-align: inherit;
  }
}

.opengym-packages-button {
  display: none;
  font-size: 20px;
  padding: 6px 32px;
  border: 2px solid #000000;
  background-color: #000000;
  color: white;
  border-radius: 40px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .opengym-packages-button {
    font-size: 24px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-packages-button {
    display: inherit;
  }
}

.opengym-packages-button:hover {
  background-color: white;
  color: #000000;
  transition: all 0.3s;
}

.opengym-packages-image-wrapper {
  display: none;
}

@media screen and (min-width: 1190px) {
  .opengym-packages-image-wrapper {
    display: inherit;
  }
}

.opengym-packages-image {
  object-fit: cover;
  object-position: right;
  height: 100%;
  width: 100%;
}

.opengym-packages-list-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .opengym-packages-list-wrapper {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-packages-list-wrapper {
    padding: 0 40px;
  }
}

@media screen and (min-width: 1280px) {
  .opengym-packages-list-wrapper {
    padding: 0 60px;
  }
}

@media screen and (min-width: 1440px) {
  .opengym-packages-list-wrapper {
    padding: 0 80px;
  }
}

.opengym-packages-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

@media screen and (min-width: 716px) {
  .opengym-packages-list {
    width: auto;
  }
}

.opengym-packages-bottom-button-wrapper {
  width: 100%;
  max-width: 712px;
  display: flex;
  justify-content: flex-end;
  padding: 32px 16px;
}

.opengym-packages-button-bottom {
  display: inherit;
}

@media screen and (min-width: 1190px) {
  .opengym-packages-button-bottom {
    display: none;
  }
}

</style>