<template>
  <div class="contract-packages-container">
    <div class="contract-packages-title">{{ $t('packages.startPackages.title') }}</div>
    <div class="contract-packages-line contract-packages-line-margin">
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="startplans.plans[0].id ?? ''"
        :name="startplans.plans[0].name ?? ''"
        :description="startplans.plans[0].description ?? ''"
        :price="startplans.plans[0].price ?? ''"
        :period="startplans.plans[0].period ?? ''"
        :color="startplans.plans[0].color ?? ''"
      />
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="startplans.plans[1].id ?? ''"
        :name="startplans.plans[1].name ?? ''"
        :description="startplans.plans[1].description ?? ''"
        :price="startplans.plans[1].price ?? ''"
        :period="startplans.plans[1].period ?? ''"
        :color="startplans.plans[1].color ?? ''"
      />
    </div>
    <div class="contract-packages-title contract-packages-section-margin">{{ $t('packages.mainPackages.title') }}</div>
    <div class="contract-packages-line contract-packages-line-margin">
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="packages.plans[0].id ?? ''"
        :name="packages.plans[0].name ?? ''"
        :description="packages.plans[0].description ?? ''"
        :price="packages.plans[0].price ?? ''"
        :period="packages.plans[0].period ?? ''"
        :color="packages.plans[0].color ?? ''"
      />
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="packages.plans[1].id ?? ''"
        :name="packages.plans[1].name ?? ''"
        :description="packages.plans[1].description ?? ''"
        :price="packages.plans[1].price ?? ''"
        :period="packages.plans[1].period ?? ''"
        :color="packages.plans[1].color ?? ''"
      />
    </div>
    <div class="contract-packages-line contract-packages-between-margin">
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="packages.plans[2].id ?? ''"
        :name="packages.plans[2].name ?? ''"
        :description="packages.plans[2].description ?? ''"
        :price="packages.plans[2].price ?? ''"
        :period="packages.plans[2].period ?? ''"
        :color="packages.plans[2].color ?? ''"
      />
      <PackagesCard
        @select="(planId) => select(planId)"
        :id="packages.plans[3].id ?? ''"
        :name="packages.plans[3].name ?? ''"
        :description="packages.plans[3].description ?? ''"
        :price="packages.plans[3].price ?? ''"
        :period="packages.plans[3].period ?? ''"
        :color="packages.plans[3].color ?? ''"
      />
    </div>
    <transition name="bounce">
      <div v-if="(this.selectedPlanId)"
           class="subscribe-section">
        <div class="subscribe-section-wrapper">
          <transition name="bounce"
                      mode="out-in">
            <h4 :key="this.selectedPlanId">
              {{ selectedPlan.name}}
            </h4>
          </transition>
          <MainButton :text="$t('pricing.other.buy')"
                      @click="openModal" />
          <button @click="cancelSelect"
                  class="cancel-subscription">{{ $t('pricing.other.cancel') }}</button>
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <ModalWindow v-if="this.showModal"
                   @closeModal="this.showModal = !this.showModal;"
                   :title="$t('pricing.other.modal.title')"
                   :subtitle="$t('pricing.other.modal.subtitle')">
        <transition name="bounce"
                    mode="out-in">
          <LoaderView v-if="loader" />
          <div v-else
               class="modal-confirm-plan">
            <img class="new-contract-img"
                 :src="contract"
                 alt="contract">
            <transition name="bounce">
              <InputNotify class="full-width"
                           v-if="warning.show"
                           :icon="warning.icon"
                           :text="warning.text" />
            </transition>
            <InputNotify class="full-width"
                         v-if="replacePlan.show"
                         :icon="replacePlan.icon"
                         :text="replacePlan.text" />
            <PackagesCard
              :name="selectedPlan.name ?? ''"
              :description="selectedPlan.description ?? ''"
              :price="selectedPlan.price ?? ''"
              :period="selectedPlan.period ?? ''"
              :color="selectedPlan.color ?? ''"
            />
            <label class="checkbox-container">
              <input type="checkbox"
                     v-model="isPayWithStebby">
              <span class="checkmark"></span>
              <p>
                {{ $t('pricing.other.payWithStebby') }}
              </p>
            </label>
            <label class="checkbox-container">
              <input type="checkbox"
                     v-model="confirmedConditions">
              <span class="checkmark"></span>
              <p>
                {{ $t('pricing.other.acknowledge') }}
                <a class="intext-link"
                   target="_blank"
                   href="https://app.35fit.com/resources/contract.pdf">
                  {{ $t('footer.contract') }}
                </a>,
                <a class="intext-link"
                   target="_blank"
                   href="https://app.35fit.com/resources/ClubRules.pdf">
                  {{ $t('footer.rules') }}
                </a> &
                <a class="intext-link"
                   target="_blank"
                   href="https://app.35fit.com/resources/Hinnakiri.pdf">
                  {{ $t('pricing.other.pricing') }}
                </a>
              </p>
            </label>
            <transition name="bounce">
              <MainButton v-if="confirmedConditions"
                          :text="$t('pricing.other.subscribe')"
                          :widthRestrict="true"
                          @click="subscribe"
                          :disabled="!confirmedConditions" />
            </transition>
          </div>
        </transition>
      </ModalWindow>
    </transition>
  </div>
</template>

<script>
import packages from '@/resources/packages.json'
import additionalservices from '@/resources/additionalservices.json'
import startplans from '@/resources/startplans.json'
import PackagesCard from "@/components/dashboard/PackagesCard";
import MainButton from "@/components/buttons/MainButton";
import ModalWindow from "@/components/ModalWindow";
import InputNotify from "@/components/InputNotify";
import LoaderView from "@/components/buttons/LoaderView";
import contract from "@/assets/svg/contract.svg";
import xmark from "@/assets/svg/xmark.svg";
import info from "@/assets/svg/info.svg";
import router from "@/router";
import {state} from "@/state";

export default {
  name: "ContractPackages",
  components: {
    PackagesCard,
    MainButton,
    ModalWindow,
    InputNotify,
    LoaderView
  },
  data() {
    return {
      packages,
      additionalservices,
      startplans,
      selectedPlanId: null,
      showModal: false,
      selectedPlan: null,
      confirmedConditions: false,
      isPayWithStebby: false,
      contract,
      loader: false,
      xmark,
      warning: {
        show: false,
        icon: "",
        text: ""
      },
      replacePlan: {
        show: true,
        icon: info,
        text: this.$t('pricing.other.planChange')
      }
    }
  },
  methods: {
    select(planId) {
      this.selectedPlanId = planId
      this.selectedPlan = this.packages.plans.filter(plan => (plan.id == planId))[0] || this.startplans.plans.filter(plan => (plan.id == planId))[0]
      const newPlan = document.getElementById(planId);
      var prev = document.querySelector('.selected-plan');
      if (prev) {
        prev.classList.remove('selected-plan');
      }
      newPlan.classList.add('selected-plan');
    },
    cancelSelect() {
      this.selectedPlanId = null
      this.selectedPlan = null
      var prev = document.querySelector('.selected-plan');
      if (prev) {
        prev.classList.remove('selected-plan');
      }
    },
    openModal() {
      if (localStorage.getItem('token')) {
        const body = document.querySelector('body');
        this.showModal = !this.showModal;
        body.classList.toggle('menu-open');
        return
      }
      this.$router.push('/login');
    },
    subscribe() {
      this.warning.show = false;
      this.loader = true;

      fetch('https://app.35fit.com/api/v2/users/change_ticket', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' +
            localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          package_id: this.selectedPlanId,
          payment_with_stebby: this.isPayWithStebby,
        })
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.status);
          }
          return res.json();
        })
        .then(data => {
          if (data.success) {
            this.openModal();
            router.push({
              name: 'dashboard',
              hash: '#contract'
            });
            return
          }

          this.warning = {
            icon: this.xmark,
            text: data.message,
            show: true
          }

          this.loader = false;

        })
        .catch(err => {
          console.log(err)
          if (err.message == 401) {
            this.openModal();
            localStorage.removeItem('token');
            state.authorized = false;
            this.$router.push('/login');
          }
          this.loader = false;
        })
    }
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.packages = this.packages.plans.en
        this.additionalservices = this.additionalservices.plans.en
        this.startplans = this.startplans.plans.en
        break;
      case 'et':
        this.packages = this.packages.plans.et
        this.additionalservices = this.additionalservices.plans.et
        this.startplans = this.startplans.plans.et
        break;
      case 'ru':
        this.packages = this.packages.plans.ru
        this.additionalservices = this.additionalservices.plans.ru
        this.startplans = this.startplans.plans.ru
        break;
      default:
        this.packages = this.packages.plans.en
        this.additionalservices = this.additionalservices.plans.en
        this.startplans = this.startplans.plans.en
        break;
    }
  },
}
</script>

<style scoped>

.contract-packages-container {
  width: 100%;
  margin-bottom: 40px;
}

.contract-packages-title {
  font-size: 22px;
  text-align: center;
  //font-weight: bold;
}

@media screen and (min-width: 1140px) {
  .contract-packages-title {
    text-align: left;
  }
}

.contract-packages-subtitle {
  max-width: 900px;
  font-size: 18px;
  color: #8a8a8a;
}

.contract-packages-line {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1140px) {
  .contract-packages-line {
    flex-direction: row;
    align-items: stretch;
  }
}

.contract-packages-line-margin {
  margin-top: 28px;
}

.contract-packages-section-margin {
  margin-top: 40px;
}



.contract-packages-between-margin {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .contract-packages-between-margin {
    margin-top: 24px;
  }
}

.intext-link {
  color: var(--dark-blue);
  font-weight: 600;
}

.new-contract-img {
  width: 100%;
  max-width: 350px;
}

.modal-confirm-plan {
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.cancel-subscription {
  color: var(--red);
  text-align: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  font: var(--font);
}

.subscribe-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 99;
}

.subscribe-section-wrapper {
  position: relative;
  padding: 20px;
  width: 300px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.496);
  backdrop-filter: blur(20px);
  border-top-left-radius: var(--card-br);
  border-top-right-radius: var(--card-br);
  border: 1px solid #b6b6b6;
}

@media screen and (max-width: 570px) {
  .subscribe-section-wrapper {
    width: 100%;
  }
}

.subscribe-section-wrapper h4 {

  text-align: center;
}

</style>