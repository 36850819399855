<template>
  <div class="trial-container">
    <div class="trial-text-container">
      <div class="trial-title">

        {{ $t('basicTrainingZone.about.firstLine') }} 40 <span style="font-size: 0.8em">€</span>/{{ $t('basicTrainingZone.about.month') }}
      </div>
      <div class="trial-first-text-line">
        {{ $t('basicTrainingZone.about.secondLine') }}
      </div>
<!--      <div class="trial-second-text-line">-->
<!--        Buy the start+ package to better understand which training package is best for you!-->
<!--      </div>-->
      <router-link to="/pricing#basic">
        <div class="trial-lead-button">
          {{ $t('basicTrainingZone.about.buttonText') }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenGymAbout"
}
</script>

<style scoped>

.trial-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 24px 16px;
}

@media screen and (min-width: 768px) {
  .trial-container {
    padding: 24px 24px 32px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-container {
    padding: 40px 0 80px;
  }
}

.trial-text-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 24px;
}

.trial-title {
  font-size: 20px;
  color: #8A8A8A;
}

@media screen and (min-width: 768px) {
  .trial-title {
    font-size: 24px;
  }
}

.trial-first-text-line {
  //text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  max-width: 1160px;
  white-space: pre-line;
}

@media screen and (min-width: 768px) {
  .trial-first-text-line {
    font-size: 28px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-first-text-line {
    font-size: 40px;
  }
}

.trial-second-text-line {
  font-size: 20px;
  line-height: 1.5;
  color: #8a8a8a;
  list-style-type: none;
  text-align: center;
  max-width: 430px;
}

@media screen and (min-width: 768px) {
  .trial-second-text-line {
    font-size: 24px;
    max-width: 600px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-second-text-line {
    font-size: 32px;
    max-width: 700px;
  }
}

.trial-lead-button {
//text-transform: uppercase;
  font-size: 20px;
  padding: 6px 32px;
  border: 2px solid #000000;
  background-color: #000000;
  color: white;
  border-radius: 40px;
  margin-top: 16px;
  cursor: pointer;
//transition: all 0.3s;
}

@media screen and (min-width: 768px) {
  .trial-lead-button {
    margin-top: 30px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-lead-button {
    margin-top: 48px;
  }
}

.trial-lead-button:hover {
  background-color: white;
  color: #000000;
  transition: all 0.3s;
}

</style>