<template>
  <div class="trial-container">
    <div class="trial-text-container">
      <div class="trial-title">
        {{ $t('homeNew.trial.firstLine') }}<span style="font-size: 0.8em">€</span>
      </div>
      <div class="trial-first-text-line">
        {{ $t('homeNew.trial.secondLine') }}
      </div>
      <ul class="trial-second-text-line">
        <li>{{ $t('homeNew.trial.thirdLine[0]') }}</li>
        <li>{{ $t('homeNew.trial.thirdLine[1]') }}</li>
        <li>{{ $t('homeNew.trial.thirdLine[2]') }}</li>
        <li>{{ $t('homeNew.trial.thirdLine[3]') }}<span style="font-size: 0.8em">€</span></li>
      </ul>
      <router-link :to='state.authorized ? `/dashboard#contract` : "/pricing"'>
        <div class="trial-lead-button">
          {{ $t('homeNew.trial.buttonText') }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {state} from "@/state";

export default {
  name: "TrialTraining",
  data() {
    return {
      state
    }
  },
}
</script>

<style scoped>

.trial-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 24px 16px;
}

@media screen and (min-width: 768px) {
  .trial-container {
    padding: 24px 24px 32px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-container {
    padding: 40px 0 80px;
  }
}

.trial-text-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 24px;
}

.trial-title {
  font-size: 24px;
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .trial-title {
    font-size: 32px;
  }
}

.trial-first-text-line {
  //text-transform: uppercase;
  font-size: 34px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .trial-first-text-line {
    font-size: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-first-text-line {
    font-size: 52px;
  }
}

.trial-second-text-line {
  font-size: 22px;
  line-height: 1.5;
  color: #8a8a8a;
  list-style-type: none;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .trial-second-text-line {
    font-size: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-second-text-line {
    font-size: 32px;
  }
}

.trial-lead-button {
  //text-transform: uppercase;
  font-size: 20px;
  padding: 6px 32px;
  border: 2px solid #000000;
  background-color: #000000;
  color: white;
  border-radius: 40px;
  margin-top: 24px;
  cursor: pointer;
  //transition: all 0.3s;
}

@media screen and (min-width: 768px) {
  .trial-lead-button {
    margin-top: 30px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .trial-lead-button {
    margin-top: 48px;
  }
}

.trial-lead-button:hover {
  background-color: white;
  color: #000000;
  transition: all 0.3s;
}

</style>