<template>
    <transition name="bounce"
     mode="out-in">
        <LoaderView v-if="loader" />
        <div v-else
         class="contract-page">
            <NotifyWarning v-if="contractMessage.show"
             :title="contractMessage.title"
             :text="contractMessage.text"
             :icon="contractMessage.icon"
             color="info"
             @close="contractMessage.show = false" />

            <InputNotify v-for="
         discount
         in
         discounts"
             :key="discount.discountText"
             class="full-width"
             :icon="this.info"
             :text="discount.discountText" />

            <NoContract v-if="(ticket == null)" />

            <div v-else
             class="contract-flex-tiles">
                <CurrentContract :ticket="ticket" />
                <PaymentWidget :ticket="ticket"
                 @pay="openModal()"
                 @vacationConfirmed="getContract" />
            </div>

            <transition name="bounce">
                <PaymentModal v-if="showModal"
                 @close="showModal = false"
                 @pay="method => pay(method)" />
            </transition>

            <transition name="bounce" mode="out-in">
              <div class="prev-contracts">
                <h3 class="prev-contracts-heading prev-contracts-heading-title">
                  {{ ticket == null ? $t('dashboard.pages.contract.buyNew') : $t('dashboard.pages.contract.changeTicket')}}
                </h3>
                <ContractPackages/>
              </div>
            </transition>

            <transition name="bounce"
             mode="out-in">
                <LoaderView v-if="loadContracts"
                 style="max-height: 150px;" />
                <div v-else
                 class="prev-contracts">
                    <h3 class="prev-contracts-heading">
                        {{ $t('dashboard.pages.contract.last') }}
                    </h3>
                    <ListRow v-for="(ticket) in this.oldTickets"
                     :key="ticket.id"
                     :title="ticket.name"
                     :date="ticket.started.split('T')[0].split('-').reverse().join('.') + ' - ' +
                         ticket.finished.split('T')[0].split('-').reverse().join('.')"
                     :showButton="false"
                     @clicked="prolongContract(ticket.ticket_id)"
                     :icon="((new Date(ticket.finished) > Date.now()) ?
                         (new Date(ticket.started) > Date.now() ? hold : active) : inactive)" />
                </div>
            </transition>

            <transition name="bounce"
             mode="out-in">
                <div class="prev-contracts"
                 v-if="this.vacations.length > 0">
                    <h3 class="prev-contracts-heading">Vacations</h3>
                    <ListRow v-for="vacation in this.vacations"
                     :key="vacation.id"
                     title="Vacation"
                     :date="vacation.started.split('-').reverse().join('.') + ' - ' +
                         vacation.finished.split('-').reverse().join('.')"
                     :icon="((new Date(vacation.finished) >= Date.now()) && (Date.now() >= new Date(vacation.started))) ? active : inactive"
                     :showButton="false" />
                </div>

            </transition>
        </div>
    </transition>
</template>

<script>
import CurrentContract from '@/components/dashboard/CurrentContract.vue'
import PaymentWidget from './PaymentWidget.vue';
import ListRow from './ListRow.vue';
import doc from '@/assets/svg/doc.svg'
import LoaderView from '../buttons/LoaderView.vue';
import InputNotify from '../InputNotify.vue';
import info from '@/assets/svg/info.svg'
import active from '@/assets/svg/dot.svg'
import inactive from '@/assets/svg/dot-gray.svg'
import hold from '@/assets/svg/dot-yellow.svg'
import NoContract from './NoContract.vue';
import NotifyWarning from '../warnings/NotifyWarning.vue';
import PaymentModal from './PaymentModal.vue';
import {state} from "@/state";
import ContractPackages from "@/components/dashboard/ContractPackages";

export default {
    name: 'ContractPage',
    components: {
      ContractPackages,
        CurrentContract,
        PaymentWidget,
        ListRow,
        LoaderView,
        InputNotify,
        NoContract,
        NotifyWarning,
        PaymentModal
    },
    props: {
        toPay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            info,
            active,
            inactive,
            hold,
            loader: true,
            doc,
            showModal: false,
            ticket: null,
            vacations: [],
            oldTickets: null,
            labels: null,
            showNotify: {
                show: false,
                text: '',
                icon: ''
            },
            contractMessage: {
                show: false,
                title: '',
                text: '',
                icon: ''
            },
            loadContracts: false,
            discounts: [],
            state


        }
    },
    methods: {
        checkDiscounts() {
            this.checkGeneralDiscount()
        },
        checkDynosticsDiscount() {
            if (this.ticket.ticket_ticket_id != 613481) {
                let ticketStartDate = new Date(this.ticket.started);
                let fourteenDays = new Date(ticketStartDate.setDate(ticketStartDate.getDate() + 14))
                if (fourteenDays >= Date.now()) {
                    this.discounts.push({
                        discountText: this.$t('dashboard.pages.contract.discounts.testDiscount')
                    })
                }
            }
        },
        checkGeneralDiscount() {
            this.ticket.labels = this.labels
            this.labels.forEach(element => {
                switch (element) {
                    case 589:
                        this.discounts.push({
                            discountText: 'You have discount on Smart plan - only for 33 euro'
                        })
                        break;
                    case 615:
                        this.discounts.push({
                            discountText: this.$t('dashboard.pages.contract.discounts.workerDiscount')
                        })
                        break;
                    default:
                        break;
                }
            });
        },
        showProlong(ticket) {
            if (ticket.ticket_ticket_id == 613481) {
                return false
            }
            let today = new Date();
            let tenDays = new Date(today.setDate(today.getDate() + 30));

            if (ticket.ticket_ticket_id === 632369) {
                tenDays = new Date(today.setDate(today.getDate() - 10));
            }

            let finish = new Date(ticket.finished);

            return tenDays >= finish;
        },
        openModal() {
            const body = document.querySelector('body');
            this.showModal = true;
            body.classList.toggle('menu-open');
            return
        },
        showNotification(message) {
            this.contractMessage.show = true
            this.contractMessage.title = 'Notification'
            this.contractMessage.text = message
            this.contractMessage.icon = this.info

            setTimeout(() => {
                this.contractMessage.title = ''
                this.contractMessage.text = ''
                this.contractMessage.icon = ''
            }, 5000);
        },
        prolongContract(ticket_id) {
            this.loadContracts = true
            this.connectServer('POST', 'https://app.35fit.com/api/users/ticket/extend', { ticket_id: ticket_id })
                .then(response => {
                    this.oldTickets.unshift(response.ticket)
                    this.showNotification(response.message)
                    this.loadContracts = false
                })
        },
        getVacantions(ticket_id) {
            this.connectServer('GET', 'https://app.35fit.com/api/users/vacations?ticket_id=' + ticket_id)
                .then(response => {
                    if (response.success) {
                        this.vacations = response.data
                    }
                })

        },
        async connectServer(method, url, body = null) {
            return fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: (body === null) ? body : JSON.stringify(body)

            })
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.status);
                    }
                    return res.json();
                })
                .then(data => {
                    this.loading = false;
                    if (data.success) {
                        return data
                    }

                    return null
                })
                .catch(err => {
                    console.log(err.message)

                    switch (err.message) {
                        case '401':
                            localStorage.removeItem('token');
                            state.authorized = false;
                            this.$router.push('/login');
                            break;
                        case '204':
                            this.showNotification('Your code was either invalid or already used. Please try again.')
                            break;
                    }
                })
        },
        pay(method) {
            if (method.method == 'paysera') {
                this.connectServer('POST', 'https://app.35fit.com/api/users/pay', { to_pay: ((this.ticket.debt > 0) ? this.ticket.debt : this.ticket.next_payment_price) })
                    .then(response => {
                        if (response.success) {
                            window.location.href = response.link;
                        } else {
                            this.showNotification('An error occured getting payment link. Please try again later.')
                        }
                    })
            } else {
                this.showModal = false
                this.connectServer('POST', 'https://app.35fit.com/api/users/pay/stebby', { ticket_code: method.ticket_code })
                    .then(response => {
                        if (response.success) {
                            this.getContract()
                            this.showNotification(response.message)
                        }

                    })
            }
        },
        getContract() {
            this.connectServer('GET', 'https://app.35fit.com/api/users/ticket')
                .then(response => {
                    if (response.success) {
                        this.oldTickets = response.old_tickets
                        if (response.ticket) {
                            this.ticket = response.ticket
                            if (response.ticket.labels) {
                                this.labels =
                                    response.ticket.labels.match(/\d+/g).map(Number)
                                this.checkDiscounts()
                            }

                            this.getVacantions(this.ticket.ticket_id)
                        }

                        this.loader = false
                        return
                    }
                })
        }
    },

    mounted() {
        this.getContract()
        if (this.toPay) {
            setTimeout(() => {
                this.openModal()
            }, 1000);
            this.$emit('opened')
        }

    },

}
</script>

<style>
.contract-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 20px;
}

.contract-flex-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 10px;
}

.prev-contracts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.prev-contracts-heading {
  font-size: 18px;
    margin: 10px 0;
}

.prev-contracts-heading-title {
  font-size: 22px;
}

.stebby-input {
    max-width: 100%;
}

.stebby-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

@media (max-width: 515px) {
    .contract-flex-tiles {
        grid-template-columns: 1fr;
    }
}
</style>
