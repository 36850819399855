<template>
<div :class="direction == 'horizontal' ? 'tariff-card-container tariff-card-horizontal' : 'tariff-card-container tariff-card-vertical'">
  <div class="tariff-card-first-line">
    <div class="tariff-card-text-block">
      <div class="tariff-card-title-block">
        <div class="tariff-card-title">
          {{name}}
        </div>
        <div class="tariff-card-small-image-block">
          <img class="tariff-card-small-image"
            :src="color == 'green' ? green : color == 'violet' ? violet : blue"
          />
        </div>
      </div>
      <ul class="tariff-card-description-block">
        <li v-for="item in description" :key="item">
          {{item}}
        </li>
      </ul>
    </div>
    <div class="tariff-card-image-block">
      <img
        :src="color == 'green' ? green : color == 'violet' ? violet : blue"
      />
    </div>
  </div>
  <div class="tariff-card-price-wrapper">
    <router-link v-if="button" :to='state.authorized ? `/dashboard#${page}` : "/login"'>
      <div class="tariff-card-button">
        {{ $t('packages.card.buy') }}
      </div>
    </router-link>
    <div v-if="!button"></div>
    <div class="tariff-card-price-line">
      {{price}} <span class="tariff-card-price-currency">€{{period != false ? '/'+period : ''}}</span>
    </div>
  </div>
</div>
</template>

<script>
import green from '@/assets/img/new/green-card-logo.png';
import violet from '@/assets/img/new/violet-card-logo.png';
import blue from '@/assets/img/new/blue-card-logo.png';
import { state } from '@/state.js'

export default {
  name: "TariffCard",
  data() {
    return {
      green,
      violet,
      blue,
      state
    }
  },
  props: {
    name: {
      type: String,
    },
    description: {
      type: Array,
    },
    price: {
      type: Number,
    },
    period: {
      type: [String, Boolean],
    },
    color: {
      type: String,
    },
    direction: {
      type: String
    },
    page: {
      type: String
    },
    button: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

.tariff-card-horizontal {
  width: 100%;
  flex: 1 0 calc(50% - 24px);
}

.tariff-card-vertical {
  //width: 100%;
}

@media screen and (min-width: 716px) {
  .tariff-card-vertical {
    width: 680px;
  }
}

.tariff-card-container {
  max-width: 684px;
  padding: 24px;
  background-color: #F2F2F2;
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .tariff-card-container {
    padding: 40px;
  }
}

.tariff-card-first-line {
  display: flex;

}

@media screen and (min-width: 768px) {
  .tariff-card-first-line {
    min-height: 180px;
  }
}

.tariff-card-text-block {
  flex: 1 0 0;
}

.tariff-card-title-block {
  display: flex;
}

.tariff-card-title {
  flex: 1 0 auto;
  font-size: 20px;
  font-weight: bold;
  white-space: pre-line;
  line-height: 23px;
  text-transform: uppercase;
}

@media screen and (min-width: 500px) {
  .tariff-card-title {
    font-size: 26px;
    line-height: 30px;
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-title {
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-title {
    font-size: 36px;
    line-height: 41px;
  }
}

.tariff-card-small-image-block {
  flex: 0 1 auto;
}

@media screen and (min-width: 768px) {
  .tariff-card-small-image-block {
    display: none;
  }
}

.tariff-card-small-image {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 500px) {
  .tariff-card-small-image {
    width: 80px;
    height: 80px;
  }
}


.tariff-card-description-block {
  margin-left: 24px;
  font-size: 18px;
  line-height: 22px;
  margin-top: 8px;
}

@media screen and (min-width: 500px) {
  .tariff-card-description-block {
    font-size: 20px;
    line-height: 24px;
    margin-top: 16px;
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-description-block {
    font-size: 24px;
    line-height: 29px;
    margin-top: 24px;
  }
}

.tariff-card-description-block li:not(:last-child) {
  margin-bottom: 4px; /* Отступ только между элементами */
}

.tariff-card-image-block {
  display: none;
}

@media screen and (min-width: 768px) {
  .tariff-card-image-block {
    display: block;
    margin-left: 24px;
    flex: 0 1 auto;
  }
}

.tariff-card-price-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

@media screen and (min-width: 500px) {
  .tariff-card-price-wrapper {
    margin-top: 24px;
  }
}

.tariff-card-button {
  padding: 5px 24px;
  cursor: pointer;
  border: 1px solid black;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 24px;
}

.tariff-card-button:hover {
  border-color: black;
  background-color: black;
  color: white;
  transition: all 0.3s;
}

@media screen and (min-width: 500px) {
  .tariff-card-button {
    padding: 6px 26px;
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-button {
    padding: 8px 30px;
    font-size: 20px;
  }
}

.tariff-card-price-line {
  text-align: right;
  font-size: 22px;
  font-weight: bold;
}

@media screen and (min-width: 500px) {
  .tariff-card-price-line {
    font-size: 28px;
  }
}

@media screen and (min-width: 768px) {
  .tariff-card-price-line {
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-price-line {
    font-size: 36px;
  }
}

.tariff-card-price-currency {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  .tariff-card-price-currency {
    font-size: 26px;
  }
}

@media screen and (min-width: 1280px) {
  .tariff-card-price-currency {
    font-size: 30px;
  }
}

</style>