<template>
  <img
    :src="button"
    class="logo-menu"
  >
</template>

<script>
import button from "@/assets/svg/new/menu-button.svg"
export default {
  name: "MenuButton",

  data() {
    return {
      button
    }
  },
}
</script>

<style scoped>

.logo-menu {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .logo-menu {
    width: 32px;
    height: 32px
  }
}

</style>