<template>
  <router-link :to="state.authorized ? '/dashboard' : '/login'">
    <div class="login-button-container">
      <div class="login-button-text">
        {{state.authorized ? $t('header.account') : $t('header.login')}}
      </div>
    </div>
  </router-link>
</template>

<script>
import { state } from '@/state.js'

export default {
  name: "LoginButton",
  data() {
    return {
      state,
    };
  },
}
</script>

<style scoped>

.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 16px;
  background-color: white;
  color: black;
  border-radius: 21px;
  cursor: pointer;
  border-color: black;
  /*border-color: #000939;*/
  border: 1px solid;
}

.login-button-container:hover {
  /*border-color: #000939;*/
  /*background-color: #000939;*/
  border-color: black;
  background-color: black;
  color: white;
  transition: all 0.3s;
}

@media screen and (min-width: 1024px) {
  .login-button-container {
    height: 42px;
    padding: 0 30px;
  }
}

.login-button-text {
  font-size: 12px;
  text-transform: uppercase;
}

@media screen and (min-width: 1024px) {
  .login-button-text {
    font-size: 16px;
  }
}

</style>