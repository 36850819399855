import { reactive } from 'vue'

export const state = reactive({
    isTrial: false,
    openBooking: false,
    toggleBooking() {
        this.openBooking = !this.openBooking
    },
    authorized: !!localStorage.getItem('token'),
})
