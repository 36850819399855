<template>
    <div class="main-container">
      <MainLead/>
      <PersonalOffer/>
      <MainAbout/>
      <MainClasses/>
      <TrialTraining/>
      <MainContacts/>
      <MainForm/>
    </div>
</template>

<script>

import { useMeta } from 'vue-meta'
// import SiteHeader from "@/components/common/SiteHeader";
import MainLead from "@/components/main/MainLead";
import PersonalOffer from "@/components/main/PersonalOffer";
import MainAbout from "@/components/main/MainAbout";
import MainClasses from "@/components/main/MainClasses";
import TrialTraining from "@/components/main/TrialTraining";
import MainContacts from "@/components/common/MainContacts";
import MainForm from "@/components/common/MainForm";
// import MainFooter from "@/components/common/MainFooter";

export default {
  name: 'HomeView',
  components: {
    // MainFooter,
    MainForm,
    MainContacts,
    TrialTraining,
    MainClasses,
    MainAbout,
    PersonalOffer,
    MainLead,
    // SiteHeader,

  },
  beforeMount() {
    useMeta({
      title: this.$t('pagenames.home') + " | AI gym",
      link: [
        { rel: 'canonical', href: 'https://35fit.com/' },
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { property: "og:title", content: "35FIT | " + this.$t('pagenames.home') + " | AI gym" },
        { property: "og:description", content: this.$t('meta.home') },
        { property: "og:url", content: "https://35fit.com" },
      ],
      description: this.$t('meta.home'),
    })
  },
  data() {
    return {
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.loggedOut = false;
    } else {
      this.loggedOut = true;
    }
  },

  methods: {
  },


}
</script>

<style>

.wrapper {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //position: relative;
  padding: 60px 0 0 0;
  //margin: 0;
  //min-height: 100vh;
}

@media screen and (min-width: 1024px) {
  .wrapper {
    padding: 96px 0 0 0;
  }
}

.main-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
