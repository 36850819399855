import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
//import PricingView from '../views/PricingView.vue'
import Dashboard from '../views/DashboardView.vue'
import Register from '../views/RegisterView.vue'
import PageNotFound from '../views/PageNotFoundView.vue'
//import TrainingView from '../views/TrainingView.vue'
//import FaqView from '../views/FAQView.vue'
import SuccessView from '../views/SuccessView.vue'
import FailedView from '../views/FailedView.vue'
import OpenGymView from "@/views/OpenGymView";
import TariffsView from "@/views/TariffsView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/basic-zone',
    name: 'basic-zone',
    component: OpenGymView,
  },
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: FaqView
  // },
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: PricingView
  // },
  {
    path: '/pricing',
    name: 'pricing',
    component: TariffsView
  },
  {
    path: '/dashboard/payment/success',
    name: 'payment-success',
    component: SuccessView,

  },
  {
    path: '/dashboard/payment/failed',
    name: 'payment-failed',
    component: FailedView,

  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/dashboard')
      } else {
        next()
      }
    }
  },
  // {
  //   path: '/training',
  //   name: 'training',
  //   component: TrainingView
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/et/:pathMatch(.*)*',
    component: HomeView
  },
  {
    path: '/en/:pathMatch(.*)*',
    component: HomeView
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: PageNotFound },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const header = document.querySelector("header"); // Находим хедер
        const headerHeight = header ? header.offsetHeight : 0; // Берем его высоту

        if (savedPosition) {
          resolve(savedPosition); // Возвращаем позицию при возврате назад
        } else if (to.hash) {
          const element = document.querySelector(to.hash);
          if (element) {
            resolve({
              top: element.offsetTop - headerHeight + headerHeight - 24, // Учитываем высоту хедера
              behavior: "smooth",
            });
          } else {
            resolve({ top: 0, behavior: "smooth" }); // Если элемента нет, прокручиваем вверх
          }
        } else {
          resolve({ top: 0, behavior: "smooth" }); // Обычная прокрутка вверх
        }
      }, 500); // Подстрой задержку под анимацию
    });
  }
});

export default router;
