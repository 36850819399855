<template>
    <div class="no-info">

        <h2>{{ text }}</h2>
    </div>
</template>
<script>
export default {
    name: 'NoInfoPlaceholder',
    props: {
        img: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }

}
</script>
<style>
.no-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    //height: 40vh;
}

.no-info h2 {
    font-size: 20px;
    text-align: center;
}

.no-info-pic {
    width: 100%;
    max-width: 450px;
}
</style>
