<template>
  <div class="description-container">
    <div class="description-top-line">
      <p class="description-title">{{title}}</p>
      <p class="description-value">{{value}}</p>
      <p class="description-subtitle">{{subtitle}}</p>
    </div>
    <div class="description-bottom-line">
      <p class="description-description">{{description}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "SliderDescription",
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
  },
}
</script>

<style scoped>

.description-container {
  width: 350px;
  height: 350px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-weight: 400;
  border-radius: 16px;
}

@media screen and (min-width: 450px) {
  .description-container {
    padding: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .description-container {
    width: 350px;
  }
}

.description-top-line {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.description-bottom-line {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.description-title {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 400;
}

.description-value {
  font-size: 40px;
  line-height: 40px;
}

@media screen and (min-width: 450px) {
  .description-value {
    font-size: 66px;
    line-height: 66px;
  }
}

.description-subtitle {
  font-size: 28px;
  line-height: 40px;
  text-transform: uppercase;
}

.description-description {
  font-size: 18px;
  line-height: 27px;
}

</style>