<template>
  <div class="main-container">
    <MainLead :title="$t('basicTrainingZone.lead.title')"/>
    <div class="tariffs-container">
      <TariffTabs/>
      <div class="tariffs-title">{{ $t('packages.startPackages.title') }}</div>
      <div class="tariffs-subtitle" v-html="$t('packages.startPackages.subtitle')">

      </div>
      <div class="tariff-line tariff-line-margin">
        <TariffCard
          :name="startplans.plans[0].name ?? ''"
          :description="startplans.plans[0].description ?? ''"
          :price="startplans.plans[0].price ?? ''"
          :period="startplans.plans[0].period ?? ''"
          direction="horizontal"
          :color="startplans.plans[0].color ?? ''"
          page="contract"
          :button=true
        />
        <TariffCard
          :name="startplans.plans[1].name ?? ''"
          :description="startplans.plans[1].description ?? ''"
          :price="startplans.plans[1].price ?? ''"
          :period="startplans.plans[1].period ?? ''"
          direction="horizontal"
          :color="startplans.plans[1].color ?? ''"
          page="contract"
          :button=true
        />
      </div>
      <div class="tariffs-note">
        {{ $t('packages.startPackages.note') }}
      </div>
      <div class="tariffs-title tariff-section-margin" id="packages">{{ $t('packages.mainPackages.title') }}</div>
      <div class="tariffs-subtitle">
        {{ $t('packages.mainPackages.subtitle') }}
      </div>
      <div class="tariff-line tariff-line-margin">
        <TariffCard
          :name="packages.plans[0].name ?? ''"
          :description="packages.plans[0].description ?? ''"
          :price="packages.plans[0].price ?? ''"
          :period="packages.plans[0].period ?? ''"
          direction="horizontal"
          :color="packages.plans[0].color ?? ''"
          page="contract"
        />
        <TariffCard
          :name="packages.plans[1].name ?? ''"
          :description="packages.plans[1].description ?? ''"
          :price="packages.plans[1].price ?? ''"
          :period="packages.plans[1].period ?? ''"
          direction="horizontal"
          :color="packages.plans[1].color ?? ''"
          page="contract"
        />
      </div>
      <div class="tariff-line tariff-between-margin">
        <TariffCard
          :name="packages.plans[2].name ?? ''"
          :description="packages.plans[2].description ?? ''"
          :price="packages.plans[2].price ?? ''"
          :period="packages.plans[2].period ?? ''"
          direction="horizontal"
          :color="packages.plans[2].color ?? ''"
          page="contract"
        />
        <TariffCard
          :name="packages.plans[3].name ?? ''"
          :description="packages.plans[3].description ?? ''"
          :price="packages.plans[3].price ?? ''"
          :period="packages.plans[3].period ?? ''"
          direction="horizontal"
          :color="packages.plans[3].color ?? ''"
          page="contract"
        />
      </div>
      <div class="tariffs-note">
        {{ $t('packages.mainPackages.note') }}
      </div>
      <div class="tariffs-title tariff-section-margin">{{ $t('packages.additionalServices.title') }}</div>
      <div class="tariffs-subtitle">
        {{ $t('packages.additionalServices.subtitle') }}
      </div>
      <div class="tariff-line tariff-line-margin">
        <TariffCard
          :name="additionalservices.plans[0].name ?? ''"
          :description="additionalservices.plans[0].description ?? ''"
          :price="additionalservices.plans[0].price ?? ''"
          :period="additionalservices.plans[0].period ?? ''"
          direction="horizontal"
          :color="additionalservices.plans[0].color ?? ''"
          page="services"
        />
        <TariffCard
          :name="additionalservices.plans[1].name ?? ''"
          :description="additionalservices.plans[1].description ?? ''"
          :price="additionalservices.plans[1].price ?? ''"
          :period="additionalservices.plans[1].period ?? ''"
          direction="horizontal"
          :color="additionalservices.plans[1].color ?? ''"
          page="services"
        />
      </div>
      <div class="tariff-line tariff-between-margin">
        <TariffCard
          :name="additionalservices.plans[2].name ?? ''"
          :description="additionalservices.plans[2].description ?? ''"
          :price="additionalservices.plans[2].price ?? ''"
          :period="additionalservices.plans[2].period ?? ''"
          direction="horizontal"
          :color="additionalservices.plans[2].color ?? ''"
          page="services"
        />
        <TariffCard
          :name="additionalservices.plans[3].name ?? ''"
          :description="additionalservices.plans[3].description ?? ''"
          :price="additionalservices.plans[3].price ?? ''"
          :period="additionalservices.plans[3].period ?? ''"
          direction="horizontal"
          :color="additionalservices.plans[3].color ?? ''"
          page="services"
        />
      </div>
      <div class="tariff-line tariff-between-margin">
        <TariffCard
          :name="additionalservices.plans[4].name ?? ''"
          :description="additionalservices.plans[4].description ?? ''"
          :price="additionalservices.plans[4].price ?? ''"
          :period="additionalservices.plans[4].period ?? ''"
          direction="horizontal"
          :color="additionalservices.plans[4].color ?? ''"
          page="services"
        />
        <div class="tariff-card-empty"></div>
      </div>
      <div class="tariffs-note">
        {{ $t('packages.additionalServices.note') }}
      </div>
    </div>
  </div>
</template>

<script>
import MainLead from "@/components/main/MainLead";
import TariffTabs from "@/components/tariffs/TariffTabs";
import packages from '@/resources/packages.json'
import additionalservices from '@/resources/additionalservices.json'
import startplans from '@/resources/startplans.json'
// import {useMeta} from "vue-meta";
import TariffCard from "@/components/tariffs/TariffCard";


export default {
  name: "TariffsView",
  components: {
    TariffCard,
    TariffTabs,
    MainLead
  },
  data() {
    return {
      packages,
      additionalservices,
      startplans,
    }
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.packages = this.packages.plans.en
        this.additionalservices = this.additionalservices.plans.en
        this.startplans = this.startplans.plans.en
        break;
      case 'et':
        this.packages = this.packages.plans.et
        this.additionalservices = this.additionalservices.plans.et
        this.startplans = this.startplans.plans.et
        break;
      case 'ru':
        this.packages = this.packages.plans.ru
        this.additionalservices = this.additionalservices.plans.ru
        this.startplans = this.startplans.plans.ru
        break;
      default:
        this.packages = this.packages.plans.en
        this.additionalservices = this.additionalservices.plans.en
        this.startplans = this.startplans.plans.en
        break;
    }
  },
}
</script>

<style scoped>

.tariff-card-empty {
  width: 100%;
  max-width: 684px;
  flex: 1 0 calc(50% - 24px);
}




.tariffs-container {
  max-width: 1440px;
  margin: 0 auto 40px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .tariffs-container {
    margin: 0 auto 60px;
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .tariffs-container {
    margin: 0 auto 80px;
  }
}

.tariffs-title {
  max-width: 600px;
  font-size: 28px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .tariffs-title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .tariffs-title {
    font-size: 40px;
  }
}

.tariffs-subtitle {
  white-space: pre-line;
  max-width: 1300px;
  font-size: 18px;
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .tariffs-subtitle {
    font-size: 22px;
  }
}

@media screen and (min-width: 1024px) {
  .tariffs-subtitle {
    font-size: 24px;
  }
}

.tariffs-note {
  margin-top: 20px;
  max-width: 1300px;
  font-size: 16px;
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .tariffs-note {
    margin-top: 30px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .tariffs-note {
    margin-top: 40px;
    font-size: 22px;
  }
}

.tariff-line {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1140px) {
  .tariff-line {
    flex-direction: row;
    align-items: stretch;
  }
}

.tariff-line-margin {
  margin-top: 28px;
}

@media screen and (min-width: 768px) {
  .tariff-line-margin {
    margin-top: 42px;
  }
}

@media screen and (min-width: 1024px) {
  .tariff-line-margin {
    margin-top: 56px;
  }
}

.tariff-section-margin {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .tariff-section-margin {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1024px) {
  .tariff-section-margin {
    margin-top: 80px;
  }
}

.tariff-between-margin {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .tariff-between-margin {
    margin-top: 24px;
  }
}

</style>