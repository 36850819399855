<template>
  <swiper
    class="classes-swiper"
    :modules="modules"
    :breakpoints="breakpoints"
    pagination
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :mousewheel="{ forceToAxis: true }"
  >
    <swiper-slide>
      <a href="/basic-zone" class="classes-image-container classes-image-container-active align-items-start">
        <div class="classes-bg-wrap">
          <img
            :src="opengym"
            class="classes-image"
          />
        </div>
        <div class="classes-text color-black">
          Basic<br>Training<br>Zone
        </div>
      </a>
    </swiper-slide>
    <swiper-slide>
      <div class="classes-image-container align-items-end">
        <div class="classes-bg-wrap">
          <img
            :src="sgt"
            class="classes-image"
          />
        </div>
        <div class="classes-text color-white">
          Small Group<br>Training
        </div>
        <div class="classes-label">{{ $t('homeNew.classes.opening') }}</div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="classes-image-container align-items-start">
        <div class="classes-bg-wrap">
          <img
            :src="personal"
            class="classes-image"
          />
        </div>
        <div class="classes-text color-white">
          Personal<br>Training
        </div>
        <div class="classes-label">{{ $t('homeNew.classes.opening') }}</div>
      </div>
    </swiper-slide>
  </swiper>

<!--  <div class="classes-container">-->
<!--    <div class="classes-image-container">-->
<!--      <div class="classes-bg-wrap">-->
<!--        <img-->
<!--          :src="opengym"-->
<!--          class="classes-image"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="classes-image-container">-->
<!--      <div class="classes-bg-wrap">-->
<!--        <img-->
<!--          :src="sgt"-->

<!--          class="classes-image"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="classes-image-container">-->
<!--      <div class="classes-bg-wrap">-->
<!--        <img-->
<!--          :src="personal"-->
<!--          class="classes-image"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import opengym from '@/assets/img/new/classes-opengym.jpg';
import sgt from '@/assets/img/new/classes-sgt.jpg';
import personal from '@/assets/img/new/classes-personal.jpg';

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  name: "MainClasses",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Mousewheel],
    };
  },
  data() {
    return {
      opengym,
      sgt,
      personal,
      breakpoints: {
        375: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 24,
          slidesPerView: 2,
        },
        1440: {
          spaceBetween: 24,
          slidesPerView: 3,
        },
      },
    }
  },

}
</script>

<style>

.swiper-pagination {
  margin-top: 32px;
  position: inherit;
}

.classes-swiper {
  display: block;
  padding: 16px 32px 16px 32px;
  width: 100%;
  max-width: 1920px;
}

@media screen and (min-width: 768px) {
  .classes-swiper {
    padding: 24px 48px 24px 48px;
  }
}

.classes-container {
  width: 100%;
  display: none;
  justify-content: space-between;
}

@media screen and (min-width: 1024px) {
  .classes-container {
    //display: flex;
  }
}

.classes-text {
  margin: 24px;
  font-size: 32px;
  line-height: 1.1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 440px) {
  .classes-text {
    font-size: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .classes-text {
    font-size: 44px;
  }
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.classes-image-container {
  //width: 30vw;
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  height: 80vw;
  position: relative;
  transition: 0.25s all ease-in-out;
}

.classes-image-container-active {
  cursor: pointer;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

@media screen and (min-width: 768px) {
  .classes-image-container {
    height: 40vw;
  }
}

@media screen and (min-width: 1440px) {
  .classes-image-container {
    height: 30vw;
    max-height: 590px;
  }
}

.classes-image-container-active:hover {
   transform: scale(1.03);
   z-index: 1;
   transition: 0.25s all ease-in-out;
 }

@media screen and (min-width: 768px) {
  .classes-image-container-active:hover {
    transform: scale(1.07);
  }
}

.classes-bg-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.classes-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.classes-label {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 20px;
  padding: 6px 8px;
  background-color: white;
  color: #000000;
  border-radius: 24px;
  justify-content: center;
  width: 110px;
  text-align: center;
}

@media screen and (min-width: 530px) {
  .classes-label {
    font-size: 24px;
    padding: 6px 32px;
    border-radius: 40px;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .classes-label {
    font-size: 20px;
    padding: 6px 8px;
    border-radius: 24px;
    width: 110px;
  }
}

@media screen and (min-width: 1024px) {
  .classes-label {
    font-size: 24px;
    padding: 6px 32px;
    border-radius: 40px;
    width: auto;
  }
}
</style>