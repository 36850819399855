<template>
  <div class="offer-container">
    <div class="offer-text-container">
      {{ $t('homeNew.offer') }} 15<span style="font-size: 0.8em">€</span>
    </div>
    <div class="offer-subtext-container">
      {{ $t('homeNew.offerSubtext') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalOffer"
}
</script>

<style scoped>
.offer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

@media screen and (min-width: 768px) {
  .offer-container {
    height: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .offer-container {
    height: 96px;
  }
}

.offer-text-container {
  line-height: 1.15;
  font-size: 28px;
  text-align: center;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .offer-text-container {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .offer-text-container {
    font-size: 40px;
  }
}

.offer-subtext-container {
  line-height: 1.15;
  font-size: 18px;
  text-align: center;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .offer-subtext-container {
    font-size: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .offer-subtext-container {
    font-size: 28px;
  }
}

</style>

