<template>
<div class="benefits-wrapper">
  <div class="benefits-title">
    Benefits of OpenGym
  </div>
  <div class="benefits-title-wrapper">
    <div class="benefits-title-small">{{ $t('basicTrainingZone.benefits.title') }}</div>
    <div class="benefits-subtitle">
      {{ $t('basicTrainingZone.benefits.subtitle') }}
    </div>
  </div>
  <swiper
    class="benefits-swiper"
    :modules="modules"

    :breakpoints="breakpoints"
    pagination
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :mousewheel="{ forceToAxis: true }"
  >
    <swiper-slide class="benefits-slide benefits-slide-first">
      <div class="benefits-image-container align-items-start">
        <div class="benefits-bg-wrap">
          <picture>
            <source
              media="(max-width: 420px)"
              :srcset="slide1Small"
            />
            <img
              class="benefits-image"
              :src="slide1"
              alt="Lead image"
            />
          </picture>
        </div>
        <div class="benefits-text color-white">
          <div class="benefits-first-line">Lorem Ipsum is simply dummy text of the </div>
          <div class="benefits-second-line">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</div>
        </div>
        <div class="benefits-description">{{ $t('basicTrainingZone.benefits.slides[0].text') }}</div>
      </div>
    </swiper-slide>
    <swiper-slide class="benefits-slide">
      <div class="benefits-image-container align-items-start">
        <div class="benefits-bg-wrap">
          <img
            :src="slide2"
            class="benefits-image"
          />
        </div>
        <div class="benefits-text color-white">
          <div class="benefits-first-line">Lorem Ipsum is simply dummy text of the </div>
          <div class="benefits-second-line">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</div>
        </div>
        <div class="benefits-description">{{ $t('basicTrainingZone.benefits.slides[1].text') }}</div>
      </div>
    </swiper-slide>
    <swiper-slide class="benefits-slide">
      <div class="benefits-image-container align-items-start">
        <div class="benefits-bg-wrap">
          <img
            :src="slide3"
            class="benefits-image"
          />
        </div>
        <div class="benefits-text color-white">
          <div class="benefits-first-line">Lorem Ipsum is simply dummy text of the </div>
          <div class="benefits-second-line">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</div>
        </div>
        <div class="benefits-description">{{ $t('basicTrainingZone.benefits.slides[2].text') }}</div>
      </div>
    </swiper-slide>
    <swiper-slide class="benefits-slide benefits-slide-last">
      <div class="benefits-image-container align-items-start">
        <div class="benefits-bg-wrap">
          <img
            :src="slide4"
            class="benefits-image"
          />
        </div>
        <div class="benefits-text color-black">
          <div class="benefits-first-line">Lorem Ipsum is simply dummy text of the </div>
          <div class="benefits-second-line">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the</div>
        </div>
        <div class="benefits-description">{{ $t('basicTrainingZone.benefits.slides[3].text') }}</div>
      </div>
    </swiper-slide>
  </swiper>
</div>
</template>

<script>
import slide1 from '@/assets/img/new/opengym-slide-1-2.jpg';
import slide1Small from '@/assets/img/new/opengym-slide-1-2-small.jpg';
import slide2 from '@/assets/img/new/opengym-slide-2-2.jpg';
import slide3 from '@/assets/img/new/opengym-slide-3-2.jpg';
import slide4 from '@/assets/img/new/opengym-slide-4-2.jpg';

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  name: "OpenGymBenefits",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slide1,
      slide2,
      slide3,
      slide4,
      slide1Small,
      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 1.1,

        },
        768: {
          spaceBetween: 24,
          slidesPerView: 'auto',
        },
        1280: {
          spaceBetween: 32,
          slidesPerView: 'auto',
        },
        1920: {
          spaceBetween: 32,
          slidesPerView: 'auto',
        },
      },
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Mousewheel],
    };
  },
}
</script>

<style>

.benefits-title-wrapper {
  margin: 20px 0 28px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .benefits-title-wrapper {
    margin: 20px 0 42px;
    padding: 0 24px;
  }
}

@media screen and (min-width: 1024px) {
  .benefits-title-wrapper {
    padding: 0 40px;
    margin: 40px 0 56px;
  }
}

@media screen and (min-width: 1280px) {
  .benefits-title-wrapper {
    padding: 0 60px;
  }
}

@media screen and (min-width: 1440px) {
  .benefits-title-wrapper {
    padding: 0 80px;
  }
}

.benefits-title {
  display: none;
  margin: 32px 0 12px;
  text-align: center;
  font-size: 28px;
}

@media screen and (min-width: 1024px) {
  .benefits-title {
    margin: 80px 0 40px;
    font-size: 48px;
  }
}

.benefits-title-small {
  max-width: 660px;
  font-size: 28px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .benefits-title-small {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .benefits-title-small {
    font-size: 40px;
  }
}

.benefits-subtitle {
  max-width: 1200px;
  font-size: 18px;
  color: #8a8a8a;
}

@media screen and (min-width: 768px) {
  .benefits-subtitle {
    font-size: 22px;
  }
}

@media screen and (min-width: 1024px) {
  .benefits-subtitle {
    font-size: 24px;
  }
}

.benefits-swiper {
  display: block;
  padding: 16px 32px 16px 0;
  width: 100%;
  //max-width: 1920px;
}

@media screen and (min-width: 768px) {
  .benefits-swiper {
    padding: 16px 0 16px 0;
  }
}

.benefits-slide-first {
  padding-left: 16px;
  box-sizing: content-box;
}

@media screen and (min-width: 768px) {
  .benefits-slide-first {
    padding-left: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .benefits-slide-first {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .benefits-slide-first {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .benefits-slide-first {
    padding-left: 80px;
  }
}

.benefits-slide-last {
  padding-right: 16px;
  box-sizing: content-box;
}

@media screen and (min-width: 768px) {
  .benefits-slide-last {
    padding-right: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .benefits-slide-last {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .benefits-slide-last {
    padding-right: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .benefits-slide-last {
    padding-right: 80px;
  }
}

.benefits-slide {
  height: calc(350px + 160px);
}

@media screen and (min-width: 768px) {
  .benefits-slide {
    width: 580px;
    height: calc(400px + 140px);
  }
}

@media screen and (min-width: 1920px) {
  .benefits-slide {
    width: 700px;
    height: calc(480px + 140px);
  }
}

.benefits-wrapper {
  width: 100%;
}

.benefits-text {
  display: none;
  margin: 24px;
  font-size: 40px;
  line-height: 1.1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  width: 200px;
}

.benefits-first-line {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 16px;
}

.benefits-second-line {
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .benefits-text {
    font-size: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .benefits-text {
    font-size: 44px;
  }
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.align-items-start {
  align-items: start;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.benefits-image-container {
////width: 30vw;
  //width: 580px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  //height: 80vw;
  height: calc(350px + 160px);
  position: relative;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
}

@media screen and (min-width: 768px) {
  .benefits-image-container {
    height: calc(400px + 140px);
  }
}

@media screen and (min-width: 1920px) {
  .benefits-image-container {
    height: calc(480px + 140px);
  }
}

.benefits-image-container:hover {
  //transform: scale(1.07);
  //z-index: 1;
  //transition: 0.25s all ease-in-out;
}

.benefits-description {
  display: flex;
  align-items: center;
  height: 160px;
  background-color: #F2F2F2;
  width: 100%;
  font-size: 18px;
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .benefits-description {
    height: 140px;
    font-size: 22px;
    padding: 0 24px;
  }
}

.benefits-bg-wrap {
  //position: absolute;
  height: calc(100% - 160px);
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .benefits-bg-wrap {
    height: calc(100% - 140px);
  }
}

.benefits-image {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

</style>