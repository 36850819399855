<template>
  <div class="lead-container">
    <div class="bgWrap">
      <picture>
        <img
          class="lead-image"
          :src="lead"
          alt="Lead image"
        />
      </picture>
    </div>
    <div class="lead-flex-container">
      <div class="lead-text-container">
        <div class="lead-second-line-text">
          Basic<br>
          Training<br>
          Zone
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import lead from '@/assets/img/new/opengym_lead.jpg';
import leadMobile from '@/assets/img/new/35fit_lead_mobile.jpg';

export default {
  name: "OpenGymLead",
  data() {
    return {
      lead,
      leadMobile
    }
  },
}
</script>

<style scoped>
.lead-container {
  position: relative;
  height: 140vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .lead-container {
    height: 120vw;
  }
}

@media screen and (min-width: 768px) {
  .lead-container {
    height: 70vw;
  }
}

@media screen and (min-width: 1180px) {
  .lead-container {
    height: calc(100vh - 192px);
  }
}

.bgWrap {
  position: absolute;
  height: 140vw;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

@media screen and (min-width: 600px) {
  .bgWrap {
    height: 120vw;
  }
}

@media screen and (min-width: 768px) {
  .bgWrap {
    height: 70vw;
    right: -40px;
    width: calc(100vw + 40px);
  }
}

@media screen and (min-width: 1180px) {
  .bgWrap {
    height: calc(100vh - 192px);
    right: -120px;
    width: calc(100vw + 120px);
  }
}

@media screen and (min-width: 1440px) {
  .bgWrap {
    right: 0;
    width: 100vw;
  }
}

.lead-image {
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
}

.lead-flex-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.lead-text-container {
  height: 100%;
  padding: 80px 48px 40px;
  flex: 1 1 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: white;
  justify-content: flex-end;
}

@media screen and (min-width: 700px) {
  .lead-text-container {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1180px) {
  .lead-text-container {
    padding: 160px 90px;
  }
}

@media screen and (min-width: 1440px) {
  .lead-text-container {
    padding: 140px 90px;
  }
}

.lead-first-line-text {
  font-weight: 700;
  font-size: 22px;
  width: 220px;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .lead-first-line-text {
    font-size: 24px;
    width: inherit;
  }
}

@media screen and (min-width: 1024px) {
  .lead-first-line-text {
    font-size: 32px;
  }
}


.lead-second-line-text {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
}

@media screen and (min-width: 600px) {
  .lead-second-line-text {
    font-size: 52px;
  }
}

@media screen and (min-width: 1024px) {
  .lead-second-line-text {
    font-size: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .lead-second-line-text {
    font-size: 80px;
  }
}

@media screen and (min-width: 1920px) {
  .lead-second-line-text {
    font-size: 100px;
  }
}

</style>