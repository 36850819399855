<template>
  <div class="opengym-faq-wrapper">
    <div class="opengym-faq-title">FAQ</div>
    <div class="opengym-questions-list">
      <div v-for="(item, index) in questions.questions" :key="index" @click="togglequestion(index)" :class="index == 0 ? 'opengym-questions-list-element opengym-questions-list-element-first' : 'opengym-questions-list-element'">
        <div class="opengym-question-with-answer">
          <div class="opengym-question">{{item.question}}</div>

          <div v-if="item.open" class="opengym-answer">{{item.answer}}</div>
        </div>
        <div class="opengym-question-icon">
          <img
            :src="item.open == false ? plus : minus"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from '@/assets/img/new/icon/plus.svg';
import minus from '@/assets/img/new/icon/minus.svg';
import questions from "@/resources/opengymquestions.json";

export default {
  name: "OpenGymFaq",
  data() {
    return {
      questions,
      plus,
      minus
    }
  },
  methods: {
    togglequestion(index) {
      this.questions.questions[index].open = !this.questions.questions[index].open;
    },
  },
  beforeMount() {
    switch (this.$i18n.locale) {
      case 'en':
        this.questions = this.questions.questions.en
        break;
      case 'et':
        this.questions = this.questions.questions.et
        break;
      case 'ru':
        this.questions = this.questions.questions.ru
        break;
      default:
        this.questions = this.questions.questions.en
        break;
    }
  }
}
</script>

<style scoped>

.opengym-faq-wrapper {
  width: 100%;
  max-width: calc(1357px + 160px);
  padding: 0 16px;
  margin: 0 auto 40px auto;
}

@media screen and (min-width: 768px) {
  .opengym-faq-wrapper {
    padding: 0 24px;
    margin: 0 auto 60px auto;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-faq-wrapper {
    padding: 0 40px;
    margin: 0 auto 80px auto;
  }
}

@media screen and (min-width: 1280px) {
  .opengym-faq-wrapper {
    padding: 0 60px;
  }
}

@media screen and (min-width: 1440px) {
  .opengym-faq-wrapper {
    padding: 0 80px;
  }
}

.opengym-faq-title {
  font-size: 28px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .opengym-faq-title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-faq-title {
    font-size: 40px;
  }
}

.opengym-questions-list-element {
  border-bottom: 1px solid black;
  display: flex;
  padding: 16px 8px 16px 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .opengym-questions-list-element {
    padding: 24px 8px 24px 16px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-questions-list-element {
    padding: 32px 8px 32px 16px;
  }
}

.opengym-questions-list-element-first {
  border-top: 1px solid black;
  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .opengym-questions-list-element-first {
    margin-top: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .opengym-questions-list-element-first {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-questions-list-element-first {
    margin-top: 56px;
  }
}

.opengym-question-with-answer {
  white-space: pre-line;
  flex: 1 0 0;
}

.opengym-question {
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 500px) {
  .opengym-question {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .opengym-question {
    font-size: 24px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-question {
    font-size: 28px;
  }
}

.opengym-answer {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.5;
  color: #8A8A8A;
}

@media screen and (min-width: 500px) {
  .opengym-answer {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .opengym-answer {
    font-size: 24px;
  }
}

@media screen and (min-width: 1190px) {
  .opengym-answer {
    font-size: 28px;
  }
}

.opengym-question-icon {
  padding-left: 8px;
}

@media screen and (min-width: 768px) {
  .opengym-question-icon {
    padding-left: 8px;
  }
}

</style>